import './TextArea.css';
import React from "react"

interface ITextAreaProps{
  fieldTittle: string;
  errorMessage?: string;
  inputProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

interface ITextAreaState{

}

export const TextArea: React.FC<ITextAreaProps> = (props) => {

    return (
      <div>
        <div className='area-container'>
          <label className='area'>
            <textarea className='area-input'
            {...props.inputProps}
            ></textarea>
            <span className='area-label'>{props.fieldTittle} </span>
          </label>
        </div>
        <div className='area-error'>
          <span>{props.errorMessage ? props.errorMessage : ''}</span>
        </div>
      </div>
      );
}