import './ProductEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { Product } from '../../../../../models/manager/Product.ts';
import productManager from '../../../../../managers/manager/product.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { SelectField, IOptionSelectField } from '../../../../../components/selectfield/SelectField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import AttachmentUploader from '../../../../../components/attachment/AttachmentUploader.tsx';
import attachmentManager from '../../../../../managers/attachment.manager.tsx';
import { AttachmentType } from '../../../../../enums/AttachmentType.ts';
import { MeasureType } from '../../../../../enums/MeasureType.ts';
import bonusManager from '../../../../../managers/manager/bonus.manager.tsx';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { TextArea } from '../../../../../components/textarea/TextArea.tsx';
import productTypeManager from '../../../../../managers/manager/productType.manager.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import { CurrencyType } from '../../../../../enums/CurrencyType.ts';

interface IProductEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  productId: number;
  selectedProductTypeId: number;
  companies: IOptionSelectField[];
  selectedCompanyId: number;
  productTypes: IOptionSelectField[];
}

interface IProductEditPopupState{
  product: Product;
  bonuses: IOptionSelectField[];
  productTypes: IOptionSelectField[];
  loading: boolean;
  images: File[];
  currentCompanyId: number;
}

export const ProductEditPopup: React.FC<IProductEditPopupProps> = (props) => {

  const [state, setState] = useState<IProductEditPopupState>({
      product: {
        productId: 0,
        productTypeId: 0,
        name: '',
        producerCountry: '',
        article: '',
        tittleDescription: '',
        description: '',
        price: 0,
        currencyType: 0,
        quantityPerHectare: 0,
        measureType: MeasureType.None,
        isActive: false,
        attachments: undefined,
        discount: undefined,
        discountId: undefined
      },
      bonuses: [],
      productTypes: [],
      loading: true,
      images: [],
      currentCompanyId: 0
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      loadProductData();
      setState(prevState => {
        return {
          ...prevState,
          images: [],
          bonuses: [],
          productTypes: props.productTypes,
          currentCompanyId: props.selectedCompanyId
        }
      });
      loadBonusesData();
    }
  }, [props.isActive]);

  const methods = useForm({
    shouldUnregister: false
  });
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadProductData = () => {
    if (props.productId > 0) {
      productManager.getProduct(props.productId).then(async (data) => {
        if (data) {
          if (!(props.selectedCompanyId > 0) && data?.productTypeId > 0) {
            let productType = await productTypeManager.getProductType(data.productTypeId);
            let selectedCompanyId = state.currentCompanyId;

            if (productType && productType?.productTypeId > 0 && productType?.companyId) {
              selectedCompanyId = productType?.companyId;
              loadProductTypesData(selectedCompanyId);
            }
            setState(prevState => {
              return {
                ...prevState,
                currentCompanyId: selectedCompanyId
              }
            });
          }
          UpdateGridAndState(data);
        }
        else{
          alert('Не вдалось завантажити дані!');
        }
      });
    }
    else
    {
      let data: Product = {
        productId: 0,
        productTypeId: 0,
        name: '',
        producerCountry: '',
        article: '',
        tittleDescription: '',
        description: '',
        price: 0,
        currencyType: 0,
        quantityPerHectare: 0,
        measureType: MeasureType.None,
        isActive: false,
        attachments: undefined,
        discount: undefined,
        discountId: undefined
      }
      UpdateGridAndState(data);
    }
  };

  const UpdateGridAndState = (data: Product) => {
    setState(prevState => {
      return {
        ...prevState,
        product: { ...data },
      }
    });

    setValue('name', data.name);
    setValue('producerCountry', data.producerCountry);
    setValue('article', data.article);
    setValue('tittleDescription', data.tittleDescription);
    setValue('description', data.description);
    setValue('price', data.price);
    setValue('quantityPerHectare', data.quantityPerHectare);
  }

  const loadBonusesData = () => {
    bonusManager.getAllActiveBonusesForSelector().then((data) => {
      let options: IOptionSelectField[] = [];
      data.forEach((x) => { options.push({ label: x.name, value: x.bonusId }) });

      setState(prevState => {
        return {
          ...prevState,
          bonuses: options,
          loading: false
        }
      });
    });
  };

  const loadProductTypesData = (companyId: number) => {
    productTypeManager.getAllActiveProductTypesForSelector(companyId).then((data) => {
        let options: IOptionSelectField[] = [];
        data.forEach((x) => { options.push({ label: x.name, value: x.productTypeId }) });
        setState(prevstate =>
        ({
            ...prevstate,
            productTypes: options,
        }));
    });
};

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const updateImages = (files: File[]) => {
    setState(prevstate =>
      ({
        ...prevstate,
        images: files
      }));
  };

  const updateExistingImages = (id: number) => {
    let product = state.product;
    product.attachments = state.product.attachments?.filter(obj => obj.attachmentId != id);
    setState(prevstate =>
      ({
        ...prevstate,
        product: product
      }));
  };

  const onPopupUpdate = async () => {
    setLoad(true);
    let product = state.product;
    if(product.attachments && product.attachments.length > 0)
    {
      product.attachments = product.attachments.filter(obj => !obj.isDefault);
    }

    await productManager.updateCreateProduct(product).then(async (data) => {
      if (!data.isSuccessfull) 
        alert(data.message ?? "Не вдалось оновити товар!");
      let files = state.images;
      let id = data.productId as number ? data.productId as number : state.product.productId;
      if(files && files.length > 0 && id > 0)
      {
        await attachmentManager.uploadAttachments(files, AttachmentType.Product, id).then((attdata) => {
          if (!attdata.isSuccessfull) 
            alert(attdata.message ?? "Не вдалось завантажити зображення!");
        });
      }
    });
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
  setState(prevstate => {
    return {
      ...prevstate,
      loading: true
    }});
}

  const MeasureTypeOptions: IOptionSelectField[] =
    [
      { label: 'Кілограм', value: MeasureType.Kilogram.valueOf() },
      { label: 'Літр', value: MeasureType.Liter.valueOf() },
      { label: 'Одиниця товару', value: MeasureType.Piece.valueOf() },
    ];

  const CurrencyTypeOptions: IOptionSelectField[] =
    [
      { label: 'Гривня', value: CurrencyType.UAH.valueOf() },
      { label: 'Долар', value: CurrencyType.USD.valueOf() },
      { label: 'Євро', value: CurrencyType.EUR.valueOf() },
    ];


  const CompanyOnChange = (e) => {
    let id = e.target.value;
    if (id && id > 0) {
        setState(prevstate =>
        ({
            ...prevstate,
            currentCompanyId: id
        }));
        loadProductTypesData(id);
    }
}

const ProductTypeOnChange = (e) => {
  let id = e.target.value;
  if (id && id > 0) {
      setState(prevstate =>
      ({
          ...prevstate,
          product: {...prevstate.product,
                      productTypeId: id
                   }
      }));
  }
}

  return (
    <Popup 
      isActive={props.isActive} 
      loading={state.loading} 
      onClose={closePopup} 
      onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle='Редагувати товар' 
      updateButtonTittle={props.productId === 0 ? "Додати" : undefined}>
      <FormProvider {...methods}>
      <TextField
        inputProps={{
          ...register('name',
            {
              onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, product: { ...prevState.product, name: value } })) },
              required: 'Це поле обов`язкове!',
              value: state.product.name,
              maxLength: {
                value: 100, message: 'Максимальна кількість знаків - 100!'
              }
            })
        }}
        errorMessage={errors.name?.message?.toString()}
        fieldTittle='Назва' />
      <TextField 
        inputProps={{
          ...register('producerCountry',
          {
            onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, product: { ...prevState.product, producerCountry: value } })) },
            required: 'Це поле обов`язкове!',
            value: state.product.producerCountry,
            maxLength: {
              value: 100, message: 'Максимальна кількість знаків - 100!'
            }
          }) 
        }} 
        errorMessage={errors.producerCountry?.message?.toString()}
        fieldTittle='Країна виробництва' />
      <TextField 
        inputProps={{
          ...register('article',
          {
            onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, product: { ...prevState.product, article: value } })) },
            required: 'Це поле обов`язкове!',
            value: state.product.article,
            maxLength: {
              value: 100, message: 'Максимальна кількість знаків - 100!'
            }
          }) 
        }}
        errorMessage={errors.article?.message?.toString()}
        fieldTittle='Артикул' />
      <TextField
        inputProps={{
          ...register('tittleDescription',
          {
            onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, product: { ...prevState.product, tittleDescription: value } })) },
            required: 'Це поле обов`язкове!',
            value: state.product.tittleDescription,
            maxLength: {
              value: 100, message: 'Максимальна кількість знаків - 100!'
            }
          }) 
        }}
        errorMessage={errors.tittleDescription?.message?.toString()} 
        fieldTittle='Заголовок' />
      <TextArea
        inputProps={{
          ...register('description',
          {
            onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, product: { ...prevState.product, description: value } })) },
            required: 'Це поле обов`язкове!',
            value: state.product.description,
            maxLength: {
              value: 300, message: 'Максимальна кількість знаків - 300!'
            }
          }) 
        }}
        errorMessage={errors.description?.message?.toString()}
        fieldTittle='Опис' />
      <TextField 
        inputProps={{ 
          ...register('price',
          {
            onChange: (e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, product: { ...prevState.product, price: value } })) },
            required: 'Це поле обов`язкове!',
            valueAsNumber: true,
            value: state.product.price,
            validate: (value) => {if(Number.isNaN(value)) return 'Введіть число у форматі 9.99!'}, 
            max: {
              value: 99999, message: 'Максимальне число - 99999!'
            }
          }) 
        }}
        errorMessage={errors.price?.message?.toString()}
        fieldTittle='Вартість' />
      <SelectField
          onChange={(e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, product: { ...prevState.product, currencyType: value } })) }}
          valueAsNumber={true}
          options={CurrencyTypeOptions}
          value={state.product.currencyType}
          fieldName='currencyType'
          fieldTittle='Валюта'
          isRequired={true} />
      <TextField 
        inputProps={{ 
          ...register('quantityPerHectare',
          {
            onChange: (e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, product: { ...prevState.product, quantityPerHectare: value } })) },
            required: 'Це поле обов`язкове!',
            valueAsNumber: true,            
            value: state.product.quantityPerHectare,
            validate: (value) => {if(Number.isNaN(value)) return 'Введіть число у форматі 9.99!'}, 
            max: {
              value: 99999, message: 'Максимальне число - 99999!'
            }
          }) 
        }}
        errorMessage={errors.quantityPerHectare?.message?.toString()}
        fieldTittle='К-сть на гектар' />
      <CheckBox 
        inputProps={{ 
          onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, product: { ...prevState.product, isActive: value } })) },
          checked: state.product.isActive,
          name: 'isActive',
        }}
        fieldTittle='Видимість' />
        <SelectField
          onChange={(e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, product: { ...prevState.product, measureType: value } })) }}
          valueAsNumber={true}
          options={MeasureTypeOptions}
          value={state.product.measureType}
          fieldName='measureType'
          fieldTittle='Тип виміру'
          isRequired={true} />
        <SelectField
          fieldName='company'
          value={state.currentCompanyId}
          onChange={CompanyOnChange}
          options={props.companies}
          fieldTittle='Компанія виробник' />
        <SelectField
          fieldName='productType'
          value={state.product.productTypeId}
          onChange={ProductTypeOnChange}
          options={state.productTypes}
          fieldTittle='Категорія' />
        <SelectField
          onChange={(e) => { let value: any = Number(e.target.value) > 0 ? Number(e.target.value) : null; setState(prevState => ({ ...prevState, product: { ...prevState.product, bonusId: value } })) }}
          valueAsNumber={true}
          options={state.bonuses}
          value={state.product.bonusId ? state.product.bonusId : 0}
          fieldName='bonusId'
          fieldTittle='Бонус' />
        <AttachmentUploader
        accept=".jpg,.png,.jpeg"
        allowedTypes={["image/jpeg", "image/png"]}
        label=""
        maxAttachmentCount={3}
        updateFilesCb={(e) => updateImages(e)}
        existedFiles={state.product.attachments && state.product.attachments.filter(obj => !obj.isDefault)}
        updateExistedFilesCb={(e) => updateExistingImages(e)}
      />
      </FormProvider>
    </Popup>
  )
}