import './Dashboard.css';
import React from "react";
import { ManagerMenu } from "../../../components/manager/menu/ManagerMenu.tsx";
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { UserCount } from './components/user-count/UserCount.tsx';
import { OrderCount } from './components/order-count/OrderCount.tsx';
import { TopUserOrder } from './components/top-user-order/TopUserOrder.tsx';
import { TopOrders } from './components/top-orders/TopOrders.tsx';


interface IDashboardProps{

}

interface IDashboardState{

}
export const Dashboard: React.FC<IDashboardState> = (props) => {

    return(
        <div>
            <ManagerMenu action={ManagerMenuActions.Dashboard}>
                <div className='dashboard-container'>
                    <div className='d-flex flex-column flex-md-row w-100'>
                        <div className='col-12 col-md-count'>
                            <div className='dashboard-item'>
                                <UserCount />
                            </div>
                        </div>
                        <div className='col-12 col-md-order'>
                            <div className='dashboard-item'>
                                <TopUserOrder />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row w-100'>
                        <div className='col-12 col-md-order'>
                            <div className='dashboard-item'>
                                <TopOrders />
                            </div>
                        </div>
                        <div className='col-12 col-md-count'>
                            <div className='dashboard-item'>
                                <OrderCount />
                            </div>
                        </div>
                    </div>
                </div>
            </ManagerMenu>
        </div>
    );
}
