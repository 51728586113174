import './Profile.css';
import React, {useState, useEffect} from "react";
import userManager from '../../../managers/user.manager.tsx';
import { TextField } from '../../../components/textfield/TextField.tsx';
import { CheckBox } from '../../../components/checkbox/CheckBox.tsx';
import { Loading } from '../../../components/loading/Loading.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { useForm, FormProvider } from "react-hook-form";
import { TextArea } from '../../../components/textarea/TextArea.tsx';
import { User } from '../../../models/user/User.ts';
import { UserUpdate } from '../../../models/user/UserUpdate.ts';


interface IProfileProps{
}

interface IProfileState{
  loading: boolean;
  updating: boolean;
  error: string;
  isChangePassword: boolean;
}

interface IProfileForm{
    user: UserUpdate;
}

export const Profile: React.FC<IProfileProps> = (props) => {

  const [state, setState] = useState<IProfileState>({
        error: '',
        updating: true,
        loading: true,
        isChangePassword: false
  });

  useEffect(() =>{
}, [state.updating])

  const loadProfile = async () => {
      let data = await userManager.getUser(true);
      let defValues: IProfileForm = {
          user:
            {
                firstName: '',
                middleName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                password: '',
                currentPassword: ''
            }
      } 
      let result = 
      {
        ...defValues,
        user: {
            ...defValues.user,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email
        }
      };
      setLoad(false);
      return result;
    }


  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const onUpdate = async (data: IProfileForm) => {
        setLoad(true);
        if (data && data.user) {
            console.log(data);
            await userManager.updateUser(data.user).then(async (data) => {
                if (!data.isSuccessfull) alert(data.message);
                window.location.reload();
            });
        }
    };

    const methods = useForm({
        defaultValues: loadProfile}
    );


    const { formState: { errors } } = methods;

    if (state.loading)
        return (<Loading />)
    else
        return (
            <div className='profile-content-center'>
                <div className='profile-edit-container mx-2 my-5 py-3'>
                    <h3 className='profile-tittle'>Профіль</h3>
                    <FormProvider {...methods}>
                        <TextField
                            fieldTittle='Ім`я'
                            errorMessage={errors?.user?.firstName?.message}
                            inputProps={{
                                ...methods.register("user.firstName",
                                    {
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }} />
                        <TextField
                            fieldTittle='По-батькові'
                            errorMessage={errors?.user?.middleName?.message}
                            inputProps={{
                                ...methods.register("user.middleName",
                                    {
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }} />
                        <TextField
                            fieldTittle='Прізвище'
                            errorMessage={errors?.user?.lastName?.message}
                            inputProps={{
                                ...methods.register("user.lastName",
                                    {
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }} />
                        <TextField
                            fieldTittle='Номер телефону'
                            errorMessage={errors?.user?.phoneNumber?.message}
                            inputProps={{
                                ...methods.register("user.phoneNumber",
                                    {
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }} />
                        <TextField
                            fieldTittle='Email'
                            errorMessage={errors?.user?.email?.message}
                            inputProps={{
                                ...methods.register("user.email",
                                    {
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }} />
                        <CheckBox
                            inputProps={{
                                onChange: (e) => {
                                    let value = e.target.checked
                                    setState(prevstate => {
                                        return {
                                            ...prevstate,
                                            isChangePassword: value
                                        }
                                    });
                                },
                                checked: state.isChangePassword
                            }}
                            fieldTittle='Змінити пароль?' />

                        {state.isChangePassword &&
                            (
                                <div>
                                    <TextField
                                        fieldTittle='Пароль'
                                        errorMessage={errors?.user?.currentPassword?.message}
                                        inputProps={{
                                            ...methods.register("user.currentPassword",
                                                {
                                                    required: 'Це поле обов`язкове!',
                                                    maxLength: {
                                                        value: 100, message: 'Максимальна кількість знаків - 100!'
                                                    }
                                                })
                                        }} />
                                    <TextField
                                        fieldTittle='Новий пароль'
                                        errorMessage={errors?.user?.password?.message}
                                        inputProps={{
                                            ...methods.register("user.password",
                                                {
                                                    required: 'Це поле обов`язкове!',
                                                    maxLength: {
                                                        value: 100, message: 'Максимальна кількість знаків - 100!'
                                                    }
                                                })
                                        }} />
                                </div>
                            )
                        }
                    </FormProvider>
                    <input type='button' className='profile-button' value='Оновити' onClick={methods.handleSubmit(onUpdate)}></input>
                </div>
            </div>
        );
}