import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetOrdersResponse } from './Response/GetOrdersResponse.ts';
import { GetOrdersRequest } from './Request/GetOrdersRequest.ts';
import { ChangeOrderStatusResponse } from './Response/ChangeOrderStatusResponse.ts';
import { ChangeOrderStatusRequest } from './Request/ChangeOrderStatusRequest.ts';
import { OrderHistoryRequest } from './Request/OrderHistoryRequest.ts';
import { OrderHistoryResponse } from './Response/OrderHistoryResponse.ts';

export class OrderService {

  async getAllOrders(request: GetOrdersRequest): Promise<GetOrdersResponse> {
    let response = await api
      .post<GetOrdersResponse>(ApplicationEndpoints.GetOrders,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new GetOrdersResponse();
  }

  async changeStatus(request: ChangeOrderStatusRequest): Promise<ChangeOrderStatusResponse> {
    let response = await api
      .post<ChangeOrderStatusResponse>(ApplicationEndpoints.OrderChangeStatus,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new ChangeOrderStatusResponse();
  }

  async getOrderHistory(request: OrderHistoryRequest): Promise<OrderHistoryResponse> {
    let response = await api
      .post<OrderHistoryResponse>(ApplicationEndpoints.GetOrderHistory,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new OrderHistoryResponse();
  }
}

const orderService = new OrderService();
export default orderService;