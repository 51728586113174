import React, { useRef, useState } from "react";
import "./AttachmentUploader.css";

const KILO_BYTES_PER_BYTE = 1024;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5242880; //5 mb

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToMB = (bytes) => (bytes / (KILO_BYTES_PER_BYTE * KILO_BYTES_PER_BYTE)).toFixed(2);

const AttachmentUploader = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  existedFiles,
  updateExistedFilesCb,
  maxAttachmentCount,
  allowedTypes,
  ...otherProps
}) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    if(fileInputField?.current)
      fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (file?.type && allowedTypes.includes(file?.type)) {
          let currentCount = (Object.keys(files)?.length ?? 0) + (existedFiles?.length ?? 0);
          if (maxAttachmentCount > currentCount) {
            files[file.name] = file;
          }
          else{
            alert(`Максимальна кількість файлів - ${maxAttachmentCount}`);
          }
        }
        else {
          alert(`Даний тип файлу не підтримується!`);
        }
      }
      else {
        alert(`Розмір файлу ${convertBytesToMB(file.size)} Мб більший за максимально дозволений (${convertBytesToMB(maxFileSizeInBytes)} Мб).`);
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const removeExistedFile = (index) => {
    updateExistedFilesCb(index);
  };

  return (
    <>
      <section className="file-upload-container px-2">
        <p className="drag-drop-text"></p>
        <button className="upload-file-btn px-5 py-2" type="button" onClick={handleUploadBtnClick}>
          <svg className="svg-upload" x="0" y="0" viewBox="0 0 24 24">
            <path d="M21.92 18.38c-.15.37-.52.62-.92.62h-1v2c0 .55-.45 1-1 1s-1-.45-1-1v-2h-1a1.002 1.002 0 0 1-.7-1.71l2-2a1 1 0 0 1 1.42 0l2 2c.29.29.37.72.22 1.09zm-5.95-5.2-4.46 2.68a.99.99 0 0 1-1.06-.03l-2.63-1.75L2 15.67V17c0 1.65 1.35 3 3 3h8c.55 0 1-.45 1-1a5 5 0 0 1 3.52-4.78zm-8.23-1.14L2 13.6V5c0-1.65 1.35-3 3-3h12c1.65 0 3 1.35 3 3v8c0 .14-.03.28-.09.4l-3.36-2.23a.971.971 0 0 0-1.06-.03l-4.46 2.68-2.48-1.65a.968.968 0 0 0-.81-.13zM7.5 9C8.33 9 9 8.33 9 7.5S8.33 6 7.5 6 6 6.67 6 7.5 6.67 9 7.5 9z"></path>
          </svg>
          <span>Завантажити</span>
        </button>
        <input ref={fileInputField} {...otherProps} type="file" className="form-field" id="file-upload" onChange={handleNewFileUpload} />
        <label htmlFor="file-upload" className="input-label"></label>
      </section>
      <article className="file-preview-container">
        <span>Обрані файли</span>
        <section className="preview-list">
          {/* For dynamically added files */}
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <section className="preview-container" key={fileName}>
                <div>
                  {isImageFile && (
                    <img src={URL.createObjectURL(file)} alt={`file preview ${index}`} className="image-preview" />
                  )}
                  {/* <div classname="file-meta-data" style={{ display: isImageFile ? "flex" : "none" }}> */}
                  <div className="file-meta-data">
                    <span className="image-description">{file.name}</span>
                    <aside>
                      <span>{convertBytesToMB(file.size)} Mb</span>
                      <i className="remove-file-icon fas fa-trash-alt" onClick={() => removeFile(fileName)}></i>
                    </aside>
                  </div>
                </div>
              </section>
            );
          })}
          {/* For existing files */}
          {(existedFiles && Array.isArray(existedFiles)) && existedFiles.map(function (data) {
            let file = data;
            let isImageFile = true;
            if (file && file.path && file.attachmentId && !file.isDefault)
              return (
                <section className="preview-container" key={file.attachmentId}>
                  <div>
                    {isImageFile && (
                      <img src={file.path} alt={file.description} className="image-preview"/>
                    )}
                    {/* <div classname="file-meta-data" style={{ display: isImageFile ? "flex" : "none" }}> */}
                    <div className="file-meta-data">
                      <span className="image-description">{file.description}</span>
                      <aside>
                        <i className="remove-file-icon fas fa-trash-alt" onClick={() => removeExistedFile(file.attachmentId)}></i>
                      </aside>
                    </div>
                  </div>
                </section>
              );
          })}
        </section>
      </article>
    </>
  );
};

export default AttachmentUploader;