const prefix = '/authentication';

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationPrefix: prefix,
    Login:  'login',
    Logout: 'logout',
    Register: 'register',
    Profile: 'profile',
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    Home: '',
    Dashboard: '/dashboard',
    Company: '/manager/company',
    Product: '/manager/product',
    ProductType: '/manager/product-type',
    Notification: '/manager/notification',
    Bonus: '/manager/bonus',
    Support: '/manager/support',
    AboutUs: '/manager/about-us',
    Welcome: '/manager/welcome',
    Order: '/manager/order',
    User: '/manager/user',
    OneC: '/manager/onec',
    ForgottenPassword: '/ForgottenPassword',
    ChangePassword: '/ChangePassword',
    ChangeEmail: '/ChangeEmail',
    ConfirmEmail: '/ConfirmEmail'
  };