import { UpdateSubscriptionRequest } from '../../services/manager/subscription/Request/UpdateSubscriptionRequest.ts';
import { UpdateSubscriptionResponse } from '../../services/manager/subscription/Response/UpdateSubscriptionResponse.ts';
import subscriptionService from '../../services/manager/subscription/subscription.service.tsx';

export class SubscriptionManager{

    async updateSubscription(isActive: boolean, userId: string): Promise<UpdateSubscriptionResponse>{
        let request: UpdateSubscriptionRequest = {
            userId,
            isActive
        }
        let response = await subscriptionService.updateSubscription(request);
        return response;
    }
}

const subscriptionManager = new SubscriptionManager();
export default subscriptionManager;