import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { UpdateWelcomeRequest } from './Request/UpdateWelcomeRequest.ts';
import { GetWelcomeResponse } from './Response/GetWelcomeResponse.ts';
import { UpdateWelcomeResponse } from './Response/UpdateWelcomeResponse.ts';

export class WelcomeService {

    async getWelcome() : Promise<GetWelcomeResponse> {
        let response = await api
        .get<GetWelcomeResponse>(`${ApplicationEndpoints.GetWelcome}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetWelcomeResponse();
    }

  async updateWelcome(request: UpdateWelcomeRequest): Promise<UpdateWelcomeResponse> {
    let response = await api
      .post<UpdateWelcomeResponse>(ApplicationEndpoints.UpdateWelcome,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new UpdateWelcomeResponse();
  }
}
const welcomeService = new WelcomeService();
export default welcomeService;