import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetBonusResponse } from './Response/GetBonusResponse.ts';
import { GetAllBonusesResponse } from './Response/GetAllBonusesResponse.ts';
import { CreateUpdateBonusRequest } from './Request/CreateUpdateBonusRequest.ts';
import { GetAllBonusesRequest } from './Request/GetAllBonusesRequest.ts';
import { CreateUpdateBonusResponse } from './Response/CreateUpdateBonusResponse.ts';
import { DeleteBonusRequest } from './Request/DeleteBonusRequest.ts';
import { DeleteBonusResponse } from './Response/DeleteBonusResponse.ts';
import { GetAllActiveBonusesSelectorResponse } from './Response/GetAllActiveBonusesSelectorResponse.ts';
import { GetAllActiveBonusesSelectorRequest } from './Request/GetAllActiveBonusesSelectorRequest.ts';
import { GetUserBonusCountResponse } from './Response/GetUserBonusCountResponse.ts';

export class BonusService {

    async getBonus(id: number) : Promise<GetBonusResponse> {
        let response = await api
        .get<GetBonusResponse>(`${ApplicationEndpoints.GetBonus}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetBonusResponse();
    }

    async getUserBonusCount(userId: string) : Promise<GetUserBonusCountResponse> {
      let response = await api
      .get<GetUserBonusCountResponse>(`${ApplicationEndpoints.GetUserBonus}?userId=${userId}`)
        .catch(
          (error) => {
          });
    return response?.data || new GetUserBonusCountResponse();
  }

    async getAllBonuses(request: GetAllBonusesRequest) : Promise<GetAllBonusesResponse> {
      let response = await api
      .post<GetAllBonusesResponse>(ApplicationEndpoints.GetBonuses,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllBonusesResponse();
  }

  async getAllActiveBonusesForSelector(request: GetAllActiveBonusesSelectorRequest): Promise<GetAllActiveBonusesSelectorResponse> {
    let response = await api
      .post<GetAllActiveBonusesSelectorResponse>(ApplicationEndpoints.GetBonusesForSelector,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new GetAllActiveBonusesSelectorResponse();
  }

  async updateCreateBonus(request: CreateUpdateBonusRequest): Promise<CreateUpdateBonusResponse> {
    let response = await api
      .post<CreateUpdateBonusResponse>(ApplicationEndpoints.UpdateBonus,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateBonusResponse();
  }

  async deleteBonus(request: DeleteBonusRequest) : Promise<DeleteBonusResponse> {
    let response = await api
    .post<DeleteBonusResponse>(ApplicationEndpoints.DeleteBonus,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteBonusResponse();
  }
}

const bonusService = new BonusService();
export default bonusService;