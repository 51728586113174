import {User} from '../models/user/User.ts'
import { UserUpdate } from '../models/user/UserUpdate.ts';
import { UserWithRole } from '../models/user/UserWithRole.ts';
import { UpdateUserRequest } from '../services/user/Request/UpdateUserRequest.ts';
import { GetUsersResponse } from '../services/user/Response/GetUsersResponse.ts';
import { UpdateUserResponse } from '../services/user/Response/UpdateUserResponse.ts';
import userService from '../services/user/user.service.tsx';

export class UserManager{
    
    async getUser(reload: boolean = false): Promise<User>{
        let localUser = localStorage.getItem('user');
        if(localUser && !reload)
            return JSON.parse(localUser);
        else
        {
            let response = await userService.getUser();
            let user = response.user;
            this.setUser(user);
            return user;
        }
    }

    async getUsers(currentPage: number = 0, pageSize: number = 0): Promise<GetUsersResponse> {
        let result = await userService.getUsers({ 
            currentPage: currentPage,
            pageSize: pageSize 
        });
        return result;
    }

    async updateUser(userUpdate: UserUpdate): Promise<UpdateUserResponse> {
        let result = await userService.updateUser({ ...userUpdate });
        return result;
    }

    async ForgottenPassword(email: string): Promise<UpdateUserResponse> {
        let result = await userService.ForgottenPassword({ email: email});
        return result;
    }

    async ChangePassword(token: string, newPassword: string, email: string): Promise<UpdateUserResponse> {
        let result = await userService.ChangePassword({ 
            token: token,
            newPassword: newPassword,
            email: email
        });
        return result;
    }

    async ChangeEmailAccept(token: string, newEmail: string, email: string): Promise<UpdateUserResponse> {
        let result = await userService.ChangeEmailAccept({ 
            token: token,
            newEmail: newEmail,
            email: email
        });
        return result;
    }

    async ConfirmEmail(token: string, email: string): Promise<UpdateUserResponse> {
        let result = await userService.ConfirmEmail({ 
            token: token,
            email: email
        });
        return result;
    }

    private setUser(user: User){
        if(user && user.id.length > 0)
            localStorage.setItem('user', JSON.stringify(user));
    }

    async removeUser(){
        localStorage.removeItem('user');
    }
}

const userManager = new UserManager();
export default userManager;