import React, { useState } from "react";
import { Product } from "../../../../../../models/manager/Product";
import { CalculationType } from "../../../../../../enums/CalculationType.ts";
import { BonusType } from "../../../../../../enums/BonusType.ts";
import { MeasureType } from "../../../../../../enums/MeasureType.ts";
import './ProductContainer.css';
import { CurrencyType } from "../../../../../../enums/CurrencyType.ts";

interface IProductContainerProps{
    product: Product;
    productCount: number;
}

interface IProductContainerState { 
    
}

export const ProductContainer: React.FC<IProductContainerProps> = (props) => {

    const [state, setState] = useState<IProductContainerState>(
        {

    });

    const getTypeString = (type: MeasureType) => {
        switch (type) {
            case MeasureType.Kilogram: return 'кг.';
            case MeasureType.Liter: return 'л.';
            case MeasureType.Piece: return 'шт.';
            default: return '';
        }
    }

    const getCurrencyLabel = (type: CurrencyType) => {
        switch (type) {
            case CurrencyType.UAH: return <span>&#8372;</span>;
            case CurrencyType.USD: return <span>&#36;</span> ;
            case CurrencyType.EUR: return <span>&#8364;</span> ;
            default: return <span> - </span>;
        }    
    }

    return (
        (props.product && props.product.productId > 0) &&
        <div className='order-item' key={props.product.productId}>
            {props.product.attachments && props.product.attachments[0] && props.product.attachments[0].path ?
                <img className='item-image' src={props.product.attachments[0].path} /> : <img className='item-image' src='data:,' />}
            <div className='item-params'>
                <div className='item-name text-overflow'>{props.product.name}</div>
                <div className='item-count'>{props.productCount} шт.</div>
                {(props.product?.bonus?.calculationType && props.product.bonus?.size && props.product.bonus?.bonusType === BonusType.Product) &&
                    <div className='item-bonus'>
                        <span>
                            {props.product.bonus.calculationType === CalculationType.Percent ?
                                (<span>{`${props.product.bonus.size} %`}</span>) :
                                props.product.bonus.calculationType === CalculationType.Piece &&
                                (<span>{props.product.bonus.size} &#8372;/{getTypeString(props.product.measureType)}</span>)
                            }
                        </span>
                    </div>
                }
                <div className='item-price'><span className="text-overflow">{props.product.price} {getCurrencyLabel(props.product.currencyType)}/{getTypeString(props.product.measureType)}</span></div>
            </div>
        </div>
    );
}