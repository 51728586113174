import { ColDef, GridApi, ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './NotificationManager.css';
import React, {useEffect, useMemo, useState} from "react";
import companyManager from '../../../managers/manager/company.manager.tsx';
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import notificationManager from '../../../managers/manager/notification.manager.tsx';
import { Notification } from '../../../models/manager/Notification.ts';
import { NotificationType } from '../../../enums/NotificationType.ts';
import { NotificationEditPopup } from './components/popup/NotificationEditPopup.tsx';

interface INotificationManagerProps{

}

interface INotificationManagerState{
    columnDefs: ColDef[];
    popupActive: boolean;
    currentNotificationId: number;
    loading: boolean;
    selectedrows: number[];
    pageSize: number;
}

export const NotificationManager: React.FC<INotificationManagerProps> = (props) => {

const LogoRenderer = (params: ICellRendererParams) => {
    return (
        <span className='logo'>
            {params.value && params.value.path && (
                <img
                    src={`${params.value.path}`}
                    className='image'
                />
            )}
        </span>
    );
}

const TypeRenderer = (params: ICellRendererParams) => {
    return (
        <span >
            {params.value && (
                params.value === NotificationType.All ? 'для всіх' :
                params.value === NotificationType.Personal && 'персональне'
            )}
        </span>
    );
}
const DateRenderer = (params: ICellRendererParams) => {
    return (
        <span >
            {params.value && getFormatedDate(new Date(params.value))}
        </span>
    );
}

const getFormatedDate = (date: Date) => {
    let d = new Date(date);
    let localDate = new Date(d.getTime() - d.getTimezoneOffset()*60*1000);
    return `${localDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${(localDate.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${localDate.getFullYear()}, ${localDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${localDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
}

    const [state, setState] = useState<INotificationManagerState>({
        columnDefs: [
            { headerName: '', field: 'checkboxBtn', checkboxSelection: true, headerCheckboxSelection: true, pinned: 'left', width: 50 },
            { headerName: '', field: 'notificationId', hide: true },
            { headerName: 'Заголовок', field: 'tittle', width: 250, sortable: false },
            { headerName: 'Текст сповіщення', field: 'description', width: 350, sortable: false },
            { headerName: 'Тип сповіщення', field: 'notificationType', cellRenderer: TypeRenderer, width: 150, hide: true, sortable: false },
            { headerName: 'Дата', field: 'date', width: 150, cellRenderer: DateRenderer, sortable: false },
            { headerName: 'Видимість', field: 'isActive', width: 100, sortable: false },
            { headerName: 'Зображення', field: 'attachment', cellRenderer: LogoRenderer, autoHeight: true, width: 150, sortable: false }
        ],
        popupActive: false,
        currentNotificationId: 0,
        loading: false,
        selectedrows: [],
        pageSize: 10
    });

    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
        if (gridApi) {
            let api = gridApi as GridApi;
            const dataSource = {
                getRows: (params: IGetRowsParams) => {
                    if (params?.endRow) {
                        let pageSize = state.pageSize;
                        const page = params.endRow / pageSize;
                        loadNotificationDataPagination(page, pageSize)
                            .then(res => {
                                params.successCallback(res.notifications, res.count);
                            });
                    }
                }
            }
            api.setGridOption('datasource', dataSource);
        }
    }, [gridApi]);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const paginationPageSizeSelector = useMemo(() => {
        return [10, 50, 100];
    }, []);

    const loadNotificationDataPagination = async (currentPage: number = 0, pageSize: number = 0) => {
        return await notificationManager.getAllNotifications(currentPage, pageSize);
    };

    const setActivePopup = (id?: number, open?: boolean) => {
        if (!(id == null) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    currentNotificationId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false
                })
            );
            hardReloadGrid();
        }
    }

    const grid_OnClickDelete = async () => {
        let notifications = state.selectedrows;
        if (notifications.length > 0) {
            notificationManager.deleteNotification(notifications).then((result) => {
                if (!result.isSuccessfull) 
                    alert(result.message?? "Не вдалось видалити!");
                hardReloadGrid();
                }
            );
        }
    }

    const onRowSelected = (e) => {
        if (gridApi) {
            let notifications = (gridApi as GridApi).getSelectedRows();
            let notifIds = Object.keys(notifications).map(function (key) {
                let obj = notifications[key];
                if(obj?.notificationId)
                    return obj.notificationId;
            });

            setState(prevstate =>
            ({
                ...prevstate,
                selectedrows: notifIds
            }));
        }
    }

    const hardReloadGrid = () => {
        //reload grid for new product type
        if (gridApi) {
            let api = gridApi as GridApi;
            api.refreshInfiniteCache()
        }
    }

        if (state.loading)
            return (
                <ManagerMenu action={ManagerMenuActions.Notification}>
                    <Loading />
                </ManagerMenu>);
        else
            return (
                <ManagerMenu action={ManagerMenuActions.Notification}>
                    <GridMenu 
                        isMenuActive={true} 
                        isAddButtonActive={true} 
                        isDeleteButtonActive={true} 
                        onClickAdd={() => {setActivePopup(0, true);}} 
                        onClickDelete={grid_OnClickDelete} 
                        isDeleteButtonDisabled={state.selectedrows.length === 0}/>
                    <Grid 
                        pagination={true} 
                        pageSize={state.pageSize} 
                        cacheBlockSize={state.pageSize}
                        height={500} 
                        rowSelection='multiple' 
                        onRowSelected={onRowSelected}
                        onRowClicked={(e) => { setActivePopup(e.data.notificationId, true); }} 
                        columnDefs={state.columnDefs} 
                        defaultColDef={this} 
                        paginationPageSizeSelector={paginationPageSizeSelector} 
                        onGridReady={onGridReady}
                        rowModelType='infinite'
                        rowHeight={60}/>
                    <NotificationEditPopup 
                        notificationId={state.currentNotificationId} 
                        isActive={state.popupActive} 
                        setActive={setActivePopup}>
                    </NotificationEditPopup>
                </ManagerMenu>);
}