import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './SupportManager.css';
import React, {useEffect, useState} from "react";
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import supportItemManager from '../../../managers/manager/supportItem.manager.tsx';
import { SupportItem } from '../../../models/manager/SupportItem.ts';
import { SupportItemType } from '../../../enums/SupportItemType.ts';
import { SupportEditPopup } from './components/popup/SupportEditPopup.tsx';

interface ISupportManagerProps{

}

interface ISupportManagerState{
    columnDefs: ColDef[];
    rowData: SupportItem[];
    popupActive: boolean;
    currentSupportItemId: number;
    loading: boolean;
    selectedrows: number[];
    updating: boolean;
}

export const SupportManager: React.FC<ISupportManagerProps> = (props) => {

    const TypeRenderer = (params: ICellRendererParams) => {
        return (
            <span >
                {params?.value && (
                    params.value === SupportItemType.Email ? 'Email' :
                    params.value === SupportItemType.FacebookMesenger ? 'Facebook' :
                    params.value === SupportItemType.PhoneNumber ? 'Phone' :
                    params.value === SupportItemType.Telegram ? 'Telegram' :
                    params.value === SupportItemType.Viber ? 'Viber' :
                    params.value === SupportItemType.Map && 'Map'
                )}
            </span>
        );
    }

    const [state, setState] = useState<ISupportManagerState>({
                columnDefs: [
                    { headerName: '', field: 'supportitemId', hide: true},
                    { headerName: 'Назва', field: 'tittle', width: 200 },
                    { headerName: 'Значення', field: 'value', width: 200 },
                    { headerName: 'Тип', field: 'supportItemType', cellRenderer: TypeRenderer, width: 150 },
                    { headerName: 'Видимість', field: 'isActive', width: 100 },
                ],
                rowData: [],
                popupActive: false,
                currentSupportItemId: 0,
                loading: true,
                selectedrows: [],
                updating: true
            });
    useEffect(() => {
        updateSupportData();
    }, [state.updating])

    const updateSupportData = async () => {
        if (state.updating) {
            let data = await supportItemManager.getAllSupportItems();
            setState(prevstate =>
            ({
                ...prevstate,
                rowData: data,
                selectedrows: [],
                loading: false,
                updating: false
            })
            );
        }
    };

    const setActivePopup = (id?: number, open?: boolean) => {
        if (!(id == null) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    currentSupportItemId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false,
                    loading: true,
                    updating: true
                })
            );
        }
    }

    if (state.loading)
        return (
            <ManagerMenu action={ManagerMenuActions.Support}>
                <Loading />
            </ManagerMenu>);
    else
        return (
            <ManagerMenu action={ManagerMenuActions.Support}>
                <GridMenu isMenuActive={true} isAddButtonActive={false} isDeleteButtonActive={false} />
                <Grid pagination={true} pageSize={20} height={500} style={{ width: '100%' }} rowData={state.rowData} rowSelection='single'
                    onRowClicked={(e) => { setActivePopup(e.data.supportItemId, true); }} columnDefs={state.columnDefs} defaultColDef={this} />
                <SupportEditPopup supportitemId={state.currentSupportItemId} isActive={state.popupActive} setActive={setActivePopup}></SupportEditPopup>
            </ManagerMenu>);
}