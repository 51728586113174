import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { UpdateAboutUsRequest } from './Request/UpdateAboutUsRequest.ts';
import { GetAboutUsResponse } from './Response/GetAboutUsResponse.ts';
import { UpdateAboutUsResponse } from './Response/UpdateAboutUsResponse.ts';

export class AboutUsService {

    async getAboutUs() : Promise<GetAboutUsResponse> {
        let response = await api
        .get<GetAboutUsResponse>(`${ApplicationEndpoints.GetAboutUs}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetAboutUsResponse();
    }

  async updateAboutUs(request: UpdateAboutUsRequest): Promise<UpdateAboutUsResponse> {
    let response = await api
      .post<UpdateAboutUsResponse>(ApplicationEndpoints.UpdateAboutUs,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new UpdateAboutUsResponse();
  }
}
const aboutUsService = new AboutUsService();
export default aboutUsService;