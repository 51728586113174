import {ProductType} from '../../models/manager/ProductType.ts'
import { CreateUpdateProductTypeRequest } from '../../services/manager/product-type/Request/CreateUpdateProductTypeRequest.ts';
import { CreateUpdateProductTypeResponse } from '../../services/manager/product-type/Response/CreateUpdateProductTypeResponse.ts';
import { DeleteProductTypeRequest } from '../../services/manager/product-type/Request/DeleteProductTypeRequest.ts';
import { DeleteProductTypeResponse } from '../../services/manager/product-type/Response/DeleteProductTypeResponse.ts';
import { GetAllProductTypesRequest } from '../../services/manager/product-type/Request/GetAllProductTypesRequest.ts';
import productTypeService from '../../services/manager/product-type/productType.service.tsx';
import { GetAllActiveProductTypesSelectorRequest } from '../../services/manager/product-type/Request/GetAllActiveProductTypesSelectorRequest.ts';
import { ProductTypeSelector } from '../../models/manager/ProductTypeSelector.ts';
import { UpdateCompanyProductTypeResponse } from '../../services/manager/product-type/Response/UpdateCompanyProductTypeResponse.ts';
import { UpdateCompanyProductTypeRequest } from '../../services/manager/product-type/Request/UpdateCompanyProductTypeRequest.ts';

export class ProductTypeManager{

    async getProductType(id: number): Promise<ProductType>{
        let response = await productTypeService.getProductType(id);
        return response.productType;
    }

    async getAllProductTypes(companyId: number = 0, currentPage: number = 0, pagesize: number = 0): Promise<ProductType[]>{
        let request: GetAllProductTypesRequest= {
            companyId: companyId,
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await productTypeService.getAllProductTypes(request);
        return response.productTypes;
    }

    async getAllActiveProductTypesForSelector(companyId: number, currentPage: number = 0, pagesize: number = 0): Promise<ProductTypeSelector[]>{
        let request: GetAllActiveProductTypesSelectorRequest = {
            currentPage: currentPage,
            pageSize: pagesize,
            companyId: companyId
        }
        let response = await productTypeService.getAllActiveProductTypesForSelector(request);
        return response.productTypes;
    }

    async updateCreateProductType(productType: ProductType): Promise<CreateUpdateProductTypeResponse>{
        let request: CreateUpdateProductTypeRequest = {
            productType: productType
        }
        let response = await productTypeService.updateCreateProductType(request);
        return response;
    }
    
    async updateCompanyProductType(productTypeBasics: number[], companyId: number): Promise<UpdateCompanyProductTypeResponse>{
        let request: UpdateCompanyProductTypeRequest = {
            productTypeBasics: productTypeBasics,
            companyId: companyId
        }
        let response = await productTypeService.updateCompanyProductType(request);
        return response;
    }

    async deleteProductType(productTypes: number[], isDeleteProducts: boolean): Promise<DeleteProductTypeResponse>{
        let request: DeleteProductTypeRequest = {
            productTypes: productTypes,
            isDeleteProducts: isDeleteProducts
        }
        let response = await productTypeService.deleteProductType(request);
        return response;
    }
}

const productTypeManager = new ProductTypeManager();
export default productTypeManager;