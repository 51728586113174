import { ColDef} from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './ProductTypeManager.css';
import React, {useEffect, useState} from "react";
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { ProductTypeBasic } from '../../../models/manager/ProductTypeBasic.ts';
import productTypeBasicManager from '../../../managers/manager/productTypeBasic.manager.tsx';
import { ProductTypeBasicEditPopup } from './components/popup/ProductTypeBasicEditPopup.tsx';
import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

interface IProductTypeManagerProps{

}

interface IProductTypeManagerState{
    columnDefs: ColDef[];
    rowData: ProductTypeBasic[];
    popupActive: boolean;
    productTypeBasicId: number;
    loading: boolean;
    selectedrows: number[];
    updating: boolean;
}

export const ProductTypeManager: React.FC<IProductTypeManagerProps> = (props) => {

    const [state, setState] = useState<IProductTypeManagerState>({
                columnDefs: [
                    { headerName: '', field: 'checkboxBtn', checkboxSelection: true, headerCheckboxSelection: true, pinned: 'left',  width: 50  },
                    { headerName: '', field: 'productTypeBasicId', hide: true},
                    { headerName: 'Назва', field: 'name', width: 250 },
                    { headerName: 'Видимість', field: 'isActive', width: 200 }
                ],
                rowData: [],
                popupActive: false,
                productTypeBasicId: 0,
                loading: true,
                selectedrows: [],
                updating: true
            });
            useEffect(() =>{
                updateProductTypeBasicData();
            }, [state.updating])

    const updateProductTypeBasicData = async () => {
        if (state.updating) {
            let data = await productTypeBasicManager.getAllProductTypeBasics();
            setState(prevstate =>
            ({
                ...prevstate,
                rowData: data,
                selectedrows: [],
                loading: false,
                updating: false
            })
            );
        }
    };

    const setActivePopup = (id?: number, open?: boolean) => {
        if (!(id == null) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    productTypeBasicId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false,
                    loading: true,
                    updating: true
                })
            );
        }
    }

    const grid_OnClickDelete = async () => {
        let productTypes = state.selectedrows;
        if (productTypes.length > 0) {
            let options: ReactConfirmAlertProps = {
                title: "Видалити категорію",
                message: "Видалити також продукти пов'язані з категорією?",
                buttons: [
                  {
                    label: "Так",
                    onClick: () => {
                        setTimeout(() => confirmDelete(true, productTypes), 250);
                    }
                  },
                  {
                    label: "Ні",
                    onClick: () => {
                        setTimeout(() => confirmDelete(false, productTypes), 250);
                    }
                  }
                ]
            }
            confirmAlert(options);
        }
    }

    const confirmDelete = (isDeleteProducts: boolean, productTypes: number[]) => {
        let opt: ReactConfirmAlertProps = {
            title: "Видалити категорію",
            message: isDeleteProducts ? 
                "Підтвердити видалення категорії разом з продуктами пов'язаними з нею?" :
                "Підтвердити видалення тільки категорії?",
            buttons: [
                {
                    label: "Так",
                    onClick: () => {
                        setState(prevstate =>
                        ({
                            ...prevstate,
                            loading: true
                        })
                        );
                        productTypeBasicManager.deleteProductTypeBasic(productTypes, isDeleteProducts).then((result) => {
                            if (!result.isSuccessfull) 
                                alert(result.message ?? "Не вдалось видалити категорію!");
                            setState(prevstate =>
                            ({
                                ...prevstate,
                                updating: true
                            }));
                        });
                    }
                },
                {
                    label: "Ні",
                    onClick: () => {}
                }
            ]
        }
        confirmAlert(opt);
    }

    const onRowSelected = (e) => {
        if(e && e.node && e.data && e.data.productTypeBasicId > 0)
        {
            if (e.node.selected) {
                setState(prevstate => 
                    ({
                        ...prevstate,
                        selectedrows: [...prevstate.selectedrows, e.data.productTypeBasicId]
                    })
                );
            }
            else
            {
                setState(prevstate => 
                    ({
                        ...prevstate,
                        selectedrows: prevstate.selectedrows.filter(function(Id) { 
                            return Id !== e.data.productTypeBasicId 
                        })
                    })
                );
            }
        }
    };

        if (state.loading)
            return (
                <ManagerMenu action={ManagerMenuActions.ProductType}>
                    <Loading />
                </ManagerMenu>);
        else
            return (
                <ManagerMenu action={ManagerMenuActions.ProductType}>
                    <GridMenu 
                        isMenuActive={true} 
                        isAddButtonActive={true} 
                        isDeleteButtonActive={true} 
                        onClickAdd={() => {setActivePopup(0, true);}}
                        onClickDelete={grid_OnClickDelete} 
                        isDeleteButtonDisabled={state.selectedrows.length === 0}/>
                    <Grid 
                        pagination={true} 
                        pageSize={20}  
                        height={500} 
                        rowData={state.rowData} 
                        rowSelection='multiple' 
                        onRowSelected={onRowSelected}
                        onRowClicked={(e) => { setActivePopup(e.data.productTypeBasicId, true); }} 
                        columnDefs={state.columnDefs} 
                        defaultColDef={this} />
                    <ProductTypeBasicEditPopup 
                        productTypeBasicId={state.productTypeBasicId} 
                        isActive={state.popupActive} 
                        setActive={setActivePopup}/>
                </ManagerMenu>);
}