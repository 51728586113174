export const ManagerMenuActions = {
    Company: 'company',
    ProductType: 'product-type',
    Product: 'product',
    Dashboard: 'dashboard',
    Notification: 'notification',
    Support: 'support',
    Bonus: 'bonus',
    AboutUs: 'about-us',
    Welcome: 'welcome',
    User: 'user',
    Order: 'order',
    OneC: 'onec'
  };