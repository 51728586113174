import './SelectFieldMultiple.css';
import React from "react"
import Select from "react-select";

export interface IOptionSelectFieldMultiple{
  label: string;
  value: string | number;
}

interface ISelectFieldMultipleProps{
  fieldName: string;
  fieldTittle: string;
  value?: IOptionSelectFieldMultiple[];
  options: IOptionSelectFieldMultiple[];
  onChange?: (e) => void;
  isRequired?: boolean;
  isMultiple?: boolean;
}

interface ISelectFieldMultipleState{

}

export const SelectFieldMultiple: React.FC<ISelectFieldMultipleProps> = (props) => {

    return (
      <div className='select-field-multiple-container'>
      <label className='select-field-multiple'>
          <Select className='select-field-multiple-input' name={props.fieldName} value={props.value} options={props.options} onChange={props.onChange} required={props.isRequired} isMulti={props.isMultiple}/>
        <span className='select-field-multiple-label'>{props.fieldTittle}</span>
      </label>
      </div>
      );
}