import {SupportItem} from '../../models/manager/SupportItem.ts'
import { CreateUpdateSupportItemRequest } from '../../services/manager/support-item/Request/CreateUpdateSupportItemRequest.ts';
import { CreateUpdateSupportItemResponse } from '../../services/manager/support-item/Response/CreateUpdateSupportItemResponse.ts';
import { DeleteSupportItemRequest } from '../../services/manager/support-item/Request/DeleteSupportItemRequest.ts';
import { DeleteSupportItemResponse } from '../../services/manager/support-item/Response/DeleteSupportItemResponse.ts';
import supportItemService from '../../services/manager/support-item/supportItem.service.tsx';
import { GetAllSupportItemsRequest } from '../../services/manager/support-item/Request/GetAllSupportItemsRequest.ts';

export class SupportItemManager{

    async getSupportItem(id: number): Promise<SupportItem>{
        let response = await supportItemService.getSupportItem(id);
        return response.supportItem;
    }

    async getAllSupportItems(currentPage: number = 0, pagesize: number = 0): Promise<SupportItem[]>{
        let request: GetAllSupportItemsRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await supportItemService.getAllSupportItems(request);
        return response.supportItems;
    }

    async updateCreateSupportItem(supportItem: SupportItem): Promise<CreateUpdateSupportItemResponse>{
        let request: CreateUpdateSupportItemRequest = {
            supportItem: supportItem
        }
        let response = await supportItemService.updateCreateSupportItem(request);
        return response;
    }
    
    async deleteSupportItem(supportItems: number[]): Promise<DeleteSupportItemResponse>{
        let request: DeleteSupportItemRequest = {
            supportItems: supportItems
        }
        let response = await supportItemService.deleteSupportItem(request);
        return response;
    }
}

const supportItemManager = new SupportItemManager();
export default supportItemManager;