import { ApplicationEndpoints } from '../../ApplicationEndpoints.js';
import api from '../api/api.attachment.authorized.js';
import { UploadAttachmentsRequest } from './Request/UploadAttachmentsRequest.ts';
import { UploadAttachmentsResponse } from './Response/UploadAttachmentsResponse.ts';

export class AttachmentService {

    // async UploadAttachments(request: UploadAttachmentsRequest) : Promise<UploadAttachmentsResponse> {
    //     let response = await api
    //     .post<UploadAttachmentsResponse>(ApplicationEndpoints.UploadAttachments,
    //       request)
    //       .catch((error) =>{
    //       });
    //   return response?.data || new UploadAttachmentsResponse();
    // }

    async UploadAttachments(request: FormData) : Promise<UploadAttachmentsResponse> {
        let response = await api
        .post<UploadAttachmentsResponse>(ApplicationEndpoints.UploadAttachments,
          request,
          {
            onUploadProgress: ProgressEvent => {
                if(ProgressEvent?.loaded && ProgressEvent?.total)
                    console.log(ProgressEvent.loaded / ProgressEvent?.total * 100);
            },
          })
          .catch((error) =>{
          });
      return response?.data || new UploadAttachmentsResponse();
    }

    // async UploadAttachments(request) : Promise<boolean> {
    //     let response = await api
    //     .post(ApplicationEndpoints.UploadAttachments,
    //       request,
    //       {
    //         onUploadProgress: ProgressEvent => {
    //             if(ProgressEvent?.loaded && ProgressEvent?.total)
    //                 console.log(ProgressEvent.loaded / ProgressEvent?.total * 100);
    //         },
    //       })
    //       .catch((error) =>{
    //       });
    //   return response?.status === 200 || false;
    // }
}

const attachmentService = new AttachmentService();
export default attachmentService;