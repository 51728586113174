import { OrdersCountResponse } from '../../services/manager/dashboard/Response/OrdersCountResponse.ts';
import { UserOrderByPriceResponse } from '../../services/manager/dashboard/Response/UserOrderByPriceResponse.ts';
import { UserOrdersResponse } from '../../services/manager/dashboard/Response/UserOrdersResponse.ts';
import { UsersCountResponse } from '../../services/manager/dashboard/Response/UsersCountResponse.ts';
import dashboardService from '../../services/manager/dashboard/dashboard.service.tsx';

export class DashboardManager{

    async getUserCount() : Promise<UsersCountResponse> {
        let response = await dashboardService.getUserCount();
      return response;
    }

    async getOrderCount(from: Date, to: Date) : Promise<OrdersCountResponse> {
        let fromStr = from.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
        let toStr = to.toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
        let response = await dashboardService.getOrderCount(fromStr, toStr);
      return response;
    }

    async getTopUserOrders(count: number) : Promise<UserOrdersResponse> {
        let response = await dashboardService.getTopUserOrders(count);
      return response;
    }

    async getTopUserOrdersByPrice(count: number) : Promise<UserOrderByPriceResponse> {
        let response = await dashboardService.getTopUserOrdersByPrice(count);
      return response;
    }
}

const dashboardManager = new DashboardManager();
export default dashboardManager;