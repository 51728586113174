import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetSupportItemResponse } from './Response/GetSupportItemResponse.ts';
import { GetAllSupportItemsResponse } from './Response/GetAllSupportItemsResponse.ts';
import { CreateUpdateSupportItemRequest } from './Request/CreateUpdateSupportItemRequest.ts';
import { GetAllSupportItemsRequest } from './Request/GetAllSupportItemsRequest.ts';
import { CreateUpdateSupportItemResponse } from './Response/CreateUpdateSupportItemResponse.ts';
import { DeleteSupportItemRequest } from './Request/DeleteSupportItemRequest.ts';
import { DeleteSupportItemResponse } from './Response/DeleteSupportItemResponse.ts';

export class SupportItemService {

    async getSupportItem(id: number) : Promise<GetSupportItemResponse> {
        let response = await api
        .get<GetSupportItemResponse>(`${ApplicationEndpoints.GetSupportItem}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetSupportItemResponse();
    }

    async getAllSupportItems(request: GetAllSupportItemsRequest) : Promise<GetAllSupportItemsResponse> {
      let response = await api
      .post<GetAllSupportItemsResponse>(ApplicationEndpoints.GetSupportItems,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllSupportItemsResponse();
  }

  async updateCreateSupportItem(request: CreateUpdateSupportItemRequest): Promise<CreateUpdateSupportItemResponse> {
    let response = await api
      .post<CreateUpdateSupportItemResponse>(ApplicationEndpoints.UpdateSupportItem,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateSupportItemResponse();
  }

  async deleteSupportItem(request: DeleteSupportItemRequest) : Promise<DeleteSupportItemResponse> {
    let response = await api
    .post<DeleteSupportItemResponse>(ApplicationEndpoints.DeleteSupportItem,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteSupportItemResponse();
  }
}

const bonusService = new SupportItemService();
export default bonusService;