import './UserCount.css';
import React, {useEffect, useState} from "react";
import dashboardManager from '../../../../../managers/manager/dashboard.manager.tsx';


interface IUserCountProps{

}

interface IUserCountState{
    loading: boolean;
    userCount: number;
}
export const UserCount: React.FC<IUserCountProps> = (props) => {
    const [state, setState] = useState<IUserCountState>({
        loading: true,
        userCount: 0
    });
    
    useEffect(() => {
        if(state.loading)
        {
            dashboardManager.getUserCount().then((response)=>{
                setState(prevstate => 
                    ({
                        ...prevstate,
                        loading: false,
                        userCount: response.userCount ?? 0
                    })
                );    
            });
        }
    }, [state.loading]);

    if (state.loading)
        return (
            <div className='loading-animated'></div>)
    else
        return (
            <div className={state.loading ? 'loading-animated' : ''}>
                <div className='dashboard-item-title'>Кількість <br/> користувачів</div>
                <div className='dashboard-item-description'><div className="number">{state.userCount}</div></div>
            </div>
        );
}
