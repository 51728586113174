import './UsedBonusPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { BonusHistory } from '../bonus-history/BonusHistory.tsx';
import bonusManager from '../../../../../managers/manager/bonus.manager.tsx';
import { UsedBonus } from '../../../../../models/manager/UsedBonus.ts';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import usedBonusManager from '../../../../../managers/manager/usedBonus.manager.tsx';
import { FormProvider, useForm } from 'react-hook-form';

interface IUsedBonusPopupProps{
  isActive: boolean;
  setActive: () => void;
  userId: string;
}

interface IUsedBonusPopupState{
  loading: boolean;
  bonusCount: number;
  usedBonus: UsedBonus;
}

export const UsedBonusPopup: React.FC<IUsedBonusPopupProps> = (props) => {

  const [state, setState] = useState<IUsedBonusPopupState>({
      loading: true,
      bonusCount: 0,
      usedBonus: {
        usedBonusId: 0,
        createDate: new Date,
        description: '',
        size: 0,
        currentSize: 0,
        userId: ''
      }
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      setValue('description','');
      setValue('size', 0);
      loadBonusData();
      setState(prevState => {
        return {
          ...prevState,
          usedBonus: {
            ...prevState.usedBonus,
            userId: props.userId
          },
          loading: false
        }});
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadBonusData = () => {
    if (props.userId && props.userId.length > 0) {
      bonusManager.getUserBonusCount(props.userId).then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            bonusCount: data.userBonusCount,
            loading: false
          }});
      }); 
    }
    else{
      closePopup();
    }
  };

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const onPopupUpdate = async () => {
    setLoad(true);
    let usedBonus = state.usedBonus;
    if (usedBonus.size > 0 && usedBonus.description.length > 0) {
      await usedBonusManager.createUsedBonus(usedBonus).then(async (data) => {
        if (!data.isSuccessfull) 
          alert(data.message ?? "Не вдалось списати бонуси!");
      });
    }
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
}

  return (
    <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle='Бонуси користувача' updateButtonTittle='Списати бонуси'>
      <FormProvider {...methods}>
      <TextField
        inputProps={{
          ...register('description',
          {
            onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, usedBonus: { ...prevState.usedBonus, description: value } })) },
            required: 'Це поле обов`язкове!',
            value: state.usedBonus.description,
            maxLength: {
              value: 200, message: 'Максимальна кількість знаків - 200!'
            }
          }) 
        }}
        errorMessage={errors.description?.message?.toString()}
        fieldTittle='Опис' />
      <TextField
        inputProps={{
          ...register('size',
          {
            onChange: (e) => { 
              let value = Number(e.target.value);
              setState(prevState => ({ ...prevState, usedBonus: { ...prevState.usedBonus, size: value } })) 
            },
            value: state.usedBonus.size ?? 0,
            valueAsNumber: true,
            required: 'Це поле обов`язкове!',
            validate: (value) => {if(Number.isNaN(value)) return 'Введіть число у форматі 9.99!'}, 
            max: {
              value: state.bonusCount ? state.bonusCount : 99999, 
              message: `Максимальна кількість до списання - ${state.bonusCount ? state.bonusCount : 99999}!`
            },
            min: {
              value: 0.01,
              message: `Мінімальна кількість до списання - 0.01!`
            }
          })
        }}
        errorMessage={errors.size?.message?.toString()}
        fieldTittle='Кількість' />
      <BonusHistory userId={props.userId} />
      <div className='user-bonus-count'>
        <span>
          Бонусів до списання:&nbsp;
          <span>
            {state.bonusCount}
          </span>
        </span>
      </div>
      </FormProvider>
    </Popup>
  )
}