import './ForgottenPassword.css';
import React, {useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Loading } from '../../components/loading/Loading.tsx';
import { TextField } from '../../components/textfield/TextField.tsx';
import userManager from '../../managers/user.manager.tsx';


interface IForgottenPasswordProps{
}

interface IForgottenPasswordState{
    loading: boolean;
    updating: boolean;
    error: string;
}

export const ForgottenPassword: React.FC<IForgottenPasswordProps> = (props) => {

  const [state, setState] = useState<IForgottenPasswordState>({
        error: '',
        updating: true,
        loading: false
  });

  useEffect(() =>{
    //loadAboutUsData();
}, [state.updating])

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const methods = useForm({
        defaultValues: {
            email: ''}
    });

    const { formState: { errors }} = methods;

    const onSubmit = async (data) => {
        setLoad(true);
        if (data && data.email) {
            await userManager.ForgottenPassword(data.email).then(async (data) => {
                if (!data?.isSuccessfull) 
                    alert(data.message);
                else
                    alert("На ваш email пошту надіслано посилання для зміни паролю!");
            });
        }
        setLoad(false);
    };

    return (
        state.loading ? <Loading /> :
        <div className='forgotten-password-container'>
            <div className='forgotten-password-logo'/>
            <div className='forgotten-password-center'>
                <h3 className='forgotten-password-tittle'>Сторінка відновлення доступу</h3>
                <form>
                    <FormProvider {...methods}>
                        <div className='forgotten-password-fields'>
                        <TextField
                            inputProps={{
                                ...methods.register("email",
                                    {
                                        required: 'Це поле обов`язкове!',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Введіть вірний email!'
                                        },
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 100!'
                                        }
                                    })
                            }}
                            fieldTittle='Email користувача'
                            errorMessage={errors?.email?.message} />
                            </div>
                        <input type='button' className='forgotten-password-button' value='Відновити' onClick={methods.handleSubmit(onSubmit)}></input>
                    </FormProvider>
                </form>
            </div>
        </div>
    );
}