import './Loading.css';
import * as React from "react"

interface ILoadingProps{
  hidden?: boolean;
}

interface ILoadingState{

}

export class Loading extends React.Component<ILoadingProps, ILoadingState> {
  constructor(props: ILoadingProps) {
    super(props);
  }
  componentDidMount() {
  }

  render() {
    return (
      <div className='loading-container' hidden={this.props.hidden ? this.props.hidden : false}>
        <div className='lds-dual-ring' />
      </div>);
  }
}