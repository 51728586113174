import { ApplicationEndpoints } from '../../ApplicationEndpoints.js';
import api from '../api/api.authorized.js'
import { GetUserResponse } from './Response/GetUserResponse.ts';
import { UpdateUserResponse } from './Response/UpdateUserResponse.ts';
import { UpdateUserRequest } from './Request/UpdateUserRequest.ts';
import { GetUsersRequest } from './Request/GetUsersRequest.ts';
import { GetUsersResponse } from './Response/GetUsersResponse.ts';
import { ForgottenPasswordRequest } from './Request/ForgottenPasswordRequest.ts';
import { ForgottenPasswordResponse } from './Response/ForgottenPasswordResponse.ts';
import { ChangePasswordRequest } from './Request/ChangePasswordRequest.ts';
import { ChangePasswordResponse } from './Response/ChangePasswordResponse.ts';
import { ChangeEmailRequest } from './Request/ChangeEmailRequest.ts';
import { ChangeEmailResponse } from './Response/ChangeEmailResponse.ts';
import { ConfirmEmailRequest } from './Request/ConfirmEmailRequest.ts';
import { ConfirmEmailResponse } from './Response/ConfirmEmailResponse.ts';

export class UserService {

    async getUser(i: number = 0) : Promise<GetUserResponse> {
        let response = await api.get<GetUserResponse>(ApplicationEndpoints.GetUser)
        .catch(
          (error)=>{
          });
      return response?.data || new GetUserResponse();
    }

    async getUsers(request: GetUsersRequest) : Promise<GetUsersResponse> {
      let response = await api.post<GetUsersResponse>(ApplicationEndpoints.GetUsers,
        request)
      .catch(
        (error)=>{
        });
    return response?.data || new GetUsersResponse();
  }

  async ForgottenPassword(request: ForgottenPasswordRequest): Promise<ForgottenPasswordResponse> {
    let response = await api.post<ForgottenPasswordResponse>(ApplicationEndpoints.ForgottenPassword,
      request)
      .catch(
        (error) => {
        });
    return response?.data || new ForgottenPasswordResponse();
  }

  async ChangePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    let response = await api.post<ChangePasswordResponse>(ApplicationEndpoints.ChangePassword,
      request)
      .catch(
        (error) => {
        });
    return response?.data || new ChangePasswordResponse();
  }

  async ChangeEmailAccept(request: ChangeEmailRequest): Promise<ChangeEmailResponse> {
    let response = await api.post<ChangeEmailResponse>(ApplicationEndpoints.ChangeEmail,
      request)
      .catch(
        (error) => {
        });
    return response?.data || new ChangeEmailResponse();
  }

  async ConfirmEmail(request: ConfirmEmailRequest): Promise<ConfirmEmailResponse> {
    let response = await api.post<ConfirmEmailResponse>(ApplicationEndpoints.ConfirmEmail,
      request)
      .catch(
        (error) => {
        });
    return response?.data || new ConfirmEmailResponse();
  }

  async updateUser(request: UpdateUserRequest) : Promise<UpdateUserResponse> {
    let response = await api.post<UpdateUserResponse>(ApplicationEndpoints.UpdateUser,
      request)
    .catch(
      (error)=>{
      });
  return response?.data || new UpdateUserResponse();
}
}

const userService = new UserService();
export default userService;