import { Loading } from '../loading/Loading.tsx';
import './Popup.css';
import React, {Component, PropsWithChildren} from "react"

interface IPopupProps{
  isActive: boolean;
  tittle: string;
  onClose?: (e) => void;
  onUpdate?: (e) => void;
  loading?: boolean;
  updateButtonTittle?: 'Оновити' | 'Додати' | string;
}

interface IPopupState{
}

export const Popup: React.FC<PropsWithChildren<IPopupProps>> = (props) => {

  const getContent = () => {
    if (!props.loading)
      return (<div className='popup-content'>{props.children}</div>)
    else
      return (<Loading />)
  }

  return (
    <div className={`popup-window ${props.isActive ? 'active' : ''}`} onClick={props.onClose}>
        <form onSubmit={props.onUpdate} className={`popup-container ${props.isActive ? 'active' : ''}`} onClick={e => e.stopPropagation()}>
            <div className='popup-title'>
                <h4 className='mb-0'>{props.tittle}</h4>
            </div>
            {getContent()}
            <div className='popup-buttons'>
                <input type='submit' className='popup-button' value={props.updateButtonTittle ? props.updateButtonTittle : 'Оновити'}></input>
                <input type='button' className='popup-button' onClick={props.onClose} value={'Закрити'}></input>
            </div>
        </form>
    </div>);
}