import './SupportEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { SupportItem } from '../../../../../models/manager/SupportItem.ts';
import supportManager from '../../../../../managers/manager/supportItem.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { SelectField, IOptionSelectField } from '../../../../../components/selectfield/SelectField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import AttachmentUploader from '../../../../../components/attachment/AttachmentUploader.tsx';
import attachmentManager from '../../../../../managers/attachment.manager.tsx';
import { AttachmentType } from '../../../../../enums/AttachmentType.ts';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { TextArea } from '../../../../../components/textarea/TextArea.tsx';
import { SupportItemType } from '../../../../../enums/SupportItemType.ts';
import { FormProvider, useForm } from 'react-hook-form';
import useDebounce from '../../../../../custom-hooks/useDebounce.jsx'

interface ISupportEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  supportitemId: number;
}

interface ISupportEditPopupState{
  support: SupportItem;
  loading: boolean;
  mapUrlCriteriaError: string;
  isMapUrlEdit: boolean;
}

export const SupportEditPopup: React.FC<ISupportEditPopupProps> = (props) => {

  const [state, setState] = useState<ISupportEditPopupState>({
      support: {
        supportItemId: 0,
        tittle: '',
        value: '',
        supportItemType: SupportItemType.None,
        isActive: false,
        url: ''
      },
      loading: true,
      mapUrlCriteriaError: '',
      isMapUrlEdit: false
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      if (props?.supportitemId > 0) {
        loadSupportData();
      }
      else
      {
        setState(prevState  => {
          return {
          ...prevState,
          support: {
            supportItemId: 0,
            tittle: '',
            value: '',
            supportItemType: SupportItemType.None,
            isActive: false,
            url: ''
          },
          loading: false,
          mapUrlCriteriaError: '',
          isMapUrlEdit: false    
        }});
      }
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, watch, formState: { errors }} = methods;

  const { debounce } = useDebounce();

  const loadSupportData = () => {
    if (props.supportitemId > 0) {
      supportManager.getSupportItem(props.supportitemId).then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            support: {...data},
            loading: false
          }});
          setValue('tittle', data.tittle);
          setValue('value', data.value);
      }); 
    }
  };

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

  const onPopupUpdate = async () => {
    setLoad(true);
    let support = state.support;
    await supportManager.updateCreateSupportItem(support).then(async (data) => {
      if (!data.isSuccessfull) 
        alert(data.message ?? "Не вдалось оновити елемент!");
    });
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
}

const emailValidation = (type: SupportItemType) => {
  if (type && type === SupportItemType.Email)
    return {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Введіть правильну електронну пошту!'
    };
  else
    return undefined;
}

const handleMapURLChange = debounce(e => MapUrlCriteriaOnChange(e), 500)

const MapUrlCriteriaOnChange = (e) => 
{
    let value = e.target.value;
    if(value && value.length < 100)
    {
        let param = encodeURIComponent(value);
        let gMap = `https://www.google.com/maps/search/?api=1&query=${param}`
        setState(prevstate => {
            return {
              ...prevstate,
              mapUrlCriteriaError: '',
              support: {
                ...prevstate.support,
                url: gMap,
                value: value
              }
            }});
    }
    else
    {
        setState(prevstate => {
            return {
              ...prevstate,
              mapUrlCriteriaError: 'Поле повинно містити від 1 до 100 знаків!'
            }});
    }
}


  return (
    <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle='Редагувати сповіщення' updateButtonTittle={props.supportitemId === 0 ? "Додати" : undefined}>
      <FormProvider {...methods}>
      <TextField
          inputProps={{
            ...register('tittle',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, support: { ...prevState.support, tittle: value } })) }, 
                value: state.support.tittle,
                required: 'Це поле обов`язкове!',
                maxLength: {
                  value: 100, message: 'Максимальна кількість знаків - 100!'
                }
              })
          }}
          errorMessage={errors.tittle?.message?.toString()}
          fieldTittle='Заголовок' />
        {state.support.supportItemType !== SupportItemType.Map ?
          <TextField
            inputProps={{
              ...register('value',
                {
                  onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, support: { ...prevState.support, value: value } })) },
                  value: state.support.value,
                  required: 'Це поле обов`язкове!',
                  pattern: emailValidation(state.support.supportItemType),
                  maxLength: {
                    value: 100, message: 'Максимальна кількість знаків - 100!'
                  }
                })
            }}
            errorMessage={errors.value?.message?.toString()}
            fieldTittle='Значення' />
          :
          <div className='address-link-edit-container'>
            <div className='address-link-container'>
              <a
                className='address-link'
                href={state.support.url}
                target='_blank'>
                Посилання на геолокацію
              </a>
              <button
                type='button'
                className='address-link-button-edit'
                onClick={() => {
                  setState(prevstate => {
                    return {
                      ...prevstate,
                      isMapUrlEdit: true
                    }
                  });
                }}>
                Редагувати
              </button>
            </div>
            {state.isMapUrlEdit &&
              <div>
                <TextField
                  inputProps={{
                    ...register('value',
                      {
                        onChange: handleMapURLChange,
                        value: state.support.value,
                        required: 'Це поле обов`язкове!',
                        maxLength: {
                          value: 100, message: 'Максимальна кількість знаків - 100!'
                        }
                      })
                  }}
                  fieldTittle='Адреса, координати...'
                  errorMessage={state.mapUrlCriteriaError} />
              </div>
            }
            <span className='geo-field-tittle'>Геолокація</span>
          </div>
        }
        <CheckBox 
          inputProps={{
            name: 'isActive',
            onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, support: { ...prevState.support, isActive: value } })) }, 
            checked: state.support.isActive
          }}
          fieldTittle='Видимість' />
      </FormProvider>
    </Popup>
  )
}