import './NotificationEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { Notification } from '../../../../../models/manager/Notification.ts';
import notificationManager from '../../../../../managers/manager/notification.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { SelectField, IOptionSelectField } from '../../../../../components/selectfield/SelectField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import AttachmentUploader from '../../../../../components/attachment/AttachmentUploader.tsx';
import attachmentManager from '../../../../../managers/attachment.manager.tsx';
import { AttachmentType } from '../../../../../enums/AttachmentType.ts';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { TextArea } from '../../../../../components/textarea/TextArea.tsx';
import { NotificationType } from '../../../../../enums/NotificationType.ts';
import { FormProvider, useForm } from 'react-hook-form';

interface INotificationEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  notificationId: number;
}

interface INotificationEditPopupState{
  notification: Notification;
  loading: boolean;
  image?: File;
}

export const NotificationEditPopup: React.FC<INotificationEditPopupProps> = (props) => {

  const [state, setState] = useState<INotificationEditPopupState>({
      notification: {
        notificationId: 0,
        tittle: '',
        description: '',
        notificationType: NotificationType.None,
        date: new Date(),
        isActive: false,
        attachment: undefined
      },
      loading: true,
      image: undefined
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      loadNotificationData();
      setState(prevState => {
        return {
          ...prevState,
          image: undefined
        }
      });
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadNotificationData = () => {
    if (props.notificationId > 0) {
      notificationManager.getNotification(props.notificationId).then((data) =>
      {
        UpdateGridAndState(data);
      }); 
    }
    else {
      let data: Notification = {
        notificationId: 0,
        tittle: '',
        description: '',
        notificationType: NotificationType.None,
        date: new Date(),
        isActive: false,
        attachment: undefined
      };
      UpdateGridAndState(data);
    }
  };

  const UpdateGridAndState = (data: Notification) => {
    setState(prevState => {
      return {
        ...prevState,
        notification: {...data},
        loading: false
      }});
      setValue('tittle', data.tittle);
      setValue('description', data.description);
  }

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const updateImages = (files: File[]) => {
    setState(prevstate =>
      ({
        ...prevstate,
        image: files[0]
      }));
  };

  const updateExistingImages = (idRemove: number) => {
    let notification = state.notification;
    setState(prevstate =>
      ({
        ...prevstate,
        notification: {
          ...notification,
          attachment: prevstate.notification.attachment?.attachmentId === idRemove ? undefined : prevstate.notification.attachment
        }
      }));
  };

  const onPopupUpdate = async () => {
    setLoad(true);
    let notification = state.notification;
    notification.notificationType = NotificationType.All; //default value
    if (notification.attachment) {
      notification.attachment = notification.attachment.isDefault ? undefined : notification.attachment;
    }

    await notificationManager.updateCreateNotification(notification).then(async (data) => {
      if (!data.isSuccessfull) 
        alert(data.message ?? "Не вдалось оновити сповіщення!");
      let file = state.image;
      if (file && (state.notification.notificationId > 0 || (data?.notificationId && data?.notificationId > 0))) {
        let id = state.notification.notificationId > 0 ? state.notification.notificationId : data.notificationId as number;
        await attachmentManager.uploadAttachments([file], AttachmentType.Notification, id).then((attdata) => {
          if (!attdata.isSuccessfull) 
            alert(attdata.message ?? "Не вдалось завантажити зображення!");
        });
      }
    });
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
}

const NotificationTypeOptions: IOptionSelectField[] = 
  [
    { label: 'Для всіх', value: NotificationType.All.valueOf()},
    { label: 'Персональне', value: NotificationType.Personal.valueOf()}
  ];

  return (
    <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle='Редагувати сповіщення' updateButtonTittle={props.notificationId === 0 ? "Додати" : undefined}>
      <FormProvider {...methods}>
      <TextField
          inputProps={{
            ...register('tittle',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, notification: { ...prevState.notification, tittle: value } })) },
                required: 'Це поле обов`язкове!',
                value: state.notification.tittle,
                maxLength: {
                  value: 100, message: 'Максимальна кількість знаків - 100!'
                }
              })
          }}
          errorMessage={errors.tittle?.message?.toString()}
          fieldTittle='Заголовок' />
        <TextArea
          inputProps={{
            ...register('description',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, notification: { ...prevState.notification, description: value } })) }, 
                value: state.notification.description,                
                required: 'Це поле обов`язкове!',
                maxLength: {
                  value: 500, message: 'Максимальна кількість знаків - 500!'
                }
              })
          }}
          errorMessage={errors.description?.message?.toString()}
          fieldTittle='Текст сповіщення' />

        <CheckBox
          inputProps={{ 
            onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, notification: { ...prevState.notification, isActive: value } })) },
            checked: state.notification.isActive,
            name: 'isActive' 
          }}
          fieldTittle='Видимість' />
        <AttachmentUploader
          accept=".jpg,.png,.jpeg"
          allowedTypes={["image/jpeg", "image/png"]}
          label=""
          maxAttachmentCount={1}
          updateFilesCb={(e) => updateImages(e)}
          existedFiles={state.notification.attachment && !state.notification.attachment.isDefault && [state.notification.attachment]}
          updateExistedFilesCb={(e) => updateExistingImages(e)}
        />
      </FormProvider>
    </Popup>
  )
}