import { AboutUs } from '../../models/manager/AboutUs.ts'
import { UpdateAboutUsRequest } from '../../services/manager/about-us/Request/UpdateAboutUsRequest.ts';
import { UpdateAboutUsResponse } from '../../services/manager/about-us/Response/UpdateAboutUsResponse.ts';
import aboutUsService from '../../services/manager/about-us/aboutUs.service.tsx';

export class AboutUsManager{

    async getAboutUs(): Promise<AboutUs>{
        let response = await aboutUsService.getAboutUs();
        return response.aboutUs;
    }


    async updateAboutUs(aboutUs: AboutUs): Promise<UpdateAboutUsResponse>{
        let request: UpdateAboutUsRequest = {
            aboutUs: aboutUs
        }
        let response = await aboutUsService.updateAboutUs(request);
        return response;
    }
}

const aboutUsManager = new AboutUsManager();
export default aboutUsManager;