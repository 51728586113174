import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Привіт!</h1>
        <h3>Вітаємо у менеджменті EuroLand!</h3>
        <p>Тут ви зможете :</p>
        <ul>
        <li>Додати/редагувати товари.</li>
        <li>Додати/редагувати бонуси для товарів.</li>
        <li>Слідкувати за замовленнями.</li>
        <li>...та багато іншого</li>
        </ul>
      </div>
    );
  }
}
