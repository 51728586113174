import './ChangeEmailAccept.css';
import React, {useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Loading } from '../../components/loading/Loading.tsx';
import { TextField } from '../../components/textfield/TextField.tsx';
import userManager from '../../managers/user.manager.tsx';
import { useLocation } from 'react-router-dom';


interface IChangeEmailAcceptProps{
}

interface IChangeEmailAcceptState{
    loading: boolean;
    updating: boolean;
    error: string;
    token: string;
    email: string;
    newEmail: string;
}

export const ChangeEmailAccept: React.FC<IChangeEmailAcceptProps> = (props) => {

  const [state, setState] = useState<IChangeEmailAcceptState>({
        error: '',
        updating: true,
        loading: false,
        token: '',
        email: '',
        newEmail: ''
  });

  const location = useLocation();

    useEffect(() => {
        if (state.updating) {
            const query = new URLSearchParams(location.search);
            const token = query.get('token');
            const email = query.get('email');
            const newEmail = query.get('newemail');
            if (token && token.length > 0 && email && email.length > 0 && newEmail && newEmail.length > 0) {
                setState(prevstate => {
                    return {
                        ...prevstate,
                        token: token,
                        email: email,
                        newEmail: newEmail,
                        updating: false
                    }
                });
            }
        }
    }, [state.updating])

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const methods = useForm({
        defaultValues: {
            newPassword: '',
            Password: ''
        }
    });

    const { formState: { errors }} = methods;

    const onClick = async (e) => {
        if (state.token && state.email && state.newEmail) {
            setLoad(true);
            await userManager.ChangeEmailAccept(state.token, state.newEmail, state.email).then(async (data) => {
                if (!data?.isSuccessfull) 
                    alert(data.message);
                else
                {
                    alert("Email успішно змінено!");
                }
            });
        }
        else
            alert("Не вдалось змінити email, спробуйте пізніше.");
        setLoad(false);
    };

    return (
        state.loading ? <Loading /> :
            <div className='change-email-accept-container'>
                <div className='change-email-accept-logo' />
                <div className='change-email-accept-center'>
                    <h3 className='change-email-accept-tittle'>
                        Ви подали запит на зміну email.
                    </h3>
                    <table>
                        <td>
                            <tr>
                                <span className='change-email-accept-email'>
                                    Старий email: {state.email}
                                </span>
                            </tr>
                            <tr>
                                <span className='change-email-accept-email'>
                                    Новий email: {state.newEmail}
                                </span>
                            </tr>
                        </td>
                    </table>
                    <h6 className='change-email-accept-message'>
                        У разі якщо ви не подавали такий запит, не підтверджуйте зміну!
                    </h6>
                    <div className='change-email-accept-fields'>
                    </div>
                    <input type='button' className='change-email-accept-button' value='Змінити' onClick={onClick}></input>
                </div>
            </div>
    );
}