import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { UpdateCurrencyRateRequest } from './Request/UpdateCurrencyRateRequest.ts';
import { GetCurrencyRatesResponse } from './Response/GetCurrencyRatesResponse.ts';
import { UpdateCurrencyRateResponse } from './Response/UpdateCurrencyRateResponse.ts';

export class OneCService {

    async GetCurrencyRates() : Promise<GetCurrencyRatesResponse> {
        let response = await api
        .get<GetCurrencyRatesResponse>(`${ApplicationEndpoints.GetCurrencyRates}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetCurrencyRatesResponse();
    }

  async UpdateCurrencyRate(request: UpdateCurrencyRateRequest): Promise<UpdateCurrencyRateResponse> {
    let response = await api
      .post<UpdateCurrencyRateResponse>(ApplicationEndpoints.UpdateCurrencyRate,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new UpdateCurrencyRateResponse();
  }
}
const oneCService = new OneCService();
export default oneCService;