const prefix = '/authentication';

export const ApplicationEndpoints = {
    Home: '',
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationPrefix: prefix,

    GetUser: 'api/User',
    GetUsers: 'api/User/manager',
    UpdateUser: 'api/User/update',
    ForgottenPassword: 'api/User/forgottenpassword',
    ChangePassword: 'api/User/changepassword',
    ChangeEmail: 'api/User/changeemailaccept',
    ConfirmEmail: 'api/User/confirmemail',

    Login:  'api/Auth/login',
    Logout: `api/Auth/logout`,
    RefreshToken: `api/Auth/refresh-token`,    
    Register: `api/Auth/register`,

    GetCompany: 'api/Company',
    GetCompanies: 'api/Company',
    GetCompaniesForSelector: 'api/Company/manager/selector',
    UpdateCompany: 'api/Company/update',
    DeleteCompany: 'api/Company/delete',

    UploadAttachments: 'api/Attachment',

    GetProduct: 'api/Product/manager',
    GetProducts: 'api/Product',
    UpdateProduct: 'api/Product/update',
    DeleteProduct: 'api/Product/delete',

    GetProductType: 'api/ProductType',
    GetProductTypes: 'api/ProductType',
    GetProductTypesForSelector: 'api/ProductType/manager/selector',
    UpdateProductType: 'api/ProductType/update',
    UpdateCompanyProductType: 'api/ProductType/update/companytypes',
    DeleteProductType: 'api/ProductType/delete',

    GetProductTypeBasic: 'api/ProductType/basic',
    GetProductTypeBasics: 'api/ProductType/basic',
    GetProductTypeBasicsSelectedByCompanyId: 'api/ProductType/basic/selected',
    UpdateProductTypeBasic: 'api/ProductType/basic/update',
    DeleteProductTypeBasic: 'api/ProductType/basic/delete',

    GetBonus: 'api/Bonus',
    GetUserBonus: 'api/Bonus',
    GetBonuses: 'api/Bonus/manager',
    GetBonusesForSelector: 'api/Bonus/manager/selector',
    UpdateBonus: 'api/Bonus/update',
    DeleteBonus: 'api/Bonus/delete',

    GetNotification: 'api/Notification/manager',
    GetNotifications: 'api/Notification/manager',
    UpdateNotification: 'api/Notification/update',
    DeleteNotification: 'api/Notification/delete',

    GetSupportItem: 'api/Support',
    GetSupportItems: 'api/Support',
    UpdateSupportItem: 'api/Support/update',
    DeleteSupportItem: 'api/Support/delete',

    GetWelcome: 'api/Welcome',
    UpdateWelcome: 'api/Welcome',

    GetAboutUs: 'api/AboutUs',
    UpdateAboutUs: 'api/AboutUs',

    GetOrders: 'api/Order/manager',
    OrderChangeStatus: 'api/Order/manager/changestatus',
    GetOrderHistory: 'api/Order/manager/history',

    GetUserUsedBonuses: "api/UsedBonus/history",
    CreateUsedBonus: "api/UsedBonus/create",

    UpdateSubscription: "api/Subscription/update",

    UserCountDashboard: "api/Dashboard/usercount",
    OrderCountDashboard: "api/Dashboard/ordercount",
    TopUserOrderDashboard: "api/Dashboard/topuserorder",
    TopUserOrderByPriceDashboard: "api/Dashboard/topuserorderbyprice",

    GetCurrencyRates: "api/OneC",
    UpdateCurrencyRate: "api/OneC/update"
  };