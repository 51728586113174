import { CurrencyType } from '../../enums/CurrencyType.ts';
import { CurrencyRate } from '../../models/manager/CurrencyRate.ts';
import { UpdateCurrencyRateResponse } from '../../services/manager/one-c/Response/UpdateCurrencyRateResponse.ts';
import oneCService from '../../services/manager/one-c/oneC.service.tsx';

export class OneCManager{

    async GetCurrencyRates(): Promise<CurrencyRate[]>{
        let response = await oneCService.GetCurrencyRates();
        return response.currencyRates;
    }


    async UpdateCurrencyRate(currencyRates: CurrencyRate[]): Promise<UpdateCurrencyRateResponse>{
        let response = await oneCService.UpdateCurrencyRate({
            currencyRates
        });
        return response;
    }
}

const onecManager = new OneCManager();
export default onecManager;