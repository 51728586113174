import {Notification} from '../../models/manager/Notification.ts'
import { CreateUpdateNotificationRequest } from '../../services/manager/notification/Request/CreateUpdateNotificationRequest.ts';
import { CreateUpdateNotificationResponse } from '../../services/manager/notification/Response/CreateUpdateNotificationResponse.ts';
import { DeleteNotificationRequest } from '../../services/manager/notification/Request/DeleteNotificationRequest.ts';
import { DeleteNotificationResponse } from '../../services/manager/notification/Response/DeleteNotificationResponse.ts';
import notificationService from '../../services/manager/notification/notification.service.tsx';
import { GetAllNotificationsRequest } from '../../services/manager/notification/Request/GetAllNotificationsRequest.ts';
import { GetAllNotificationsResponse } from '../../services/manager/notification/Response/GetAllNotificationsResponse.ts';

export class NotificationManager{

    async getNotification(id: number): Promise<Notification>{
        let response = await notificationService.getNotification(id);
        return response.notification;
    }

    async getAllNotifications(currentPage: number = 0, pagesize: number = 0): Promise<GetAllNotificationsResponse>{
        let request: GetAllNotificationsRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await notificationService.getAllNotifications(request);
        return response;
    }

    async updateCreateNotification(notification: Notification): Promise<CreateUpdateNotificationResponse>{
        let request: CreateUpdateNotificationRequest = {
            notification: notification
        }
        let response = await notificationService.updateCreateNotification(request);
        return response;
    }
    
    async deleteNotification(notifications: number[]): Promise<DeleteNotificationResponse>{
        let request: DeleteNotificationRequest = {
            notifications: notifications
        }
        let response = await notificationService.deleteNotification(request);
        return response;
    }
}

const notificationManager = new NotificationManager();
export default notificationManager;