import './TextField.css';
import React, { useState } from "react"

interface ITextFieldProps {
  fieldTittle: string;
  type?: string;
  errorMessage?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

interface ITextFieldState{

}

export const TextField: React.FC<ITextFieldProps> = (props) => {

  return (
    <div>
      <div className='field-container'>
        <label className='field'>
          <input className='field-input'
            {...props.inputProps}
            type={props.type ? props.type : 'text'}
            placeholder=' ' >
          </input>
          <span className='field-label'>{props.fieldTittle} </span>
        </label>
      </div>
      <div className='field-error'>
        <span>{props.errorMessage ? props.errorMessage : ''}</span>
      </div>
    </div>
  );
}