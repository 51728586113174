import './OrderCount.css';
import React, {useEffect, useState} from "react";
import dashboardManager from '../../../../../managers/manager/dashboard.manager.tsx';


interface IOrderCountProps{

}

interface IOrderCountState{
    loading: boolean;
    ordersCount: number;
}
export const OrderCount: React.FC<IOrderCountProps> = (props) => {
    const [state, setState] = useState<IOrderCountState>({
        loading: true,
        ordersCount: 0
    });

    useEffect(() => {
        if(state.loading)
        {
            //local date now
            let now = new Date();
            //local date today without hours, starts from 00:00
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            //convert to UTC, date today 00:00 in UTC
            let utcFrom = new Date(today.getTime() + today.getTimezoneOffset()*60*1000);
            //convert to UTC, next day 00:00 in UTC
            let utcTo = new Date(utcFrom.getFullYear(), utcFrom.getMonth(), utcFrom.getDate() + 1, utcFrom.getHours());

            dashboardManager.getOrderCount(utcFrom, utcTo).then((response)=>{
                setState(prevstate => 
                    ({
                        ...prevstate,
                        loading: false,
                        ordersCount: response.ordersCount  ?? 0
                    })
                );    
            });
        }
    }, [state.loading]);

    if (state.loading)
        return (
            <div className='loading-animated'></div>)
    else
        return (
        <div>
            <div className='dashboard-item-title'>Кількість замовлень <br/> за сьогодні</div>
            <div className='dashboard-item-description'><div className="number">{state.ordersCount}</div></div>
        </div>
    );
}
