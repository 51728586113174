import { ColDef, GridApi, ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './ProductManager.css';
import React, {useEffect, useMemo, useRef, useState} from "react";
import productManager from '../../../managers/manager/product.manager.tsx';
import { ProductEditPopup } from './components/popup/ProductEditPopup.tsx';
import { Product } from '../../../models/manager/Product.ts';
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { MeasureType } from '../../../enums/MeasureType.ts';
import { IOptionSelectField, SelectField } from '../../../components/grid/menu/selectfield/SelectField.tsx';
import companyManager from '../../../managers/manager/company.manager.tsx';
import productTypeManager from '../../../managers/manager/productType.manager.tsx';
import { CurrencyType } from '../../../enums/CurrencyType.ts';
import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';


interface IProductManagerProps{

}

interface IProductManagerState{
    columnDefs: ColDef[];
    popupActive: boolean;
    currentProductId: number;
    loading: boolean;
    selectedrows: number[];
    selectedCompany: number;
    selectedProductType: number;
    companies: IOptionSelectField[];
    productTypes: IOptionSelectField[];
    pageSize: number;
}

export const ProductManager: React.FC<IProductManagerProps> = (props) => {

    const productLogoRenderer = (params: ICellRendererParams) => {
        return (
            <span
                style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 5
                }}
            >
                {(params && params.value && params.value.length && params.value.length > 0) ? (
                    params.value.map(function (data) {
                        return (
                            <img key={data.attachmentId}
                                src={`${data.path && data.path}`}
                                style={{
                                    display: 'block',
                                    width: '50px',
                                    height: '50px',
                                    filter: 'brightness(1.1)',
                                }}
                            />
                        )
                    })
                ) : ''}
            </span>
        );
    }

    const measureTypeRenderer = (params: ICellRendererParams) => {
        return (
            <span >
                {params?.value && (
                    params.value === MeasureType.None ? '-' :
                        params.value === MeasureType.Kilogram ? 'кілограм' :
                            params.value === MeasureType.Liter ? 'літр' :
                                params.value === MeasureType.Piece && 'за одиницю'
                )}
            </span>
        );
    }

    const currencyTypeRenderer = (params: ICellRendererParams) => {
        return (params?.value && (
                    params.value === CurrencyType.None ? <span> - </span>  :
                        params.value === CurrencyType.UAH ? <span>&#8372;</span> :
                            params.value === CurrencyType.USD ? <span>&#36;</span>  :
                                params.value === CurrencyType.EUR && <span>&#8364;</span> 
                ));
    }

    const [state, setState] = useState<IProductManagerState>({
        columnDefs: [
            { headerName: '', field: 'checkboxBtn', checkboxSelection: true, headerCheckboxSelection: true, pinned: 'left', width: 50 },
            { headerName: '', field: 'productId', hide: true },
            { headerName: 'Назва', field: 'name', width: 150, sortable: false },
            { headerName: 'Країна виробника', field: 'producerCountry', width: 150, sortable: false },
            { headerName: 'Артикул', field: 'article', width: 150, sortable: false },
            { headerName: 'Скорочений опис', field: 'tittleDescription', width: 150, sortable: false },
            { headerName: 'Опис', field: 'description', width: 150, sortable: false },
            { headerName: 'Вартість', field: 'price', width: 150, sortable: false },
            { headerName: 'Валюта', field: 'currencyType', cellRenderer: currencyTypeRenderer, width: 100, sortable: false },
            { headerName: 'К-сть на гектар', field: 'quantityPerHectare', width: 150, sortable: false },
            { headerName: 'Тип виміру', field: 'measureType', cellRenderer: measureTypeRenderer, width: 150, sortable: false },
            { headerName: 'Видимість', field: 'isActive', width: 100, sortable: false },
            { headerName: 'Зображення', field: 'attachments', width: 250, cellRenderer: productLogoRenderer, autoHeight: true, sortable: false }
        ],
        popupActive: false,
        currentProductId: 0,
        loading: false,
        selectedrows: [],
        selectedCompany: 0,
        selectedProductType: 0,
        companies: [],
        productTypes: [],
        pageSize: 10
    });

    const [gridApi, setGridApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const paginationPageSizeSelector = useMemo(() => {
        return [10, 50, 100];
    }, []);

    const loadProductDataPagination = async (productTypeId: number = 0, currentPage: number = 0, pageSize: number = 0, isLoading: boolean = false) => {
        return await productManager.getAllProducts(productTypeId, currentPage, pageSize);
};

    const loadCompaniesData = () => {
        companyManager.getAllActiveCompaniesForSelector().then((data) => {
            let options: IOptionSelectField[] = [];
            if (data && data.length > 0) {
                data.forEach((x) => { options.push({ label: x.name, value: x.companyId }) });
                setState(prevstate =>
                ({
                    ...prevstate,
                    companies: options,
                    loading: false
                }));
            }
        });
    };

    const loadProductTypesData = (companyId: number) => {
        productTypeManager.getAllActiveProductTypesForSelector(companyId).then((data) => {
            let options: IOptionSelectField[] = [];
            data.forEach((x) => { options.push({ label: x.name, value: x.productTypeId }) });
            setState(prevstate =>
            ({
                ...prevstate,
                productTypes: options,
            }));
        });
    };

    const setActivePopup = (id?: number, open?: boolean) => {
        if (!(id == null) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    currentProductId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false
                }));
            hardReloadGrid();
        }
    }

    const grid_OnClickDelete = async () => {
        let products = state.selectedrows;
        if (products.length > 0) {
            let options: ReactConfirmAlertProps = {
                title: "Видалити продукт",
                message: "Видалити обрані продукти?",
                buttons: [
                  {
                    label: "Так",
                    onClick: () => {
                        productManager.deleteProduct(products).then((result) => {
                            if (!result.isSuccessfull) 
                                alert(result.message ?? "Не вдалось видалити товар!");
                            hardReloadGrid();
                            }
                        );
                    }
                  },
                  {
                    label: "Ні",
                    onClick: () => { }
                  }
                ]
            }
            confirmAlert(options);
        }
    }

    const onRowSelected = (e) => {
        if (gridApi) {
            let products = (gridApi as GridApi).getSelectedRows();
            let productIds = Object.keys(products).map(function (key) {
                let obj = products[key];
                if(obj?.productId)
                    return obj.productId;
            });

            setState(prevstate =>
            ({
                ...prevstate,
                selectedrows: productIds
            })
            );
        }
    };
    
    const CompanyOnChange = (e) => {
        let id = e.target.value;
        if (id && id > 0) {
            setState(prevstate =>
            ({
                ...prevstate,
                selectedCompany: id
            }));
            loadProductTypesData(id);
        }
    }

    const ProductTypeOnChange = (e) => {
        let id = e.target.value;
        if (id && id > 0) {
            setState(prevstate =>
            ({
                ...prevstate,
                selectedProductType: id
            }));
        }
    }


    const hardReloadGrid = () => {
        //reload grid for new product type
        if (gridApi) {
            let api = gridApi as GridApi;
            api.refreshInfiniteCache()
        }
    }

    useEffect(() => {
        if (gridApi) {
            loadCompaniesData();
            let api = gridApi as GridApi;
            const dataSource = {
                getRows: (params: IGetRowsParams) => {
                    if (params?.endRow) {
                        let pageSize = state.pageSize;
                        const page = params.endRow / pageSize;
                        loadProductDataPagination(state.selectedProductType, page, pageSize)
                            .then(res => {
                                params.successCallback(res.products, res.count);
                            });
                    }
                }
            }
            api.setGridOption('datasource', dataSource);
        }
    }, [gridApi, state.selectedProductType]);

    if (state.loading)
        return (
            <ManagerMenu action={ManagerMenuActions.Product}>
                <Loading />
            </ManagerMenu>);
    else
        return (
            <ManagerMenu action={ManagerMenuActions.Product}>
                <GridMenu 
                    isMenuActive={true} 
                    isAddButtonActive={true} 
                    isDeleteButtonActive={true} 
                    onClickAdd={() => { setActivePopup(0, true); }}
                    onClickDelete={grid_OnClickDelete} 
                    isDeleteButtonDisabled={state.selectedrows.length === 0}>
                    <SelectField 
                        fieldName='company-selector' 
                        value={state.selectedCompany} 
                        onChange={CompanyOnChange} options={state.companies} 
                        fieldTittle='Компанія' />
                    <SelectField 
                        fieldName='productType-selector' 
                        value={state.selectedProductType} 
                        onChange={ProductTypeOnChange} 
                        options={state.productTypes} 
                        fieldTittle='Категорія' />
                </GridMenu>
                <Grid 
                    pagination={true} 
                    pageSize={state.pageSize}
                    cacheBlockSize={state.pageSize} 
                    height={500} 
                    rowSelection='multiple' 
                    onRowSelected={onRowSelected}
                    onRowClicked={(e) => { setActivePopup(e.data.productId, true); }} 
                    columnDefs={state.columnDefs} 
                    defaultColDef={this}
                    paginationPageSizeSelector={paginationPageSizeSelector} 
                    onGridReady={onGridReady}
                    rowModelType='infinite'
                    rowHeight={60}/>
                <ProductEditPopup 
                    companies={state.companies} 
                    selectedCompanyId={state.selectedCompany} 
                    productId={state.currentProductId} 
                    selectedProductTypeId={state.selectedProductType} 
                    isActive={state.popupActive} 
                    setActive={setActivePopup}
                    productTypes={state.productTypes}>
                </ProductEditPopup>
            </ManagerMenu>);
}