import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetNotificationResponse } from './Response/GetNotificationResponse.ts';
import { GetAllNotificationsResponse } from './Response/GetAllNotificationsResponse.ts';
import { CreateUpdateNotificationRequest } from './Request/CreateUpdateNotificationRequest.ts';
import { GetAllNotificationsRequest } from './Request/GetAllNotificationsRequest.ts';
import { CreateUpdateNotificationResponse } from './Response/CreateUpdateNotificationResponse.ts';
import { DeleteNotificationRequest } from './Request/DeleteNotificationRequest.ts';
import { DeleteNotificationResponse } from './Response/DeleteNotificationResponse.ts';

export class NotificationService {

    async getNotification(id: number) : Promise<GetNotificationResponse> {
        let response = await api
        .get<GetNotificationResponse>(`${ApplicationEndpoints.GetNotification}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetNotificationResponse();
    }

    async getAllNotifications(request: GetAllNotificationsRequest) : Promise<GetAllNotificationsResponse> {
      let response = await api
      .post<GetAllNotificationsResponse>(ApplicationEndpoints.GetNotifications,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllNotificationsResponse();
  }

  async updateCreateNotification(request: CreateUpdateNotificationRequest): Promise<CreateUpdateNotificationResponse> {
    let response = await api
      .post<CreateUpdateNotificationResponse>(ApplicationEndpoints.UpdateNotification,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateNotificationResponse();
  }

  async deleteNotification(request: DeleteNotificationRequest) : Promise<DeleteNotificationResponse> {
    let response = await api
    .post<DeleteNotificationResponse>(ApplicationEndpoints.DeleteNotification,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteNotificationResponse();
  }
}

const notificationService = new NotificationService();
export default notificationService;