import { UsedBonus } from "../../models/manager/UsedBonus";
import { CreateUsedBonusRequest } from "../../services/manager/used-bonus/Request/CreateUsedBonusRequest.ts";
import { CreateUsedBonusResponse } from "../../services/manager/used-bonus/Response/CreateUsedBonusResponse.ts";
import { GetUserUsedBonusesResponse } from "../../services/manager/used-bonus/Response/GetUserUsedBonusesResponse.ts";
import usedBonusService from "../../services/manager/used-bonus/usedBonus.service.tsx";

export class UsedBonusManager{

    async getUsedBonuses(userId: string, pageSize: number, currentPage: number): Promise<GetUserUsedBonusesResponse>{
        let response = await usedBonusService.getUsedBonuses(userId, pageSize, currentPage);
        return response;
    }


    async createUsedBonus(usedBonus: UsedBonus): Promise<CreateUsedBonusResponse>{
        let request: CreateUsedBonusRequest = {
            usedBonus
        }
        let response = await usedBonusService.createUsedBonus(request);
        return response;
    }
}

const usedBonusManager = new UsedBonusManager();
export default usedBonusManager;