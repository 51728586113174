import {Product} from '../../models/manager/Product.ts'
import { CreateUpdateProductRequest } from '../../services/manager/product/Request/CreateUpdateProductRequest.ts';
import { CreateUpdateProductResponse } from '../../services/manager/product/Response/CreateUpdateProductResponse.ts';
import { DeleteProductRequest } from '../../services/manager/product/Request/DeleteProductRequest.ts';
import { DeleteProductResponse } from '../../services/manager/product/Response/DeleteProductResponse.ts';
import productService from '../../services/manager/product/product.service.tsx';
import { GetAllProductsRequest } from '../../services/manager/product/Request/GetAllProductsRequest.ts';
import { GetAllProductsResponse } from '../../services/manager/product/Response/GetAllProductsResponse.ts';

export class ProductManager{

    async getProduct(id: number): Promise<Product>{
        let response = await productService.getProduct(id);
        return response.product;
    }

    async getAllProducts(productTypeId: number = 0, currentPage: number = 0, pageSize: number = 0, search: string = ''): Promise<GetAllProductsResponse>{
        let request: GetAllProductsRequest = {
            productTypeId: productTypeId,
            currentPage: currentPage,
            pageSize: pageSize,
            search: search
        }
        let response = await productService.getAllProducts(request);
        return response;
    }

    async updateCreateProduct(product: Product): Promise<CreateUpdateProductResponse>{
        let request: CreateUpdateProductRequest = {
            product: product
        }
        let response = await productService.updateCreateProduct(request);
        return response;
    }
    
    async deleteProduct(products: number[]): Promise<DeleteProductResponse>{
        let request: DeleteProductRequest = {
            products: products
        }
        let response = await productService.deleteProduct(request);
        return response;
    }
}

const productManager = new ProductManager();
export default productManager;