import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './CompanyManager.css';
import React, {Component} from "react";
import companyManager from '../../../managers/manager/company.manager.tsx';
import { Company } from '../../../models/manager/Company.ts';
import { CompanyEditPopup } from './components/popup/CompanyEditPopup.tsx';
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

interface ICompanyManagerProps{

}

interface ICompanyManagerState{
    columnDefs: ColDef[];
    rowData:Company[];
    popupActive: boolean;
    currentCompanyId: number;
    loading: boolean;
    selectedrows: number[];
}

export class CompanyManager extends Component<ICompanyManagerProps, ICompanyManagerState> {
    constructor(props: ICompanyManagerProps) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: '', field: 'checkboxBtn', checkboxSelection: true, headerCheckboxSelection: true, pinned: 'left',  width: 50  },
                { headerName: '', field: 'companyId', hide: true},
                { headerName: 'Назва', field: 'name', width: 250 },
                { headerName: 'Видимість', field: 'isActive', width: 200 },
                { headerName: 'Логотип', field: 'attachment.path', width: 250, cellRenderer: this.companyLogoRenderer, autoHeight: true }
            ],
            rowData: [],
            popupActive: false,
            currentCompanyId: 0,
            loading: true,
            selectedrows: []
        };
        this.setActivePopup = this.setActivePopup.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.grid_OnClickDelete = this.grid_OnClickDelete.bind(this);
    }

    async componentDidMount() {
        this.updateCompanyData();
    }

    componentDidUpdate(prevProps) {
    }

    async updateCompanyData() {
        let data = await companyManager.getAllCompanies();
        this.setState((state) => {
            return {
                rowData: data,
                selectedrows: [],
                loading: false
            }
        });
    }

    companyLogoRenderer(params: ICellRendererParams) {
        return (
            <span
                style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 5
                }}
            >
                {params.value && (
                    <img
                        src={`${params.value}`}
                        style={{
                            display: 'block',
                            width: '50px',
                            height: '50px',
                            filter: 'brightness(1.1)',
                        }}
                    />
                )}
            </span>
        );
    }

    setActivePopup(id?: number, open?: boolean) {
        if (!(id == null) && open) {
            this.setState((state) => {
                return {
                    popupActive: true,
                    currentCompanyId: id
                }
            });
        }
        else {
            this.setState((state) => {
                return { 
                    popupActive: false,
                    loading: true
                }
            });
            this.updateCompanyData();
        }
    }

    // async grid_OnClickDelete() {
    //     let companies = this.state.selectedrows;
    //     if (companies.length > 0) {
    //         this.setState((state) => {
    //             return {
    //                 loading: true
    //             }
    //         });

    //         let deleteCompanyResult = await companyManager.deleteCompany(companies);
    //         if (!deleteCompanyResult.isSuccessfull) alert(deleteCompanyResult.message);
    //         this.updateCompanyData();
    //     }
    // }

    async grid_OnClickDelete() {
        let companies = this.state.selectedrows;
        if (companies.length > 0) {
            let options: ReactConfirmAlertProps = {
                title: "Видалити Компанію",
                message: "Видалити також продукти пов'язані з компанією?",
                buttons: [
                  {
                    label: "Так",
                    onClick: () => {
                        setTimeout(() => this.confirmDelete(true, companies), 250);
                    }
                  },
                  {
                    label: "Ні",
                    onClick: () => {
                        setTimeout(() => this.confirmDelete(false, companies), 250);
                    }
                  }
                ]
            }
            confirmAlert(options);
        }
    }

    confirmDelete(isDeleteProducts: boolean, companies: number[]) {
        let opt: ReactConfirmAlertProps = {
            title: "Видалити категорію",
            message: isDeleteProducts ? 
                "Підтвердити видалення компанії разом з продуктами пов'язаними з нею?" :
                "Підтвердити видалення тільки компанії?",
            buttons: [
                {
                    label: "Так",
                    onClick: async () => {

                        let deleteCompanyResult = await companyManager.deleteCompany(companies, isDeleteProducts);
                        if (!deleteCompanyResult.isSuccessfull) alert(deleteCompanyResult.message);
                        this.updateCompanyData();
                    }
                },
                {
                    label: "Ні",
                    onClick: () => {}
                }
            ]
        }
        confirmAlert(opt);
    }


    onRowSelected(e){
        if(e && e.node && e.data && e.data.companyId > 0)
        {
            let count = this.state.selectedrows.length;
            if (e.node.selected) {
                this.setState((state) => {
                    return {
                        selectedrows: [...state.selectedrows, e.data.companyId]
                    }
                });
            }
            else
            {
                this.setState((state) => {
                    return {
                        selectedrows: state.selectedrows.filter(function(Id) { 
                            return Id !== e.data.companyId 
                        })
                    }
                });
            }

        }
    }

    render() {
        if (this.state.loading)
            return (                
                <ManagerMenu action={ManagerMenuActions.Company}>
                    <Loading />
                </ManagerMenu>);
        else
            return (
                <ManagerMenu action={ManagerMenuActions.Company}>
                    <GridMenu 
                        isMenuActive={true} 
                        isAddButtonActive={true} 
                        isDeleteButtonActive={true} 
                        onClickAdd={() => this.setActivePopup(0, true)} 
                        onClickDelete={this.grid_OnClickDelete} 
                        isDeleteButtonDisabled={this.state.selectedrows.length === 0}/>
                    <Grid 
                        pagination={true} 
                        pageSize={20} 
                        height={500} 
                        rowData={this.state.rowData} 
                        rowSelection='multiple' 
                        onRowSelected={this.onRowSelected}
                        onRowClicked={(e) => { this.setActivePopup(e.data.companyId, true); }} 
                        columnDefs={this.state.columnDefs} 
                        defaultColDef={this} />
                    <CompanyEditPopup 
                        companyId={this.state.currentCompanyId} 
                        isActive={this.state.popupActive} 
                        setActive={this.setActivePopup}/>
                </ManagerMenu>);
    }
}