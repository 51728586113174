import './AboutUsManager.css';
import React, {useState, useEffect} from "react";
import { AboutUs } from '../../../models/manager/AboutUs.ts';
import aboutUsManager from '../../../managers/manager/aboutUs.manager.tsx';
import { TextField } from '../../../components/textfield/TextField.tsx';
import { CheckBox } from '../../../components/checkbox/CheckBox.tsx';
import { Loading } from '../../../components/loading/Loading.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { useForm, FormProvider } from "react-hook-form";
import useDebounce from '../../../custom-hooks/useDebounce.jsx'


interface IAboutUsManagerProps{
}

interface IAboutUsManagerState{
  loading: boolean;
  updating: boolean;
  error: string;
  isAddressOfficeUrlEdit: boolean;
  isAddressStorageUrlEdit: boolean;
  addressOfficeUrlCriteriaError: string;
  addressStorageUrlCriteriaError: string;
}

interface IAboutUsForm{
    aboutUs: AboutUs;
}

export const AboutUsManager: React.FC<IAboutUsManagerProps> = (props) => {

  const [state, setState] = useState<IAboutUsManagerState>({
        error: '',
        updating: true,
        loading: true,
        isAddressOfficeUrlEdit: false,
        isAddressStorageUrlEdit: false,
        addressOfficeUrlCriteriaError: '',
        addressStorageUrlCriteriaError: ''
  });

  useEffect(() =>{
    //loadAboutUsData();
}, [state.updating])

const { debounce } = useDebounce();

const loadAboutUsData = () => {
    if (state.updating) {
      aboutUsManager.getAboutUs().then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            aboutUs: {...data},
            loading: false,
            updating: false
          }});
      }); 
    }
  };

  const loadAboutUs = async () => {
      let data = await aboutUsManager.getAboutUs();
      let defValues: IAboutUsForm = {
          aboutUs:
          {
              contactPhone: '',
              contactEmail: '',
              officeAddress: '',
              officeAddressUrl: '',
              officePhone: '',
              officeEmail: '',
              isActiveOfficeAddress: false,
              storageAddress: '',
              storageAddressUrl: '',
              storagePhone: '',
              storageEmail: '',
              isActiveStorageAddress: false
          }
      } 
      let result = 
      {
        ...defValues,
        aboutUs: {
            ...defValues.aboutUs,
            ...data
        }
      };
      setLoad(false);
      return result;
    }


  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const onUpdate = async (data: IAboutUsForm) => {
        setLoad(true);
        if (data && data.aboutUs) {
            await aboutUsManager.updateAboutUs(data.aboutUs).then(async (data) => {
                if (!data.isSuccessfull) alert(data.message);
                setLoad(false);
            });
        }
    };

    const methods = useForm({
        defaultValues: loadAboutUs}
    );

    const { formState: { errors }, watch, setValue} = methods;


    const handleAdrOfficeURL = debounce(e => addressOfficeUrlCriteriaOnChange(e), 500)

    const addressOfficeUrlCriteriaOnChange = (e) => 
    {
        let value = e.target.value;
        if(value && value.length < 200)
        {
            let param = encodeURIComponent(value);
            let gMap = `https://www.google.com/maps/search/?api=1&query=${param}`

            setValue('aboutUs.officeAddressUrl', gMap);

            setState(prevstate => {
                return {
                  ...prevstate,
                  addressOfficeUrlCriteriaError: ''
                }});
        }
        else
        {
            setState(prevstate => {
                return {
                  ...prevstate,
                  addressOfficeUrlCriteriaError: 'Поле повинно містити від 1 до 100 знаків!'
                }});
        }
    }

    const handleAdrStorageURL = debounce(e => addressStorageUrlCriteriaOnChange(e), 500)

    const addressStorageUrlCriteriaOnChange = (e) => 
    {
        let value = e.target.value;
        if(value && value.length < 200)
        {
            let param = encodeURIComponent(value);
            let gMap = `https://www.google.com/maps/search/?api=1&query=${param}`

            setValue('aboutUs.storageAddressUrl', gMap);

            setState(prevstate => {
                return {
                  ...prevstate,
                  addressStorageUrlCriteriaError: ''
                }});
        }
        else
        {
            setState(prevstate => {
                return {
                  ...prevstate,
                  addressStorageUrlCriteriaError: 'Поле повинно містити від 1 до 100 знаків!'
                }});
        }
    }
    
    if (state.loading)
        return (
            <ManagerMenu action={ManagerMenuActions.AboutUs}>
                <Loading />
            </ManagerMenu>);
    else
        return (
            <ManagerMenu action={ManagerMenuActions.AboutUs}>
                <div className='about-us-content-center'>
                    <div className='about-us-edit-container'>
                        <h3 className='about-us-tittle'>Сторінка про нас</h3>
                        <FormProvider {...methods}>
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.contactPhone",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }}
                                fieldTittle='Контактний номер телефону'
                                errorMessage={errors?.aboutUs?.contactPhone?.message} />
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.contactEmail",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Введіть правильну електронну пошту!'
                                            },
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Контактна електронна пошта'
                                errorMessage={errors?.aboutUs?.contactEmail?.message} />
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.officeAddress",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Адреса офісу'
                                errorMessage={errors?.aboutUs?.officeAddress?.message} />
                            <div className='address-link-edit-container'>
                                <div className='address-link-container'>
                                    <a
                                        className='address-link'
                                        href={watch('aboutUs.officeAddressUrl')}
                                        target='_blank'>
                                        Посилання на геолокацію офісу
                                    </a>
                                    <button
                                        className='address-link-button-edit'
                                        onClick={() => {
                                            setState(prevstate => {
                                                return {
                                                    ...prevstate,
                                                    isAddressOfficeUrlEdit: true
                                                }
                                            });
                                        }}>
                                        Редагувати
                                    </button>
                                </div>
                                {state.isAddressOfficeUrlEdit &&
                                    <div>
                                        <TextField
                                            inputProps={{
                                                onChange: handleAdrOfficeURL,
                                            }
                                            }
                                            fieldTittle='Адреса, координати...'
                                            errorMessage={state.addressOfficeUrlCriteriaError} />
                                    </div>
                                }
                                <span className='geo-field-tittle'>Геолокація офісу</span>
                            </div>
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.officePhone",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Номер телефону офісу'
                                errorMessage={errors?.aboutUs?.officePhone?.message} />
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.officeEmail",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Введіть правильну електронну пошту!'
                                            },
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Офісна електронна пошта'
                                errorMessage={errors?.aboutUs?.officeEmail?.message} />
                            <CheckBox
                                inputProps={{ 
                                    ...methods.register("aboutUs.isActiveOfficeAddress"),
                                    onChange: methods.register("aboutUs.isActiveStorageAddress").onChange
                                }}
                                fieldTittle='Видимість контактної інформації офісу' />
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.storageAddress",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Адреса складу'
                                errorMessage={errors?.aboutUs?.storageAddress?.message} />
                            <div className='address-link-edit-container'>
                                <div className='address-link-container'>
                                    <a
                                        className='address-link'
                                        href={watch('aboutUs.storageAddressUrl')}
                                        target='_blank'>
                                        Посилання на геолокацію складу
                                    </a>
                                    <button
                                        className='address-link-button-edit'
                                        onClick={() => {
                                            setState(prevstate => {
                                                return {
                                                    ...prevstate,
                                                    isAddressStorageUrlEdit: true
                                                }
                                            });
                                        }}>
                                        Редагувати
                                    </button>
                                </div>
                                {state.isAddressStorageUrlEdit &&
                                    <div>
                                        <TextField
                                            inputProps={{
                                                onChange: handleAdrStorageURL,
                                            }
                                            }
                                            fieldTittle='Адреса, координати...'
                                            errorMessage={state.addressStorageUrlCriteriaError} />
                                    </div>
                                }
                                <span className='geo-field-tittle'>Геолокація складу</span>
                            </div>    
                            <TextField
                                inputProps={{
                                    ...methods.register("aboutUs.storagePhone",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 100, message: 'Максимальна кількість знаків - 100!'
                                            }
                                        })
                                }
                                }
                                fieldTittle='Номер телефону складу'
                                errorMessage={errors?.aboutUs?.storagePhone?.message} />
                            <CheckBox
                                inputProps={{ 
                                    ...methods.register("aboutUs.isActiveStorageAddress"),
                                    onChange: methods.register("aboutUs.isActiveStorageAddress").onChange 
                                }}
                                fieldTittle='Видимість контактної інформації складу' />
                        </FormProvider>
                        <input type='button' className='about-us-button' value='Оновити' onClick={methods.handleSubmit(onUpdate)}></input>
                    </div>
                </div>
            </ManagerMenu>);
}