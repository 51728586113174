import './Grid.css';
import * as React from "react"
import { CellClickedEvent, CellValueChangedEvent, ColDef, RowClickedEvent, RowModelType } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';


interface IGridProps{
  onRowClicked?(event: RowClickedEvent): void;
  defaultColDef?: any;
  pagination?: boolean;
  pageSize?: number;
  height: number;
  columnDefs: ColDef[];
  rowData?:{}[];
  rowHeight?: number;
  paginationPageSizeSelector?: number[] | boolean;
  rowSelection?: 'single' | 'multiple';
  onRowSelected?: (e) => void;
  onGridReady?: (e) => void;
  onPaginationChanged?: (e) => void;
  rowModelType?: RowModelType; 
  cacheBlockSize?: number;
  onCellClicked?: (event: CellClickedEvent) => void
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
}

interface IGridState{
}

export class Grid extends React.Component<IGridProps, IGridState> {
  constructor(props: IGridProps) {
    super(props);
  }
  componentDidMount() {
  }

  render() {
    return (
      <div style={{ height: this.props.height }} className="ag-theme-quartz grid-container">
        <AgGridReact
          columnDefs={this.props.columnDefs}
          cacheBlockSize={this.props.cacheBlockSize}
          rowData={this.props.rowData}
          rowHeight={this.props.rowHeight}
          onRowClicked={this.props.onRowClicked}
          defaultColDef={this.props.defaultColDef}
          pagination={this.props.pagination}
          paginationPageSize={this.props.pageSize}
          rowSelection={this.props.rowSelection}
          onRowSelected={this.props.onRowSelected}
          onGridReady={this.props.onGridReady}
          onPaginationChanged={this.props.onPaginationChanged}
          rowModelType={this.props.rowModelType}
          paginationPageSizeSelector={this.props.paginationPageSizeSelector}
          onCellClicked={this.props.onCellClicked}
          onCellValueChanged={this.props.onCellValueChanged}
        />
      </div>);
  }
}