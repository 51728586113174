import './ConfirmEmail.css';
import React, {useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Loading } from '../../components/loading/Loading.tsx';
import { TextField } from '../../components/textfield/TextField.tsx';
import userManager from '../../managers/user.manager.tsx';
import { useLocation } from 'react-router-dom';


interface IConfirmEmailProps{
}

interface IConfirmEmailState{
    loading: boolean;
    updating: boolean;
    error: string;
    token: string;
    email: string;
}

export const ConfirmEmail: React.FC<IConfirmEmailProps> = (props) => {

  const [state, setState] = useState<IConfirmEmailState>({
        error: '',
        updating: true,
        loading: false,
        token: '',
        email: '',
  });

  const location = useLocation();

    useEffect(() => {
        if (state.updating) {
            const query = new URLSearchParams(location.search);
            const token = query.get('token');
            const email = query.get('email');
            if (token && token.length > 0 && email && email.length > 0) {
                setState(prevstate => {
                    return {
                        ...prevstate,
                        token: token,
                        email: email,
                        updating: false
                    }
                });
            }
        }
    }, [state.updating])

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const methods = useForm({
        defaultValues: {
            newPassword: '',
            Password: ''
        }
    });

    const { formState: { errors }} = methods;

    const onClick = async (e) => {
        if (state.token && state.email) {
            setLoad(true);
            await userManager.ConfirmEmail(state.token, state.email).then(async (data) => {
                if (!data?.isSuccessfull) 
                    alert(data.message);
                else
                {
                    alert("Email успішно підтверджено!");
                }
            });
        }
        else
            alert("Не вдалось підтвердити email, спробуйте пізніше.");
        setLoad(false);
    };

    return (
        state.loading ? <Loading /> :
            <div className='confirm-email-container'>
                <div className='confirm-email-logo' />
                <div className='confirm-email-center'>
                    <h3 className='confirm-email-tittle'>
                        Підтвердження реєстрації
                    </h3>
                    <h5>
                        Для завершення реєстрації, та отримання
                        <br/>
                        доступу до контенту підтвердіть реєстрацію!
                    </h5>
                    <h6 className='confirm-email-message'>
                        У разі якщо ви не подавали такий запит, не підтверджуйте!
                    </h6>
                    <div className='confirm-email-fields'>
                    </div>
                    <input type='button' className='confirm-email-button' value='Підтвердити' onClick={onClick}></input>
                </div>
            </div>
    );
}