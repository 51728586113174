import './BonusHistoryItem.css';
import React, {useEffect, useState} from "react";
import { UsedBonus } from '../../../../../models/manager/UsedBonus.ts';

interface IBonusHistoryItemProps{
    usedBonus: UsedBonus;
}

interface IBonusHistoryItemState{
}

export const BonusHistoryItem: React.FC<IBonusHistoryItemProps> = (props) => {

    const [state, setState] = useState<IBonusHistoryItemState>({
    });

    const getFormatedDate = (date: Date) => {
        let d = new Date(date);
        let localDate = new Date(d.getTime() - d.getTimezoneOffset()*60*1000);
        return `${localDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${(localDate.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${localDate.getFullYear()}, ${localDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${localDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
    }

    return (
        <div>
            {
                props.usedBonus && (
                    <div>
                        <div className='history-item'>
                            <div className='history-item-top'>
                                <span>{props.usedBonus.description}</span>
                            </div>
                            <div className='history-item-bottom'>
                                <span>Обсяг: {props.usedBonus.size}</span>
                                <span>Дата: {getFormatedDate(props.usedBonus.createDate)}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                )}
        </div>);
}