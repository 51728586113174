import './OrderStatusPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../../components/popup/Popup.tsx';
import { OrderHistoryContainer } from './order-history/OrderHistoryContainer.tsx';
import orderManager from '../../../../../../managers/manager/order.manager.tsx';
import { TextField } from '../../../../../../components/textfield/TextField.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderStatus } from '../../../../../../enums/OrderStatus.ts';
import { CheckBox } from '../../../../../../components/checkbox/CheckBox.tsx';

interface IOrderStatusPopupProps{
  isActive: boolean;
  onSuccess?: (newstatus: OrderStatus) => void;
  orderId: number;
  newOrderStatus: OrderStatus;
  closePopup: () => void;
}

interface IOrderStatusPopupState{
  loading: boolean;
  internalDescription: string;
  externalDescription: string;
  isSingleDescription: boolean;
  isNoDescription: boolean;
}

export const OrderStatusPopup: React.FC<IOrderStatusPopupProps> = (props) => {

  const [state, setState] = useState<IOrderStatusPopupState>({
      loading: true,
      internalDescription: '',
      externalDescription: '',
      isSingleDescription: true,
      isNoDescription: false
      });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      setState(prevState => {
        return {
          ...prevState,
          internalDescription: '',
          externalDescription: '',
          isSingleDescription: true,
          isNoDescription: false,    
          loading: false
        }});
        setValue('internalDescription', '');
        setValue('externalDescription', '');
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const onPopupUpdate = async () => {
    setLoad(true);
    let isSuccessfull = false;
    if (props.newOrderStatus != OrderStatus.None) {
      let inDesc: string | undefined;
      let exDesc: string | undefined;
      if(!state.isNoDescription){
        if(state.isSingleDescription)
          inDesc = exDesc = state.internalDescription;
        else 
        {
          inDesc = state.internalDescription;
          exDesc = state.externalDescription;
        }
      }


      let result = await orderManager.changeStatus(props.orderId, props.newOrderStatus, inDesc, exDesc);
        if (!result || !result.isSuccessfull) 
          alert(result.message ?? "Не вдалось змінити статус!");
        else
          isSuccessfull = true;
    }
    else
      alert('Не вдалось змінити статус замовлення!');

    if(isSuccessfull && props.onSuccess)
      props.onSuccess(props.newOrderStatus);

    //Close Popup
    props.closePopup();
  };

const ChangeTittle = (status: OrderStatus) => {
  switch(status){
    case OrderStatus.InProcess:{
      return 'Встановити статус замовлення - в процесі.';
    }
    case OrderStatus.Done:{
      return 'Завершити замовлення';
    }
    case OrderStatus.Canceled:{
      return 'Скасувати замовлення';
    }
    default:{
      return '-';
    }
  }
}


  return (
    <Popup 
      isActive={props.isActive} 
      loading={state.loading} 
      onClose={() => props.closePopup()} 
      onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle={ChangeTittle(props.newOrderStatus)} 
      updateButtonTittle='Змінити статус'>
      <FormProvider {...methods}>
      <CheckBox 
        inputProps={{ 
          onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, isNoDescription: value })) },
          checked: state.isNoDescription,
          name: 'isNoDescription',
        }}
        fieldTittle='Без опису' />
        {!state.isNoDescription &&
          <div className='order-status-description'>
            <TextField
              inputProps={{
                ...register('internalDescription',
                  {
                    onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, internalDescription: value })) },
                    required: 'Це поле обов`язкове!',
                    value: state.internalDescription,
                    maxLength: {
                      value: 200, message: 'Максимальна кількість знаків - 200!'
                    }
                  })
              }}
              errorMessage={errors.internalDescription?.message?.toString()}
              fieldTittle='Причина зміни' />
            <CheckBox
              inputProps={{
                onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, isSingleDescription: value })) },
                checked: state.isSingleDescription,
                name: 'isSingleDescription',
              }}
              fieldTittle='Однаковий внутрішній та клієнтський опис зміни статусу' />
            {!state.isSingleDescription &&
              <TextField
                inputProps={{
                  ...register('externalDescription',
                    {
                      onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, externalDescription: value })) },
                      required: 'Це поле обов`язкове!',
                      value: state.externalDescription,
                      maxLength: {
                        value: 200, message: 'Максимальна кількість знаків - 200!'
                      }
                    })
                }}
                errorMessage={errors.externalDescription?.message?.toString()}
                fieldTittle='Пояснення для користувача' />
            }
          </div>}
        <OrderHistoryContainer orderId={props.orderId} />
      </FormProvider>
    </Popup>
  )
}