import './ProductTypeBasicEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { ProductTypeBasic } from '../../../../../models/manager/ProductTypeBasic.ts';
import productTypeBasicManager from '../../../../../managers/manager/productTypeBasic.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { FormProvider, useForm } from 'react-hook-form';

interface IProductTypeBasicEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  productTypeBasicId: number;
}

interface IProductTypeBasicEditPopupState{
  productTypeBasic: ProductTypeBasic;
  loading: boolean;
}

export const ProductTypeBasicEditPopup: React.FC<IProductTypeBasicEditPopupProps> = (props) => {

  const [state, setState] = useState<IProductTypeBasicEditPopupState>({
      productTypeBasic: {
        productTypeBasicId: 0,
        name: '',
        isActive: false
      },
      loading: true
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      if (props?.productTypeBasicId > 0) {
        loadProductTypeBasicData();
      }
      else
      {
        setState(prevState  => {
          return {
          ...prevState,
          productTypeBasic: {
            productTypeBasicId: 0,
            name: '',
            isActive: false
          },
          loading: false
        }});
      }
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadProductTypeBasicData = () => {
    if (props.productTypeBasicId > 0) {
      productTypeBasicManager.getProductTypeBasic(props.productTypeBasicId).then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            productTypeBasic: {...data},
            loading: false
          }});
          setValue('name', data.name);
      }); 
    }
  };

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const onPopupUpdate = async () => {
    setLoad(true);
    let productTypeBasic = state.productTypeBasic;
    await productTypeBasicManager.updateCreateProductTypeBasic(productTypeBasic).then(async (data) => {
      if (!data.isSuccessfull) 
        alert(data.message ?? "Не вдалось оновити категорію!");
    });
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
}

    return (
      <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
        tittle='Редагувати категорію' updateButtonTittle={props.productTypeBasicId === 0 ? "Додати" : undefined}>
        <FormProvider {...methods}>
          <TextField
            inputProps={{
              ...register('name',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, productTypeBasic: { ...prevState.productTypeBasic, name: value } })) },
                required: 'Це поле обов`язкове!',
                value: state.productTypeBasic.name,
                maxLength: {
                  value: 100, message: 'Максимальна кількість знаків - 100!'
                }
              })
            }} 
            errorMessage={errors.name?.message?.toString()}
            fieldTittle='Назва категорії' />
          <CheckBox 
            inputProps={{
              onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, productTypeBasic: { ...prevState.productTypeBasic, isActive: value } })) }, 
              checked: state.productTypeBasic.isActive,
              name: 'producttypebasic.isActive'
            }}
            fieldTittle='Видимість' />
        </FormProvider>
      </Popup>
    )
}