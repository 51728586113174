import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './services/auth/AuthorizeRoute';
import { Layout } from './components/Layout.tsx';
import './App.css';
import { ErrorHandler } from './components/error/ErrorHandler';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ErrorHandler>
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, requireAuth, ...rest } = route;
              return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
            })}
          </Routes>
        </Layout>
      </ErrorHandler>
    );
  }
}
