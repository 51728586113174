import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { UserOrdersResponse } from './Response/UserOrdersResponse.ts';
import { UsersCountResponse } from './Response/UsersCountResponse.ts';
import { OrdersCountResponse } from './Response/OrdersCountResponse.ts';
import { UserOrderByPriceResponse } from './Response/UserOrderByPriceResponse.ts';

export class DashboardService {

    async getUserCount() : Promise<UsersCountResponse> {
        let response = await api
        .get<UsersCountResponse>(`${ApplicationEndpoints.UserCountDashboard}`)
          .catch(
            (error) => {
            });
      return response?.data || new UsersCountResponse();
    }

    async getOrderCount(from: string, to: string) : Promise<OrdersCountResponse> {
        let response = await api
        .get<OrdersCountResponse>(`${ApplicationEndpoints.OrderCountDashboard}?from=${from}&to=${to}`)
          .catch(
            (error) => {
            });
      return response?.data || new OrdersCountResponse();
    }

    async getTopUserOrders(count: number) : Promise<UserOrdersResponse> {
        let response = await api
        .get<UserOrdersResponse>(`${ApplicationEndpoints.TopUserOrderDashboard}?count=${count}`)
          .catch(
            (error) => {
            });
      return response?.data || new UserOrdersResponse();
    }

    async getTopUserOrdersByPrice(count: number) : Promise<UserOrderByPriceResponse> {
        let response = await api
        .get<UserOrderByPriceResponse>(`${ApplicationEndpoints.TopUserOrderByPriceDashboard}?count=${count}`)
          .catch(
            (error) => {
            });
      return response?.data || new UserOrderByPriceResponse();
    }
}
const dashboardService = new DashboardService();
export default dashboardService;