import { ApplicationEndpoints } from '../../ApplicationEndpoints.js';
import axios, { AxiosResponse } from "axios";
import { LoginRequest } from './Models/Request/LoginRequest.ts';
import { LogoutRequest } from './Models/Request/LogoutRequest.ts';
import { LoginResponse } from './Models/Response/LoginResponse.ts';
import { LogoutResponse } from './Models/Response/LogoutResponse.ts';
import { RefreshTokenRequest } from './Models/Request/RefreshTokenRequest.ts';
import { RefreshTokenResponse } from './Models/Response/RefreshTokenResponse.ts';

export class AuthorizeService {

  async login(loginRequest: LoginRequest) : Promise<LoginResponse> {
      let response = await axios
      .post<LoginResponse>(ApplicationEndpoints.Login,
        loginRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }).catch((error) =>{

        });
    return response?.data || new LoginResponse();
  }

  async logout(logoutRequest: LogoutRequest) : Promise<LogoutResponse> {
    let response = await axios
    .post<LogoutResponse>(ApplicationEndpoints.Logout,
      logoutRequest,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).catch((error) =>{

      });
  return response?.data || new LogoutResponse();
  }

  async refreshToken(refreshTokenRequest: RefreshTokenRequest) : Promise<AxiosResponse<RefreshTokenResponse, any>> {
    let response = await axios
    .post<RefreshTokenResponse>(ApplicationEndpoints.RefreshToken,
      refreshTokenRequest,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })      
  return response;
 }
}

const authService = new AuthorizeService();
export default authService;
