import './GridMenu.css';
import * as React from "react"
import { GridButton } from './button/GridButton.tsx';

interface IGridMenuProps{
  onClickAdd?: () => void;
  onClickDelete?: () => void;
  isMenuActive?: boolean;
  isAddButtonActive: boolean;
  isDeleteButtonActive: boolean;
  isDeleteButtonDisabled?: boolean;
}

interface IGridMenuState{

}

export const GridMenu: React.FC<React.PropsWithChildren<IGridMenuProps>> = (props) => {

  const addButton = () => {
    if(props.isAddButtonActive)
    return(      
    <div>
      <button className={'grid-button'} onClick={props.onClickAdd}>Додати</button>
    </div>);
  }

  const deleteButton = () => {
    if(props.isDeleteButtonActive)
    return(      
    <div>
      <button className={'grid-button-delete'} disabled={props.isDeleteButtonDisabled} onClick={props.onClickDelete}>Видалити</button>
    </div>);
  }

      return ( props.isMenuActive &&
        <div className='grid-buttons gap-1'>
          {addButton()}{deleteButton()}
          {props.children}
        </div>);
}