import {BonusFull} from '../../models/manager/BonusFull.ts'
import { CreateUpdateBonusRequest } from '../../services/manager/bonus/Request/CreateUpdateBonusRequest.ts';
import { CreateUpdateBonusResponse } from '../../services/manager/bonus/Response/CreateUpdateBonusResponse.ts';
import { DeleteBonusRequest } from '../../services/manager/bonus/Request/DeleteBonusRequest.ts';
import { DeleteBonusResponse } from '../../services/manager/bonus/Response/DeleteBonusResponse.ts';
import bonusservice from '../../services/manager/bonus/bonus.service.tsx';
import { GetAllBonusesRequest } from '../../services/manager/bonus/Request/GetAllBonusesRequest.ts';
import { BonusSelector } from '../../models/manager/BonusSelector.ts';
import { GetAllActiveBonusesSelectorRequest } from '../../services/manager/bonus/Request/GetAllActiveBonusesSelectorRequest.ts';
import { GetUserBonusCountResponse } from '../../services/manager/bonus/Response/GetUserBonusCountResponse.ts';

export class BonusManager{

    async getBonus(id: number): Promise<BonusFull>{
        let response = await bonusservice.getBonus(id);
        return response.bonus;
    }

    async getUserBonusCount(userId: string): Promise<GetUserBonusCountResponse>{
        let response = await bonusservice.getUserBonusCount(userId);
        return response;
    }

    async getAllBonuses(currentPage: number = 0, pagesize: number = 0): Promise<BonusFull[]>{
        let request: GetAllBonusesRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await bonusservice.getAllBonuses(request);
        return response.bonuses;
    }

    async getAllActiveBonusesForSelector(currentPage: number = 0, pagesize: number = 0): Promise<BonusSelector[]>{
        let request: GetAllActiveBonusesSelectorRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await bonusservice.getAllActiveBonusesForSelector(request);
        return response.bonuses;
    }

    async updateCreateBonus(bonus: BonusFull): Promise<CreateUpdateBonusResponse>{
        let request: CreateUpdateBonusRequest = {
            bonus: bonus
        }
        let response = await bonusservice.updateCreateBonus(request);
        return response;
    }
    
    async deleteBonus(bonuses: number[]): Promise<DeleteBonusResponse>{
        let request: DeleteBonusRequest = {
            bonuses: bonuses
        }
        let response = await bonusservice.deleteBonus(request);
        return response;
    }
}

const bonusManager = new BonusManager();
export default bonusManager;