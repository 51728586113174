import './ManagerButton.css';
import * as React from "react"
import { NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';

interface IManagerButtonProps{
  to: string;
  name: string;
  isActive: boolean;
}

interface IManagerButtonState{

}

export class ManagerButton extends React.Component<IManagerButtonProps, IManagerButtonState> {
  constructor(props: IManagerButtonProps) {
    super(props);
  }
  componentDidMount() {
  }

  render() {
    return (
      <div>
        <React.Fragment>
          <NavItem>
            <NavLink tag={Link} className={'manager-button' + (this.props.isActive ? ' active': '')} to={this.props.to}>{this.props.name}</NavLink>
          </NavItem>
        </React.Fragment>
      </div>);
  }
}