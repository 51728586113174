import axios from "axios";
import { ApplicationEndpoints } from '../../ApplicationEndpoints.js';
import authManager from "../../managers/auth.manager.tsx";


const instance = axios.create({
  //baseURL: "http://localhost:8080/api",
  headers: {
    "Content-Type": "application/json",
    Accept: 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = authManager.getAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (!originalConfig.url.includes(ApplicationEndpoints.Login) && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
            let request = await authManager.RefreshToken();
            if(!request)
                return Promise.reject(err);

            return instance(originalConfig);
        } catch (_error) {
            return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;