import {Company} from '../../models/manager/Company.ts'
import { UpdateCompanyRequest } from '../../services/manager/company/Request/UpdateCompanyRequest.ts';
import { UpdateCompanyResponse } from '../../services/manager/company/Response/UpdateCompanyResponse.ts';
import { DeleteCompanyRequest } from '../../services/manager/company/Request/DeleteCompanyRequest.ts';
import { DeleteCompanyResponse } from '../../services/manager/company/Response/DeleteCompanyResponse.ts';
import companyService from '../../services/manager/company/company.service.tsx';
import { CompanySelector } from '../../models/manager/CompanySelector.ts';
import { GetAllActiveCompaniesSelectorRequest } from '../../services/manager/company/Request/GetAllActiveCompaniesSelectorRequest.ts';

export class CompanyManager{

    async getCompany(id: number): Promise<Company>{
        let response = await companyService.getCompany(id);
        return response.company;
    }

    async getAllCompanies(): Promise<Company[]>{
        let response = await companyService.getAllCompanies();
        return response.companies;
    }

    async getAllActiveCompaniesForSelector(currentPage: number = 0, pagesize: number = 0): Promise<CompanySelector[]>{
        let request: GetAllActiveCompaniesSelectorRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await companyService.getAllActiveCompaniesForSelector(request);
        return response.companies;
    }

    async updateCreateCompany(company: Company): Promise<UpdateCompanyResponse>{
        let request: UpdateCompanyRequest = {
            company: company
        }
        let response = await companyService.updateCreateCompany(request);
        return response;
    }
    
    async deleteCompany(companies: number[], isDeleteProducts: boolean): Promise<DeleteCompanyResponse>{
        let request: DeleteCompanyRequest = {
            companies,
            isDeleteProducts
        }
        let response = await companyService.deleteCompany(request);
        return response;
    }
}

const companyManager = new CompanyManager();
export default companyManager;