import {ProductTypeBasic} from '../../models/manager/ProductTypeBasic.ts'
import { CreateUpdateProductTypeBasicRequest } from '../../services/manager/product-type-basic/Request/CreateUpdateProductTypeBasicRequest.ts';
import { CreateUpdateProductTypeBasicResponse } from '../../services/manager/product-type-basic/Response/CreateUpdateProductTypeBasicResponse.ts';
import { DeleteProductTypeBasicRequest } from '../../services/manager/product-type-basic/Request/DeleteProductTypeBasicRequest.ts';
import { DeleteProductTypeBasicResponse } from '../../services/manager/product-type-basic/Response/DeleteProductTypeBasicResponse.ts';
import { GetAllProductTypeBasicsRequest } from '../../services/manager/product-type-basic/Request/GetAllProductTypeBasicsRequest.ts';
import productTypeBasicService from '../../services/manager/product-type-basic/productTypeBasic.service.tsx';
import { GetAllProductTypeBasicsSelectedByCompanyIdRequest } from '../../services/manager/product-type-basic/Request/GetAllProductTypeBasicsSelectedByCompanyIdRequest.ts';

export class ProductTypeBasicManager{

    async getProductTypeBasic(id: number): Promise<ProductTypeBasic>{
        let response = await productTypeBasicService.getProductTypeBasic(id);
        return response.productTypeBasic;
    }

    async getAllProductTypeBasics(currentPage: number = 0, pagesize: number = 0): Promise<ProductTypeBasic[]>{
        let request: GetAllProductTypeBasicsRequest = {
            currentPage: currentPage,
            pageSize: pagesize
        }
        let response = await productTypeBasicService.getAllProductTypeBasics(request);
        return response.productTypeBasics;
    }

    async getAllByCompanyIdSelected(companyId: number, currentPage: number = 0, pagesize: number = 0): Promise<ProductTypeBasic[]>{
        let request: GetAllProductTypeBasicsSelectedByCompanyIdRequest = {
            currentPage: currentPage,
            pageSize: pagesize,
            companyId: companyId
        }
        let response = await productTypeBasicService.getAllByCompanyIdSelected(request);
        return response.productTypeBasics;
    }

    async updateCreateProductTypeBasic(productTypeBasic: ProductTypeBasic): Promise<CreateUpdateProductTypeBasicResponse>{
        let request: CreateUpdateProductTypeBasicRequest = {
            productTypeBasic: productTypeBasic
        }
        let response = await productTypeBasicService.updateCreateProductTypeBasic(request);
        return response;
    }
    
    async deleteProductTypeBasic(productTypeBasics: number[], isDeleteProducts: boolean): Promise<DeleteProductTypeBasicResponse>{
        let request: DeleteProductTypeBasicRequest = {
            productTypeBasics,
            isDeleteProducts
        }
        let response = await productTypeBasicService.deleteProductTypeBasic(request);
        return response;
    }
}

const productTypeBasicManager = new ProductTypeBasicManager();
export default productTypeBasicManager;