import './OrderHistoryContainer.css';
import React, {useEffect, useState} from "react";
import orderManager from '../../../../../../../managers/manager/order.manager.tsx';
import { useInView } from 'react-intersection-observer';
import { OrderHistory } from '../../../../../../../models/manager/OrderHistory.ts';
import { OrderHistoryItem } from '../order-history-item/OrderHistoryItem.tsx';
import { Loading } from '../../../../../../../components/loading/Loading.tsx';

interface IOrderHistoryContainerProps{
    orderId: number;
}

interface IOrderHistoryContainerState{
    loading: boolean;
    pageSize: number;
    currentPage: number;
    orderHistories: OrderHistory[];
    count: number; 
    currentCount: number; 
}

export const OrderHistoryContainer: React.FC<IOrderHistoryContainerProps> = (props) => {

    const [state, setState] = useState<IOrderHistoryContainerState>({
        loading: true,
        pageSize: 10,
        currentPage: 0,
        orderHistories: [],
        count: 1,
        currentCount: 0,
    });
    const { ref, inView } = useInView({ threshold: 0.4, initialInView: true, trackVisibility: true, delay: 150 });

    useEffect(() => {
        if (inView && state.currentCount < state.count) {
            loadHistory();
        }
    }, [inView])

    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                loading: true,
            };
        });
        loadHistory(true);
    }, [props.orderId])

    const loadHistory = (isReload?: boolean) => {
        if (isReload) {
            orderManager.getOrderHistory(props.orderId, state.pageSize, 1).then((response) => {
                if (response?.orderHistories) {
                    let count = response.orderHistories.length;
                    setState(prevState => {
                        return {
                            ...prevState,
                            orderHistories: response.orderHistories,
                            currentPage: 1,
                            loading: false,
                            count: response.count,
                            currentCount: count
                        };
                    });
                }
                else
                    alert('Не вдалось завантажити історію списання бонусів.');
            });
        }
        else {
            orderManager.getOrderHistory(props.orderId, state.pageSize, state.currentPage + 1).then((response) => {
                if (response?.orderHistories) {
                    let count = response.orderHistories.length + state.orderHistories.length;

                    let newOrderHistories = response.orderHistories.filter(order => !state.orderHistories.some(existingOrder => existingOrder.orderHistoryId === order.orderHistoryId));

                    setState(prevState => {
                        return {
                            ...prevState,
                            orderHistories: [
                                ...prevState.orderHistories,
                                ...newOrderHistories
                            ],
                            currentPage: prevState.currentPage + 1,
                            loading: false,
                            count: response.count,
                            currentCount: count
                        };
                    });
                }
                else
                    alert('Не вдалось завантажити історію списання бонусів.');
            });
        }
    }

    return (
        <div className='order-history-container'>
            {
                !state.loading ?
                    state.orderHistories &&
                        state.orderHistories.length > 0 ?
                        <div>
                            {state.orderHistories.map((orderHistory) =>
                            (
                                <OrderHistoryItem key={`orderHistory_${orderHistory.orderHistoryId}`} orderHistory={orderHistory} />
                            ))}
                            <div style={{ height: 5 }} ref={ref}></div>
                        </div>
                        :
                        <div className='order-history-no-order-history'>
                            <span>Немає історії замовлення...</span>
                        </div>
                    :
                    <Loading />
            }
        </div>);
}