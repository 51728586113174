import './SelectField.css';
import React, {Component} from "react"

export interface IOptionSelectField{
  label: string;
  value: string | number;
}

interface ISelectFieldProps{
  fieldName: string;
  fieldTittle?: string;
  value?: string | number | undefined;
  defaultValue?: string | number | undefined;
  options: IOptionSelectField[];
  valueAsNumber?: boolean;
  onChange?: (e) => void;
  defaultTextOfFirstValue?: string;
}

interface ISelectFieldState{

}

export const SelectField: React.FC<ISelectFieldProps> = (props) => {

    return (
      <div className='select-field-grid-container'>
      <label className='select-field-grid'>
          <select className='select-field-grid-input' name={props.fieldName} value={props.value} defaultValue={props.defaultValue} onChange={props.onChange}>
            <option value={typeof(props.defaultValue) === 'number' ? 0 : ''} >{props.defaultTextOfFirstValue ? props.defaultTextOfFirstValue :'Обрати...'}</option>
            {props.options.map(function (data) {
              return (
                <option key={data.value} value={data.value}>{data.label}</option>
              )
            })}
          </select>
        <span className='select-field-grid-label'>{props.fieldTittle}</span>
      </label>
      </div>
      );
}