import { OrderStatus } from '../../../../../../../enums/OrderStatus.ts';
import { OrderHistory } from '../../../../../../../models/manager/OrderHistory.ts';
import './OrderHistoryItem.css';
import React from "react";

interface IOrderHistoryItemProps{
    orderHistory: OrderHistory;
}

interface IOrderHistoryItemState{
}

export const OrderHistoryItem: React.FC<IOrderHistoryItemProps> = (props) => {

    const getFormatedDate = (date: Date) => {
        let d = new Date(date);
        let localDate = new Date(d.getTime() - d.getTimezoneOffset()*60*1000);
        return `${localDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${(localDate.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${localDate.getFullYear()}, ${localDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${localDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
    }

    const getStatusTittle = (status: OrderStatus) => {
        switch(status){
            case OrderStatus.InProcess:
                return <span style={{color: 'orange'}}>В процесі</span>
            case OrderStatus.Done:
                return <span style={{color: '#22bd0d'}}>Виконано</span>
            case OrderStatus.Canceled:
                return <span style={{color: 'red'}}>Скасовано</span>
            default:
                return <span> - </span>
        }
    }

    return (
        <div>
            {
                props.orderHistory && (
                    <div>
                        <div className='history-items'>
                            {props.orderHistory.externalDescription &&
                                <div className='order-history-item'>
                                    <span className='history-item-client'>Користувач:&nbsp;</span>
                                    <span className='text-black'>{props.orderHistory.externalDescription}</span>
                                </div>
                            }
                            {props.orderHistory.internalDescription &&
                                <div className='order-history-item'>
                                    <span className='history-item-internal'>Внутрішня причина:&nbsp;</span>
                                    <span className='text-black'>{props.orderHistory.internalDescription}</span>
                                </div>
                            }
                            <div className='order-history-item'>
                                <span>status: {getStatusTittle(props.orderHistory.orderStatus)}</span>
                                <span>Дата: {getFormatedDate(props.orderHistory.createDate)}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                )}
        </div>);
}