import { Home } from "./components/Home";
import { Login } from "./pages/user/login/Login.tsx";
import { LoginActions } from "./pages/user/login/LoginActions";
import { ApplicationPaths } from "./ApplicationPaths";
import { Profile } from "./pages/user/profile/Profile.tsx";
import { CompanyManager } from "./pages/manager/company/CompanyManager.tsx";
import { Dashboard } from "./pages/manager/dashboard/Dashboard.tsx";
import { ProductManager } from "./pages/manager/product/ProductManager.tsx";
import { ProductTypeManager } from "./pages/manager/product-type/ProductTypeManager.tsx";
import { NotificationManager } from "./pages/manager/notification/NotificationManager.tsx";
import { BonusManager } from "./pages/manager/bonus/BonusManager.tsx";
import { SupportManager } from "./pages/manager/support/SupportManager.tsx";
import { AboutUsManager } from "./pages/manager/about-us/AboutUsManager.tsx";
import { WelcomeManager } from "./pages/manager/welcome/WelcomeManager.tsx";
import { UserManager } from "./pages/manager/users/UserManager.tsx";
import { OrderManager } from "./pages/manager/orders/OrderManager.tsx";
import { OneCManager } from "./pages/manager/1c-integration/OneCManager.tsx";
import { ForgottenPassword } from "./pages/forgotten-password/ForgottenPassword.tsx";
import { ChangePassword } from "./pages/change-password/ChangePassword.tsx";
import { ChangeEmailAccept } from "./pages/change-email-accept/ChangeEmailAccept.tsx";
import { ConfirmEmail } from "./pages/confirm-email/ConfirmEmail.tsx";

const AppRoutes = [
  // {
  //   index: true,
  //   element: <Home />
  // },
  {
    index: true,
    requireAuth: true,
    element: <Dashboard/>
  },
  {
    path: ApplicationPaths.Login,
    element: <Login action={LoginActions.Login}></Login>
  },
  {
    path: ApplicationPaths.Logout,
    element: <Login action={LoginActions.Logout}></Login>
  },
  {
    path: ApplicationPaths.Profile,
    requireAuth: true,
    element: <Profile></Profile>
  },
  {
    path: ApplicationPaths.Dashboard,
    requireAuth: true,
    element: <Dashboard/>
  },
  {
    path: ApplicationPaths.Company,
    requireAuth: true,
    element: <CompanyManager/>
  },
  {
    path: ApplicationPaths.Product,
    requireAuth: true,
    element: <ProductManager/>
  },
  {
    path: ApplicationPaths.ProductType,
    requireAuth: true,
    element: <ProductTypeManager/>
  },
  {
    path: ApplicationPaths.Notification,
    requireAuth: true,
    element: <NotificationManager/>
  },
  {
    path: ApplicationPaths.Bonus,
    requireAuth: true,
    element: <BonusManager/>
  },
  {
    path: ApplicationPaths.Support,
    requireAuth: true,
    element: <SupportManager/>
  },
  {
    path: ApplicationPaths.AboutUs,
    requireAuth: true,
    element: <AboutUsManager/>
  },
  {
    path: ApplicationPaths.Welcome,
    requireAuth: true,
    element: <WelcomeManager/>
  },
  {
    path: ApplicationPaths.User,
    requireAuth: true,
    element: <UserManager/>
  },
  {
    path: ApplicationPaths.Order,
    requireAuth: true,
    element: <OrderManager/>
  },
  {
    path: ApplicationPaths.OneC,
    requireAuth: true,
    element: <OneCManager/>
  },
  {
    path: ApplicationPaths.ForgottenPassword,
    element: <ForgottenPassword/>
  },
  {
    path: ApplicationPaths.ChangePassword,
    element: <ChangePassword/>
  },
  {
    path: ApplicationPaths.ChangeEmail,
    element: <ChangeEmailAccept/>
  },
  {
    path: ApplicationPaths.ConfirmEmail,
    element: <ConfirmEmail/>
  }
];

export default AppRoutes;
