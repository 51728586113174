import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './BonusManager.css';
import React, {useEffect, useState} from "react";
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import bonusManager from '../../../managers/manager/bonus.manager.tsx';
import { BonusFull } from '../../../models/manager/BonusFull.ts';
import { CalculationType } from '../../../enums/CalculationType.ts';
import { BonusEditPopup } from './components/popup/BonusEditPopup.tsx';

interface IBonusManagerProps{

}

interface IBonusManagerState{
    columnDefs: ColDef[];
    rowData: BonusFull[];
    popupActive: boolean;
    currentBonusId: number;
    loading: boolean;
    selectedrows: number[];
    updating: boolean;
}

export const BonusManager: React.FC<IBonusManagerProps> = (props) => {

    const TypeRenderer = (params: ICellRendererParams) => {
        return (
            <span >
                {params?.value && (
                    params.value === CalculationType.Percent ? 'відсоток' :
                    params.value === CalculationType.Piece && 'за одиницю'
                )}
            </span>
        );
    }
    const [state, setState] = useState<IBonusManagerState>({
                columnDefs: [
                    { headerName: '', field: 'checkboxBtn', checkboxSelection: true, headerCheckboxSelection: true, pinned: 'left',  width: 50  },
                    { headerName: '', field: 'bonusId', hide: true},
                    { headerName: 'Назва', field: 'name', width: 200 },
                    { headerName: 'Розмір', field: 'size', width: 100 },
                    { headerName: 'Тип обрахунку', field: 'calculationType', cellRenderer: TypeRenderer, width: 150 },
                    { headerName: 'Видимість', field: 'isActive', width: 100 },
                ],
                rowData: [],
                popupActive: false,
                currentBonusId: 0,
                loading: true,
                selectedrows: [],
                updating: true
            });
            useEffect(() =>{
                updateBonusData();
            }, [state.updating])

    const updateBonusData = async () => {
        if (state.updating) {
            let data = await bonusManager.getAllBonuses();
            setState(prevstate =>
            ({
                ...prevstate,
                rowData: data,
                selectedrows: [],
                loading: false,
                updating: false
            })
            );
        }
    };

    const setActivePopup = (id?: number, open?: boolean) => {
        if (!(id == null) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    currentBonusId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false,
                    loading: true,
                    updating: true
                })
            );
        }
    }

    const grid_OnClickDelete = async () => {
        let bonuss = state.selectedrows;
        if (bonuss.length > 0) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    loading: true
                })
            );

            let deleteBonusResult = bonusManager.deleteBonus(bonuss).then((result) => {
                if (!result.isSuccessfull) 
                    alert(result.message ?? "Не вдалось видалити бонус!");
                setState(prevstate => 
                    ({
                        ...prevstate,
                        updating: true
                    }));
                }
            );
        }
    }

    const onRowSelected = (e) => {
        if(e && e.node && e.data && e.data.bonusId > 0)
        {
            if (e.node.selected) {
                setState(prevstate => 
                    ({
                        ...prevstate,
                        selectedrows: [...prevstate.selectedrows, e.data.bonusId]
                    })
                );
            }
            else
            {
                setState(prevstate => 
                    ({
                        ...prevstate,
                        selectedrows: prevstate.selectedrows.filter(function(Id) { 
                            return Id !== e.data.bonusId 
                        })
                    })
                );
            }
        }
    };

        if (state.loading)
            return (
                <ManagerMenu action={ManagerMenuActions.Bonus}>
                    <Loading />
                </ManagerMenu>);
        else
            return (
                <ManagerMenu action={ManagerMenuActions.Bonus}>
                    <GridMenu isMenuActive={true} isAddButtonActive={true} isDeleteButtonActive={true} 
                    onClickAdd={() => {setActivePopup(0, true);}} onClickDelete={grid_OnClickDelete} isDeleteButtonDisabled={state.selectedrows.length === 0}/>
                    <Grid pagination={true} pageSize={20} height={500} rowData={state.rowData} rowSelection='multiple' onRowSelected={onRowSelected}
                        onRowClicked={(e) => { setActivePopup(e.data.bonusId, true); }} columnDefs={state.columnDefs} defaultColDef={this} />
                    <BonusEditPopup bonusId={state.currentBonusId} isActive={state.popupActive} setActive={setActivePopup}></BonusEditPopup>                
                </ManagerMenu>);
}