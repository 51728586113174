import './BonusEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { BonusFull } from '../../../../../models/manager/BonusFull.ts';
import bonusManager from '../../../../../managers/manager/bonus.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { SelectField, IOptionSelectField } from '../../../../../components/selectfield/SelectField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { BonusType } from '../../../../../enums/BonusType.ts';
import { CalculationType } from '../../../../../enums/CalculationType.ts';
import { FormProvider, ValidationRule, useForm } from 'react-hook-form';

interface IBonusEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  bonusId: number;
}

interface IBonusEditPopupState{
  bonus: BonusFull;
  loading: boolean;
}

export const BonusEditPopup: React.FC<IBonusEditPopupProps> = (props) => {

  const [state, setState] = useState<IBonusEditPopupState>({
      bonus: {
        bonusId: 0,
        name: '',
        isActive: false,
        bonusType: BonusType.None,
        calculationType: CalculationType.None,
        size: 0
      },
      loading: true
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      if (props?.bonusId > 0) {
        loadBonusData();
      }
      else
      {
        setState(prevState  => {
          return {
          ...prevState,
          bonus: {
            bonusId: 0,
            name: '',
            isActive: false,
            bonusType: BonusType.None,
            calculationType: CalculationType.None,
            size: 0
          },
          loading: false
        }});
      }
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadBonusData = () => {
    if (props.bonusId > 0) {
      bonusManager.getBonus(props.bonusId).then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            bonus: {...data},
            loading: false
          }});
          setValue('name', data.name);
          setValue('size', data.size);
      }); 
    }
  };

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const onPopupUpdate = async () => {
    setLoad(true);
    let bonus = state.bonus;
    bonus.bonusType = BonusType.Product; //default Type for now
    
    await bonusManager.updateCreateBonus(bonus).then(async (data) => {
      if (!data.isSuccessfull) 
        alert(data.message ?? "Не вдалось оновити бонус!");
    });
    //Close Popup
    closePopup();
  };

const closePopup = () => {
  props.setActive();
}

const CalculationTypeOptions: IOptionSelectField[] =
  [
    { label: 'Відсоток', value: CalculationType.Percent.valueOf() },
    { label: 'За одиницю', value: CalculationType.Piece.valueOf() }
  ];

  const MaxSize = () => {
    if(state.bonus?.calculationType && state.bonus?.calculationType === CalculationType.Percent)
      return {
        value: 100, message: 'Максимальне число - 100(%)!'
      };
    else 
      return {
        value: 99999, message: 'Максимальне число - 99999!'
      };
  }

  return (
    <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
      tittle='Редагувати бонус ' updateButtonTittle={props.bonusId === 0 ? "Додати" : undefined}>
      <FormProvider {...methods}>
      <TextField
          inputProps={{
            ...register('name',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, bonus: { ...prevState.bonus, name: value } })) }, 
                value: state.bonus.name,                
                required: 'Це поле обов`язкове!',
                maxLength: {
                  value: 100, message: 'Максимальна кількість знаків - 100!'
                }
              })
          }}
          errorMessage={errors.name?.message?.toString()}
          fieldTittle='Назва' />
      <TextField 
        inputProps={{ 
          ...register('size',
          {
            onChange: (e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, bonus: { ...prevState.bonus, size: value } })) },
            required: 'Це поле обов`язкове!',
            valueAsNumber: true,
            value: state.bonus.size,
            validate: (value) => {if(Number.isNaN(value)) return 'Введіть число у форматі 9.99!'}, 
            max: MaxSize()
          }) 
        }}
        errorMessage={errors.size?.message?.toString()}
        fieldTittle='Обсяг' />
        <CheckBox 
          inputProps={{
            onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, bonus: { ...prevState.bonus, isActive: value } })) },
            checked: state.bonus.isActive,
            name: 'bonus.isActive'
          }} 
          fieldTittle='Видимість' />
        <SelectField 
          onChange={(e) => { let value = Number(e.target.value); setState(prevState => ({ ...prevState, bonus: { ...prevState.bonus, calculationType: value } })) }} 
          valueAsNumber={true} options={CalculationTypeOptions} 
          value={state.bonus.calculationType} 
          fieldName='bonus.calculationType' 
          fieldTittle='Тип виміру' 
          isRequired={true} />
      </ FormProvider>
    </Popup>
  )
}