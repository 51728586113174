import React, { PropsWithChildren } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';
import { ApplicationPaths } from '../ApplicationPaths';

interface ILayoutProps{

}

export const Layout: React.FC<PropsWithChildren<ILayoutProps>> = (props) => {
  const excludedRoutes = [
    ApplicationPaths.ChangeEmail.toLowerCase(), 
    ApplicationPaths.ChangePassword.toLowerCase(), 
    ApplicationPaths.ConfirmEmail.toLowerCase(), 
    ApplicationPaths.ForgottenPassword.toLowerCase()
  ];
  const location = useLocation();

    return (
      <div className='main'>
        {!excludedRoutes.includes(location.pathname.toLowerCase()) && <NavMenu />}
        <div>
          {props.children}
        </div>
      </div>
    );
}
