import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetCompanyResponse } from './Response/GetCompanyResponse.ts';
import { GetCompaniesResponse } from './Response/GetCompaniesResponse.ts';
import { UpdateCompanyRequest } from './Request/UpdateCompanyRequest.ts';
import { UpdateCompanyResponse } from './Response/UpdateCompanyResponse.ts';
import { DeleteCompanyRequest } from './Request/DeleteCompanyRequest.ts';
import { DeleteCompanyResponse } from './Response/DeleteCompanyResponse.ts';
import { GetAllActiveCompaniesSelectorRequest } from './Request/GetAllActiveCompaniesSelectorRequest.ts';
import { GetAllActiveCompaniesSelectorResponse } from './Response/GetAllActiveCompaniesSelectorResponse.ts';

export class CompanyService {

    async getCompany(id: number) : Promise<GetCompanyResponse> {
        let response = await api
        .get<GetCompanyResponse>(`${ApplicationEndpoints.GetCompany}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetCompanyResponse();
    }

    async getAllCompanies() : Promise<GetCompaniesResponse> {
      let response = await api
      .get<GetCompaniesResponse>(ApplicationEndpoints.GetCompanies)
        .catch(
          (error) => {
          });
    return response?.data || new GetCompaniesResponse();
  }

  async getAllActiveCompaniesForSelector(request: GetAllActiveCompaniesSelectorRequest): Promise<GetAllActiveCompaniesSelectorResponse> {
    let response = await api
      .post<GetAllActiveCompaniesSelectorResponse>(ApplicationEndpoints.GetCompaniesForSelector,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new GetAllActiveCompaniesSelectorResponse();
  }

  async updateCreateCompany(request: UpdateCompanyRequest): Promise<UpdateCompanyResponse> {
    let response = await api
      .post<UpdateCompanyResponse>(ApplicationEndpoints.UpdateCompany,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new UpdateCompanyResponse();
  }

  async deleteCompany(request: DeleteCompanyRequest) : Promise<DeleteCompanyResponse> {
    let response = await api
    .post<DeleteCompanyResponse>(ApplicationEndpoints.DeleteCompany,
      request)
      .catch(
        (error) => {
        });
    return response?.data || new DeleteCompanyResponse();
  }
}

const companyService = new CompanyService();
export default companyService;