import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { CreateUsedBonusRequest } from './Request/CreateUsedBonusRequest.ts';
import { CreateUsedBonusResponse } from './Response/CreateUsedBonusResponse.ts';
import { GetUserUsedBonusesResponse } from './Response/GetUserUsedBonusesResponse.ts';

export class UsedBonusService {

  async getUsedBonuses(userId: string, pageSize: number, currentPage: number): Promise<GetUserUsedBonusesResponse> {
    let response = await api
      .get<GetUserUsedBonusesResponse>(`${ApplicationEndpoints.GetUserUsedBonuses}?userId=${userId}&pageSize=${pageSize}&currentPage=${currentPage}`)
      .catch(
        (error) => {
        });
    return response?.data || new GetUserUsedBonusesResponse();
  }

  async createUsedBonus(request: CreateUsedBonusRequest): Promise<CreateUsedBonusResponse> {
    let response = await api
      .post<CreateUsedBonusResponse>(ApplicationEndpoints.CreateUsedBonus,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUsedBonusResponse();
  }
}
const usedBonusService = new UsedBonusService();
export default usedBonusService;