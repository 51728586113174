import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetProductTypeBasicResponse } from './Response/GetProductTypeBasicResponse.ts';
import { GetAllProductTypeBasicsResponse } from './Response/GetAllProductTypeBasicsResponse.ts';
import { CreateUpdateProductTypeBasicRequest } from './Request/CreateUpdateProductTypeBasicRequest.ts';
import { GetAllProductTypeBasicsRequest } from './Request/GetAllProductTypeBasicsRequest.ts';
import { CreateUpdateProductTypeBasicResponse } from './Response/CreateUpdateProductTypeBasicResponse.ts';
import { DeleteProductTypeBasicRequest } from './Request/DeleteProductTypeBasicRequest.ts';
import { DeleteProductTypeBasicResponse } from './Response/DeleteProductTypeBasicResponse.ts';
import { GetAllProductTypeBasicsSelectedByCompanyIdResponse } from './Response/GetAllProductTypeBasicsSelectedByCompanyIdResponse.ts'
import { GetAllProductTypeBasicsSelectedByCompanyIdRequest } from './Request/GetAllProductTypeBasicsSelectedByCompanyIdRequest.ts'

export class ProductTypeBasicService {

    async getProductTypeBasic(id: number) : Promise<GetProductTypeBasicResponse> {
        let response = await api
        .get<GetProductTypeBasicResponse>(`${ApplicationEndpoints.GetProductTypeBasic}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetProductTypeBasicResponse();
    }

    async getAllProductTypeBasics(request: GetAllProductTypeBasicsRequest) : Promise<GetAllProductTypeBasicsResponse> {
      let response = await api
      .post<GetAllProductTypeBasicsResponse>(ApplicationEndpoints.GetProductTypeBasics,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllProductTypeBasicsResponse();
  }

  async getAllByCompanyIdSelected(request: GetAllProductTypeBasicsSelectedByCompanyIdRequest) : Promise<GetAllProductTypeBasicsSelectedByCompanyIdResponse> {
    let response = await api
    .post<GetAllProductTypeBasicsResponse>(ApplicationEndpoints.GetProductTypeBasicsSelectedByCompanyId,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new GetAllProductTypeBasicsSelectedByCompanyIdResponse();
}

  async updateCreateProductTypeBasic(request: CreateUpdateProductTypeBasicRequest): Promise<CreateUpdateProductTypeBasicResponse> {
    let response = await api
      .post<CreateUpdateProductTypeBasicResponse>(ApplicationEndpoints.UpdateProductTypeBasic,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateProductTypeBasicResponse();
  }

  async deleteProductTypeBasic(request: DeleteProductTypeBasicRequest) : Promise<DeleteProductTypeBasicResponse> {
    let response = await api
    .post<DeleteProductTypeBasicResponse>(ApplicationEndpoints.DeleteProductTypeBasic,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteProductTypeBasicResponse();
  }
}

const productTypeBasicService = new ProductTypeBasicService();
export default productTypeBasicService;