import * as React from "react"
import authManager from '../../../managers/auth.manager.tsx';
import { ApplicationPaths } from '../../../ApplicationPaths.js';
import './Login.css';
import { LoginActions } from "./LoginActions.js";
import { ApplicationEndpoints } from "../../../ApplicationEndpoints.js";
import { useEffect, useState } from "react";

interface ILoginProps {
  returnUrl: string;
  action: string;
}

interface ILoginState {
  email: string;
  password: string;
  loginError: string;
  emailError: string;
  passwordError: string;
}


export const Login: React.FC<ILoginProps> = (props) => {

  const [state, setState] = useState<ILoginState>({
    email: '',
    password: '',
    loginError: '',
    emailError: '',
    passwordError: ''
});

const [showPassword, setShowPassword] = useState(false);

useEffect(() => {
    const action = props.action;
    switch (action) {
      case LoginActions.Login:
      break;
      case LoginActions.Logout:
        authManager.Logout();
        navigateToReturnUrl(`/${ApplicationPaths.Login}`);
      break;
    }
  }, [])

  const onEmailChange = (email: string) => {
    setState(prevState => {
      return {
        ...prevState,
        email: email      
      }});
  }

  const onPasswordChange = (password: string) => {
    setState(prevState => {
      return {
        ...prevState,
        password: password      
      }});
  }

  const  onLoginClick = async () => {
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(state.email)) {
      setState(prevState => {
        return {
          ...prevState,
          emailError: 'Введіть електронну пошту в форматі example@mail.com'   
        }});
      return;
    }
    else
    setState(prevState => {
      return {
        ...prevState,
        emailError: ''   
      }});

    if (state.password === '') {
      setState(prevState => {
        return {
          ...prevState,
          passwordError: 'Введіть пароль'  
        }});
      return;
    }
    else
    setState(prevState => {
      return {
        ...prevState,
        passwordError: ''  
      }});
      
    let result = await authManager.Login(state.email, state.password);
    if(result) 
    {
      let url = ApplicationPaths.Dashboard;
      if(props.returnUrl)
        url = `/${props.returnUrl}`;
      navigateToReturnUrl(url);
    }
    else
      setState(prevState => {
      return {
        ...prevState,
        loginError: 'Невірний пароль або електронна пошта'
      }});
  }

  const navigateToReturnUrl = (returnUrl) => {
    let url = `${window.location.origin + returnUrl}`;
    window.location.replace(url);
  }

    if(props.action === LoginActions.Login)
    {
      return (<div className={"LoginContainer px-1 p-sm-5"}>
        <div className={"card px-1 px-sm-5 py-5"}>
          <div className={"text-center"}>
            <svg width="120" height="120" viewBox="0 0 48 48" fill="#22bd0d" xmlns="http://www.w3.org/2000/svg">
              <path d="M40.1235 37.395C42.2777 34.8032 43.7759 31.7306 44.4915 28.4373C45.2071 25.144 45.1189 21.7267 44.2345 18.4747C43.3501 15.2226 41.6954 12.2314 39.4105 9.75404C37.1255 7.27672 34.2775 5.38616 31.1074 4.24231C27.9373 3.09845 24.5383 2.73495 21.1979 3.18255C17.8576 3.63015 14.6742 4.87568 11.9171 6.81379C9.15993 8.75189 6.91012 11.3255 5.35799 14.317C3.80586 17.3085 2.99706 20.6298 3.00001 24C3.00114 28.8993 4.72763 33.6417 7.87651 37.395L7.84651 37.4205C7.95151 37.5465 8.07151 37.6545 8.17951 37.779C8.31451 37.9335 8.46001 38.079 8.59951 38.229C9.01951 38.685 9.45151 39.123 9.90451 39.534C10.0425 39.66 10.185 39.777 10.3245 39.897C10.8045 40.311 11.298 40.704 11.8095 41.07C11.8755 41.115 11.9355 41.1735 12.0015 41.22V41.202C15.5147 43.6743 19.7057 45.0011 24.0015 45.0011C28.2974 45.0011 32.4884 43.6743 36.0015 41.202V41.22C36.0675 41.1735 36.126 41.115 36.1935 41.07C36.7035 40.7025 37.1985 40.311 37.6785 39.897C37.818 39.777 37.9605 39.6585 38.0985 39.534C38.5515 39.1215 38.9835 38.685 39.4035 38.229C39.543 38.079 39.687 37.9335 39.8235 37.779C39.93 37.6545 40.0515 37.5465 40.1565 37.419L40.1235 37.395ZM24 12C25.335 12 26.6401 12.3959 27.7501 13.1376C28.8601 13.8793 29.7253 14.9335 30.2362 16.1669C30.7471 17.4003 30.8808 18.7575 30.6203 20.0669C30.3599 21.3763 29.717 22.579 28.773 23.523C27.829 24.467 26.6262 25.1099 25.3169 25.3703C24.0075 25.6308 22.6503 25.4971 21.4169 24.9862C20.1835 24.4753 19.1293 23.6102 18.3876 22.5001C17.6459 21.3901 17.25 20.0851 17.25 18.75C17.25 16.9598 17.9612 15.2429 19.227 13.9771C20.4929 12.7112 22.2098 12 24 12ZM12.0105 37.395C12.0365 35.4255 12.8369 33.5454 14.2385 32.1614C15.6402 30.7775 17.5303 30.001 19.5 30H28.5C30.4697 30.001 32.3599 30.7775 33.7615 32.1614C35.1631 33.5454 35.9635 35.4255 35.9895 37.395C32.6998 40.3595 28.4284 42.0001 24 42.0001C19.5716 42.0001 15.3002 40.3595 12.0105 37.395Z"/>
            </svg>
          </div>
          <div className={"titleContainer text-center"}>
            <h1 className={"fs-2"}>Увійти в обліковий запис</h1>
          </div>
          <br />
          <div className={"inputContainer"}>
            <input
              value={state.email}
              placeholder="Введіть електронну пошту"
              onChange={e => onEmailChange(e.target.value)}
              className={"inputBox"} />
            <label className="errorLabel">{state.emailError}</label>
          </div>
          <br />
          <div className="inputContainer">
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                value={state.password}
                placeholder="Введіть пароль"
                onChange={e => onPasswordChange(e.target.value)}
                className={"inputBox"} />
              <button className='password-visibility'
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 20 20"><path fill="currentColor" d="M17.3 3.3c-.4-.4-1.1-.4-1.6 0l-2.4 2.4a9.6 9.6 0 0 0-3.3-.6c-3.8.1-7.2 2.1-9 5.4c.2.4.5.8.8 1.2c.8 1.1 1.8 2 2.9 2.7L3 16.1c-.4.4-.5 1.1 0 1.6c.4.4 1.1.5 1.6 0L17.3 4.9c.4-.5.4-1.2 0-1.6m-10.6 9l-1.3 1.3c-1.2-.7-2.3-1.7-3.1-2.9C3.5 9 5.1 7.8 7 7.2c-1.3 1.4-1.4 3.6-.3 5.1M10.1 9c-.5-.5-.4-1.3.1-1.8c.5-.4 1.2-.4 1.7 0zm8.2.5c-.5-.7-1.1-1.4-1.8-1.9l-1 1c.8.6 1.5 1.3 2.1 2.2C15.9 13.4 13 15 9.9 15h-.8l-1 1c.7-.1 1.3 0 1.9 0c3.3 0 6.4-1.6 8.3-4.3c.3-.4.5-.8.8-1.2c-.3-.3-.5-.7-.8-1M14 10l-4 4c2.2 0 4-1.8 4-4" /></svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 20 20"><path fill="currentColor" d="M18.3 9.5C15 4.9 8.5 3.8 3.9 7.2c-1.2.9-2.2 2.1-3 3.4c.2.4.5.8.8 1.2c3.3 4.6 9.6 5.6 14.2 2.4c.9-.7 1.7-1.4 2.4-2.4c.3-.4.5-.8.8-1.2c-.3-.4-.5-.8-.8-1.1m-8.2-2.3c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8s-1.3.5-1.8 0s-.5-1.3 0-1.8m-.1 7.7c-3.1 0-6-1.6-7.7-4.2C3.5 9 5.1 7.8 7 7.2c-.7.8-1 1.7-1 2.7c0 2.2 1.7 4.1 4 4.1c2.2 0 4.1-1.7 4.1-4v-.1c0-1-.4-2-1.1-2.7c1.9.6 3.5 1.8 4.7 3.5c-1.7 2.6-4.6 4.2-7.7 4.2" /></svg>
                }
              </button>
            </div>
            <label className="errorLabel">{state.passwordError}</label>
            <label className="errorCenterLabel">{state.loginError}</label>
          </div>
          <br />
          <div className={"buttonsContainer"}>
            <input
              className={"inputButton"}
              type="button"
              onClick={onLoginClick}
              value={"Авторизуватися"} />
            {/* <input
              className={"inputButton"}
              type="button"
              value={"Реєстрація"} /> */}
          </div>
        </div>
      </div>
      );
    }
    else if (props.action === LoginActions.Logout)
      return (
        <div>
          Processing logout
        </div>
      );
}
