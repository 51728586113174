import './Button.css';
import React, { useState } from "react"

interface IButtonProps{
    onClick: (isActive: boolean, userId: string) => Promise<boolean>;
    userId: string;
    isActive: boolean;
    setIsNodeChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Button: React.FC<IButtonProps> = (props) => {

    const onButtonClick = async (e) => {
        let result = await props.onClick(!props.isActive, props.userId);
        if (result) {
            props.setIsNodeChanged(true);
        }
    }

    return (
        <div className='activity-button'>
            <button className={props.isActive ? 'active': 'unactive'} onClick={onButtonClick}>{props.isActive ? 'активна': 'неактивна'}</button>
        </div>
    );
}