import { OrderStatus } from '../../enums/OrderStatus.ts';
import { Order } from '../../models/manager/Order.ts'
import { ChangeOrderStatusResponse } from '../../services/manager/order/Response/ChangeOrderStatusResponse.ts';
import { GetOrdersResponse } from '../../services/manager/order/Response/GetOrdersResponse.ts';
import { OrderHistoryResponse } from '../../services/manager/order/Response/OrderHistoryResponse.ts';
import orderService from '../../services/manager/order/order.service.tsx';

export class OrderManager{

    async getOrders(currentPage: number = 0, pagesize: number = 0, search: string = '', orderStatus: OrderStatus = OrderStatus.None): Promise<GetOrdersResponse>{
        let response = await orderService.getAllOrders({
             currentPage: currentPage, 
             pageSize: pagesize,
             search: search,
             orderStatus: orderStatus
            });
        return response;
    }


    async changeStatus(orderId: number, orderStatus: OrderStatus, internalDescription?: string, externalDescription?: string): Promise<ChangeOrderStatusResponse>{
        let response = await orderService.changeStatus({
            orderId: orderId,
            orderStatus: orderStatus,
            internalDescription: internalDescription,
            externalDescription: externalDescription
        });
        return response;
    }

    async getOrderHistory(orderId: number, pageSize: number = 0, currentPage: number = 0): Promise<OrderHistoryResponse>{
        let response = await orderService.getOrderHistory({
            orderId: orderId,
            pageSize: pageSize,
            currentPage: currentPage
        });
        return response;
    }
}

const orderManager = new OrderManager();
export default orderManager;