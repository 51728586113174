import './TopUserOrder.css';
import React, {useEffect, useState} from "react";
import dashboardManager from '../../../../../managers/manager/dashboard.manager.tsx';
import { UserOrder } from '../../../../../models/manager/Dashboard/UserOrder.ts';


interface ITopUserOrderProps{

}

interface ITopUserOrderState{
    loading: boolean;
    userOrders: UserOrder[];
}
export const TopUserOrder: React.FC<ITopUserOrderProps> = (props) => {
    const [state, setState] = useState<ITopUserOrderState>({
        loading: true,
        userOrders: []
    });

    useEffect(() => {
        if(state.loading)
        {
            dashboardManager.getTopUserOrders(10).then((response)=>{
                setState(prevstate => 
                    ({
                        ...prevstate,
                        loading: false,
                        userOrders: response.userOrders
                    })
                );    
            });
        }
    }, [state.loading]);

    if (state.loading)
        return (
            <div className='loading-animated'></div>)
    else
        return (
            <div className={state.loading ? 'loading-animated' : ''}>
                <div className='dashboard-item-title'>Топ 10 користувачів</div>
                <div className='dashboard-item-description'>
                    <div className='top-user-order'>
                        <div className='header'>
                            <div className='rows d-flex flex-column flex-md-row text-center text-md-start'>
                                <div>№</div>
                                <div>Користувач</div>
                                <div>Email</div>
                                <div>Кількість замовлень</div>
                            </div>
                        </div>
                        <div className='body'>
                            {state.userOrders &&
                                state.userOrders.map((item, index) => (
                                    item?.user?.id?.length > 0 &&
                                    <div className='rows d-flex flex-column flex-md-row text-center text-md-start' key={item?.user?.id ?? index}>
                                        <div>{index + 1}</div>
                                        <div>{`${item.user.lastName} ${item.user.firstName}`}</div>
                                        <div>{item.user.email}</div>
                                        <div>{item.orderCount}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
}
