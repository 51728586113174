import { AttachmentType } from '../enums/AttachmentType.ts';
import { Company } from '../models/manager/Company.ts';
import {User} from '../models/user/User.ts'
import { UploadAttachmentsRequest } from '../services/attachment/Request/UploadAttachmentsRequest.ts';
import { UploadAttachmentsResponse } from '../services/attachment/Response/UploadAttachmentsResponse.ts';
import attachmentService from '../services/attachment/attachment.service.tsx';

export class AttachmentManager{

    async uploadAttachments(files: File[], attachmentType: AttachmentType, companyId: number ): Promise<UploadAttachmentsResponse>{
        let filestoupload = new FormData();
        files.forEach((value, index) =>{
            filestoupload.append(`Files`, value);
        });
        filestoupload.append('AttachmentType', attachmentType.toString());
        filestoupload.append('ForeignKey', companyId.toString());
        
        let response = await attachmentService.UploadAttachments(filestoupload);
        return response;
    }

    // async uploadAttachments(files: File[], attachmentType: AttachmentType): Promise<boolean>{
    //     console.log(files[0]);
    //     let filestoupload = new FormData();
    //     filestoupload.append('request', files[0]);
    //     let response = await attachmentService.UploadAttachments(filestoupload);
    //     return response;
    // }
}

const attachmentManager = new AttachmentManager();
export default attachmentManager;