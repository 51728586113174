import './BonusHistory.css';
import React, {useEffect, useState} from "react";
import usedBonusManager from '../../../../../managers/manager/usedBonus.manager.tsx';
import { useInView } from 'react-intersection-observer';
import { UsedBonus } from '../../../../../models/manager/UsedBonus.ts';
import { BonusHistoryItem } from '../bonus-history-item/BonusHistoryItem.tsx';
import { Loading } from '../../../../../components/loading/Loading.tsx';

interface IBonusHistoryProps{
    userId: string;
}

interface IBonusHistoryState{
    loading: boolean;
    pageSize: number;
    currentPage: number;
    usedbonuses: UsedBonus[];
    usedbonusesCount: number; 
    currentUsedBonusesCount: number; 
}

export const BonusHistory: React.FC<IBonusHistoryProps> = (props) => {

    const [state, setState] = useState<IBonusHistoryState>({
        loading: true,
        pageSize: 10,
        currentPage: 0,
        usedbonuses: [],
        usedbonusesCount: 1,
        currentUsedBonusesCount: 0,
    });
    const { ref, inView } = useInView({ threshold: 0.4, initialInView: true });

    useEffect(() => {
        if (inView && state.currentUsedBonusesCount < state.usedbonusesCount) {
            loadUsedBonuses();
        }
    }, [inView])

    useEffect(() => {
        if (props.userId && props.userId.length > 0) {
            setState(prevState => {
                return {
                    ...prevState,
                    loading: true,
                };
            });
            loadUsedBonuses(true);
        }
    }, [props.userId])

    const loadUsedBonuses = (isReload?: boolean) => {
        if (isReload) {
            usedBonusManager.getUsedBonuses(props.userId, state.pageSize, 1).then((response) => {
                if (response) {
                    let count = response.usedBonuses.length;
                    setState(prevState => {
                        return {
                            ...prevState,
                            usedbonuses: response.usedBonuses,
                            currentPage: 1,
                            loading: false,
                            usedbonusesCount: response.count,
                            currentUsedBonusesCount: count
                        };
                    });
                }
                else
                {
                    setState(prevState => {
                        return {
                            ...prevState,
                            usedbonuses: [],
                            currentPage: 0,
                            loading: false,
                            usedbonusesCount: 1,
                            currentUsedBonusesCount: 0
                        };
                    });
                    alert('Не вдалось завантажити історію списання бонусів.');
                }
            });
        }
        else {
            usedBonusManager.getUsedBonuses(props.userId, state.pageSize, state.currentPage + 1).then((response) => {
                if (response && response.usedBonuses) {
                    let count = response.usedBonuses.length + state.usedbonuses.length;
                    setState(prevState => {
                        return {
                            ...prevState,
                            usedbonuses: [
                                ...prevState.usedbonuses,
                                ...response.usedBonuses
                            ],
                            currentPage: prevState.currentPage + 1,
                            loading: false,
                            usedbonusesCount: response.count,
                            currentUsedBonusesCount: count
                        };
                    });
                }
                else 
                    alert('Не вдалось завантажити історію списання бонусів.');
            });    
        }
    }

    if (state.loading)
        return (
            <div className='bonus-history-container'>
                <Loading />
            </div>
        )
    else
        return (
            <div className='bonus-history-container'>
                {state.usedbonuses &&
                    state.usedbonuses.length > 0 ?
                    state.usedbonuses.map((usedBonus) =>
                    (
                        <BonusHistoryItem key={usedBonus.usedBonusId} usedBonus={usedBonus} />
                    ))
                    :
                    <div className='bonus-history-no-bonus-history'>
                        <span> Немає списань бонусів...</span>
                    </div>
                }
                <div style={{ height: 5 }} ref={ref}></div>
            </div>);
}