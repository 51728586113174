import './CheckBox.css';
import React, {Component} from "react";

interface ICheckBoxProps {
  fieldTittle: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>; 
}

interface ICheckBoxState{

}

export const CheckBox: React.FC<ICheckBoxProps> = (props) => {
   
  return (
      <div className="checkbox-wrapper">
        <label className="checkbox">
        <input type="checkbox" className='checkbox__input' 
        {...props.inputProps} />
        <span className="checkbox__label"></span>
        {props.fieldTittle}
        </label>
      </div>
    );
}