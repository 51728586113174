import './OrderContainer.css';
import React, { useState, useEffect } from "react";
import { Order } from "../../../../../models/manager/Order.ts";
import { OrderStatus } from '../../../../../enums/OrderStatus.ts';
import { ProductContainer } from './product-container/ProductContainer.tsx';
import { IOptionSelectField, SelectField } from '../selectfield/SelectField.tsx';
import orderManager from '../../../../../managers/manager/order.manager.tsx';

interface IOrderContainerProps{
    order: Order;
    editStatus: (orderId: number, newOrderStatus: OrderStatus, onSuccess?: (newstatus: OrderStatus) => void) => void;
}

interface IOrderContainerState {
    isWraped: boolean;
    orderStatus: OrderStatus; 
    popupActive: boolean;
}

export const OrderContainer: React.FC<IOrderContainerProps> = (props) => {

    const [state, setState] = useState<IOrderContainerState>(
    {
        isWraped: true,
        orderStatus: props.order?.orderStatus ? props.order?.orderStatus: OrderStatus.None,
        popupActive: false
    });

    const orderStatusClass = (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.InProcess: return 'order-status in-process';
            case OrderStatus.Canceled: return 'order-status canceled';
            case OrderStatus.Done: return 'order-status done';
            default: return 'order-status';
        }      
    }

    const getFormatedDate = (date: Date) => {
        let d = new Date(date);
        let localDate = new Date(d.getTime() - d.getTimezoneOffset()*60*1000);
        return `${localDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${(localDate.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.${localDate.getFullYear()}, ${localDate.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${localDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
    }

    const onWrapClick = () => {
        setState(prevState => ({
            ...prevState,
            isWraped: !prevState.isWraped
        }));
    }

    const MeasureTypeOptions: IOptionSelectField[] = 
  [
    { label: ' - ', value: OrderStatus.None.valueOf()},
    { label: 'В процесі', value: OrderStatus.InProcess.valueOf()},
    { label: 'Виконано', value: OrderStatus.Done.valueOf()},
    { label: 'Скасовано', value: OrderStatus.Canceled.valueOf()}
  ];

    const onSelectOrderStatusChange = (e) => {
        let value = Number(e.target.value);
        if (value && value > 0 && props.order?.orderId > 0) {
            // orderManager.changeStatus(props.order.orderId, value).then((data) => {
            //     if (!data.isSuccessfull)
            //         alert(data.message);
            //     else
            //         setState(prevState => ({
            //             ...prevState,
            //             orderStatus: value
            //         }));
            // });
            props.editStatus(props.order.orderId, value, onSuccessPopupEdit);
        }
    }

    const onSuccessPopupEdit = (newStatus: OrderStatus) => {
        setState(prevState => ({
            ...prevState,
            orderStatus: newStatus
        }));
    }
  
    return (
        <div>
            {props.order && (
                <div className="order-item-container p-1 p-md-3" key={props.order.orderId}>
                    <div className='order-top'>
                        <div className='order-top-first'>
                            {/* <span className={`${orderStatusClass(props.order.orderStatus)} text-overflow`}>
                                {props.order?.orderStatus && (
                                    MeasureTypeOptions.map(function (item) {
                                        if (item.value === props.order.orderStatus.valueOf())
                                            return item['label'];
                                    })
                                )}
                            </span> */}
                            <SelectField 
                                fieldName='status' 
                                options={MeasureTypeOptions} 
                                inputProps={{ 
                                    value: state.orderStatus,
                                    onChange: onSelectOrderStatusChange,
                                    className: orderStatusClass(state.orderStatus)
                                    }}>
                            </SelectField>
                            <span className='order-date text-overflow'>
                                {getFormatedDate(props.order.createDate)}
                            </span>
                        </div>
                        <div className='order-wrapped-bottom d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-end'>
                            <div className='order-number h3'>{props.order.orderNumber}</div>
                            <button className={`unwrap-button ${!state.isWraped && 'active'}`} onClick={onWrapClick} >
                                {state.isWraped ? 'Розгорнути' : 'Згорнути'}
                            </button>
                        </div>
                    </div>
                    {!state.isWraped && (
                        <div>
                            <div className='order-items'>
                                {
                                    props.order.shoppingCart.shoppingCartItems.map((item) => (
                                        <ProductContainer product={item.product} productCount={item.count} key={item.shoppingCartItemId} />
                                    ))
                                }
                            </div>
                            <div className='order-bottom'>
                                <div className='order-full-bonus'>
                                    <span>Загальна кількість нарахованих бонусів: </span>
                                    <span>{props.order.fullBonuses} &#8372;</span>
                                </div>
                                <div className='order-full-price'>
                                    <span>Загальна вартість: </span>
                                    <span>{props.order.fullPrice} &#8372;</span>
                                </div>
                            </div>
                            <br/>
                            <span className='line'/>
                            {(props.order.user && props.order.user.id.length > 0) && (
                                <div className='order-customer'>
                                    <div className='customer-column px-sm-2 col-12 col-md-6'>
                                        <div className='customer-row'>
                                            <span className='row-tittle text-overflow'>Ім'я</span>
                                            <span className='text-overflow'>{props.order.user.firstName}</span>
                                        </div>
                                        <div className='customer-row'>
                                            <span className='row-tittle text-overflow'>По-батькові</span>
                                            <span className='text-overflow'>{props.order.user.middleName}</span>
                                        </div>
                                        <div className='customer-row'>
                                            <span className='row-tittle text-overflow'>Прізвище</span>
                                            <span className='text-overflow'>{props.order.user.lastName}</span>
                                        </div>
                                    </div>
                                    {/* <hr className='horizontal-line'/> */}
                                    <div className='customer-column px-sm-2 col-12 col-md-6'>
                                        <div className='customer-row'>
                                            <span className='row-tittle text-overflow'>Номер телефону</span>
                                            <span className='text-overflow'>{props.order.user.phoneNumber}</span>
                                        </div>
                                        <div className='customer-row'>
                                            <span className='row-tittle text-overflow'>Електронна пошта</span>
                                            <span className='text-overflow'>{props.order.user.email}</span>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}