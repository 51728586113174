import './OrderManager.css';
import React, {useEffect, useState} from "react";
import { Loading } from '../../../components/loading/Loading.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { OrderContainer } from './components/order-container/OrderContainer.tsx';
import { Order } from '../../../models/manager/Order.ts';
import orderManager from '../../../managers/manager/order.manager.tsx';
import { useInView } from 'react-intersection-observer';
import { BarOptions, OrderBar } from './components/order-bar/OrderBar.tsx';
import { OrderStatus } from '../../../enums/OrderStatus.ts';
import { OrderStatusPopup } from './components/order-container/popup/OrderStatusPopup.tsx';

interface IOrderManagerProps{

}

interface IOrderManagerState{
    loading: boolean;
    pageSize: number;
    currentPage: number;
    orders: Order[];
    ordersCount: number; 
    currentOrdersCount: number; 
    search: string;
    orderStatus: OrderStatus;
}

interface IOrderManagerPopupState{
    popupActive: boolean;
    onSuccess?: (newstatus: OrderStatus) => void;
    orderId: number;
    newOrderStatus: OrderStatus;
}


export const OrderManager: React.FC<IOrderManagerProps> = (props) => {

    const [state, setState] = useState<IOrderManagerState>({
        loading: true,
        pageSize: 10,
        currentPage: 0,
        orders: [],
        ordersCount: 1,
        currentOrdersCount: 0,
        search: '',
        orderStatus: OrderStatus.None,
    });

    const [popupState, setPopupState] = useState<IOrderManagerPopupState>({
        popupActive: false,
        onSuccess: undefined,
        orderId: 0,
        newOrderStatus: OrderStatus.None
    });

    const { ref, inView } = useInView({ threshold: 0.4, initialInView: true });

    useEffect(() => {
        if (inView && state.currentOrdersCount < state.ordersCount) {
            loadOrders();
        }
    }, [inView])

    const loadOrders = () => {
        orderManager.getOrders(state.currentPage + 1, state.pageSize, state.search, state.orderStatus).then((response) => {
            if (response && response.orders) {
                let count = response.orders.length + state.orders.length;
                setState(prevState => {
                    return {
                        ...prevState,
                        orders: [
                            ...prevState.orders,
                            ...response.orders
                        ],
                        currentPage: prevState.currentPage + 1,
                        loading: false,
                        ordersCount: response.count,
                        currentOrdersCount: count
                    };
                });
            }
            else 
                alert('Не вдалось завантажити замовлення.');
        });
    }

    const reLoadOrders = (search: string = '', orderStatus: OrderStatus = OrderStatus.None) => {
        orderManager.getOrders(1, state.pageSize, search, orderStatus).then((response) => {
            if (response && response.orders) {
                let count = response.orders.length;
                setState(prevState => {
                    return {
                        ...prevState,
                        orders: response.orders,
                        currentPage: 1,
                        ordersCount: response.count,
                        currentOrdersCount: count,
                        orderStatus
                    };
                });
            }
            else 
                alert('Не вдалось завантажити замовлення.');
        });
    }

    const onOrderBarChanged = (data: BarOptions) => {
        reLoadOrders(data.search, data.status);
    }

    const editStatus = (orderId: number, newOrderStatus: OrderStatus, onSuccess?: (newstatus: OrderStatus) => void) => {
        setPopupState(prevState => {
            return {
                ...prevState,
                orderId,
                newOrderStatus,
                onSuccess,
                popupActive: true
            };
        });
    }

    const closePopup = () => {
        setPopupState(prevstate =>
        ({
            ...prevstate,
            popupActive: false
        }));
    }

    if (state.loading)
        return (
            <ManagerMenu action={ManagerMenuActions.Order}>
                <Loading />
            </ManagerMenu>);
    else
        return (
            <ManagerMenu action={ManagerMenuActions.Order}>
                <OrderBar onChange={onOrderBarChanged}/>
                {state.orders.map((order) =>
                (
                    order && 
                    <div key={`order_${order.orderId}`}>
                        <OrderContainer order={order} editStatus={editStatus} />
                    </div>
                ))}
                <div ref={ref}></div>
                <OrderStatusPopup 
                    isActive={popupState.popupActive}
                    orderId={popupState.orderId}
                    newOrderStatus={popupState.newOrderStatus}
                    onSuccess={popupState.onSuccess}
                    closePopup={closePopup}/>
            </ManagerMenu>);
}