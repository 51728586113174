import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { UpdateSubscriptionRequest } from './Request/UpdateSubscriptionRequest.ts';
import { UpdateSubscriptionResponse } from './Response/UpdateSubscriptionResponse.ts';

export class SubscriptionService {

  async updateSubscription(request: UpdateSubscriptionRequest): Promise<UpdateSubscriptionResponse> {
    let response = await api
      .post<UpdateSubscriptionResponse>(ApplicationEndpoints.UpdateSubscription,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new UpdateSubscriptionResponse();
  }
}
const subscriptionService = new SubscriptionService();
export default subscriptionService;