import './TopOrders.css';
import React, {useEffect, useState} from "react";
import dashboardManager from '../../../../../managers/manager/dashboard.manager.tsx';
import { UserOrderByPrice } from '../../../../../models/manager/Dashboard/UserOrderByPrice.ts';


interface ITopOrdersProps{

}

interface ITopOrdersState{
    loading: boolean;
    userOrders: UserOrderByPrice[];
}
export const TopOrders: React.FC<ITopOrdersProps> = (props) => {
    const [state, setState] = useState<ITopOrdersState>({
        loading: true,
        userOrders: []
    });

    useEffect(() => {
        if(state.loading)
        {
            dashboardManager.getTopUserOrdersByPrice(10).then((response)=>{
                setState(prevstate => 
                    ({
                        ...prevstate,
                        loading: false,
                        userOrders: response.userOrders
                    })
                );    
            });
        }
    }, [state.loading]);

    if (state.loading)
        return (
            <div className='loading-animated'></div>)
    else
        return (
            <div className={state.loading ? 'loading-animated' : ''}>
                <div className='dashboard-item-title'>Топ 10 замовлень</div>
                <div className='dashboard-item-description'>
                    <div className='top-order'>
                        <div className='header'>
                            <div className='rows d-flex flex-column flex-md-row text-center text-md-start'>
                                <div>№</div>
                                <div>Замовлення</div>
                                <div>Покупець</div>
                                <div>Вартість</div>
                            </div>
                        </div>
                        <div className='body'>
                            {state.userOrders &&
                                state.userOrders.map((item, index) => (
                                    item?.user?.id?.length > 0 &&
                                    <div className='rows d-flex flex-column flex-md-row text-center text-md-start' key={item.orderId}>
                                        <div>{index + 1}</div>
                                        <div>{item.orderNumber}</div>
                                        <div>{`${item.user.lastName} ${item.user.firstName}`}</div>
                                        <div>{item.price} &#8372;</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
}
