import './WelcomeManager.css';
import React, {useState, useEffect} from "react";
import { Welcome } from '../../../models/manager/Welcome.ts';
import welcomeManager from '../../../managers/manager/welcome.manager.tsx';
import { Loading } from '../../../components/loading/Loading.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { useForm, FormProvider } from "react-hook-form";
import { TextArea } from '../../../components/textarea/TextArea.tsx';


interface IWelcomeManagerProps{
}

interface IWelcomeManagerState{
  loading: boolean;
  updating: boolean;
  error: string;
}

interface IWelcomeForm{
    welcome: Welcome;
}

export const WelcomeManager: React.FC<IWelcomeManagerProps> = (props) => {

  const [state, setState] = useState<IWelcomeManagerState>({
        error: '',
        updating: true,
        loading: true
  });

  useEffect(() =>{
}, [state.updating])

  const loadWelcome = async () => {
      let data = await welcomeManager.getWelcome();
      let defValues: IWelcomeForm = {
          welcome:
          {
              welcomeMessage: ''
          }
      } 
      let result = 
      {
        ...defValues,
        welcome: {
            ...defValues.welcome,
            ...data
        }
      };
      setLoad(false);
      return result;
    }


  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const onUpdate = async (data: IWelcomeForm) => {
        setLoad(true);
        if (data && data.welcome) {
            console.log(data);
            await welcomeManager.updateWelcome(data.welcome).then(async (data) => {
                if (!data.isSuccessfull) alert(data.message);
                setLoad(false);
            });
        }
    };

    const methods = useForm({
        defaultValues: loadWelcome}
    );

    const { formState: { errors }} = methods;

    if (state.loading)
        return (
            <ManagerMenu action={ManagerMenuActions.Welcome}>
                <Loading />
            </ManagerMenu>);
    else
        return (
            <ManagerMenu action={ManagerMenuActions.Welcome}>
                <div className='about-us-content-center'>
                    <div className='about-us-edit-container'>
                        <h3 className='about-us-tittle'>Сторінка першого відкриття</h3>
                        <FormProvider {...methods}>
                            <TextArea 
                                fieldTittle='Текст привітального сповіщення'
                                errorMessage={errors?.welcome?.welcomeMessage?.message}
                                inputProps={{
                                    rows: 10,
                                    ...methods.register("welcome.welcomeMessage",
                                        {
                                            required: 'Це поле обов`язкове!',
                                            maxLength: {
                                                value: 500, message: 'Максимальна кількість знаків - 500!'
                                            }
                                        }),
                                }} />
                        </FormProvider>
                        <input type='button' className='about-us-button' value='Оновити' onClick={methods.handleSubmit(onUpdate)}></input>
                    </div>
                </div>
            </ManagerMenu>);
}