import './ChangePassword.css';
import React, {useState, useEffect} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Loading } from '../../components/loading/Loading.tsx';
import { TextField } from '../../components/textfield/TextField.tsx';
import userManager from '../../managers/user.manager.tsx';
import { useLocation } from 'react-router-dom';


interface IChangePasswordProps{
}

interface IChangePasswordState{
    loading: boolean;
    updating: boolean;
    error: string;
    token: string;
    email: string;
}

export const ChangePassword: React.FC<IChangePasswordProps> = (props) => {

  const [state, setState] = useState<IChangePasswordState>({
        error: '',
        updating: true,
        loading: false,
        token: '',
        email: ''
  });

  const location = useLocation();

    useEffect(() => {
        if (state.updating) {
            const query = new URLSearchParams(location.search);
            const token = query.get('token');
            const email = query.get('email');
            if (token && token.length > 0 && email && email.length > 0) {
                setState(prevstate => {
                    return {
                        ...prevstate,
                        token: token,
                        email: email,
                        updating: false
                    }
                });
            }
        }
    }, [state.updating])

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }

    const methods = useForm({
        defaultValues: {
            newPassword: '',
            Password: ''
        }
    });

    const { formState: { errors }} = methods;

    const onSubmit = async (data) => {
        if (data && data.newPassword && state.token && state.email) {
            setLoad(true);
            await userManager.ChangePassword(state.token, data.newPassword, state.email).then(async (data) => {
                if (!data?.isSuccessfull) 
                    alert(data.message);
                else
                {
                    alert("Пароль успішно змінено!");
                }
            });
        }
        else
            alert("Не вдалось змінити пароль, спробуйте пізніше.");
        setLoad(false);
    };

    return (
        state.loading ? <Loading /> :
        <div className='change-password-container'>
            <div className='change-password-logo'/>
            <div className='change-password-center'>
                <h3 className='change-password-tittle'>Відновлення паролю</h3>
                <form>
                    <FormProvider {...methods}>
                        <div className='change-password-fields'>
                        <TextField
                            inputProps={{
                                ...methods.register("newPassword",
                                    {
                                        required: 'Це поле обов`язкове!',
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: 'Пароль повинен містити хоча б одну цифру,\n спеціальний символ, велику та малу літери.\n Мінімальна довжина паролю - 8 символів!'
                                        },
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 50!'
                                        },
                                        onChange: () => {methods.clearErrors('Password')}
                                    })
                            }}
                            fieldTittle='Новий пароль'
                            errorMessage={errors?.newPassword?.message} />                        
                        <TextField
                            inputProps={{
                                ...methods.register("Password",
                                    {
                                        required: 'Це поле обов`язкове!',
                                        validate: (value) => {if(!(value && value.length > 0 && value === methods.getValues('newPassword'))) return 'Пароль повинен співпадати!'},
                                        maxLength: {
                                            value: 100, message: 'Максимальна кількість знаків - 50!'
                                        },
                                    })
                            }}
                            fieldTittle='Повторіть пароль'
                            errorMessage={errors?.Password?.message} />
                            </div>
                        <input type='button' className='change-password-button' value='Відновити' onClick={methods.handleSubmit(onSubmit)}></input>
                    </FormProvider>
                </form>
            </div>
        </div>
    );
}