import './SelectField.css';
import React from "react"

export interface IOptionSelectField{
  label: string;
  value: string | number;
}

interface ISelectFieldProps{
  fieldName: string;
  fieldTittle: string;
  value?: string | number | undefined;
  defaultValue?: string | number | undefined;
  options: IOptionSelectField[];
  valueAsNumber?: boolean;
  onChange?: (e) => void;
  isRequired?: boolean;
  isMultiple?: boolean;
}

interface ISelectFieldState{

}

export const SelectField: React.FC<ISelectFieldProps> = (props) => {

    return (
      <div className='select-field-container'>
      <label className='select-field'>
          <select className='select-field-input' name={props.fieldName} value={props.value} onChange={props.onChange} defaultValue={props.defaultValue} required={props.isRequired} multiple={props.isMultiple}>
            <option value={''} >Обрати...</option>
            {props.options.map(function (data) {
              return (
                <option key={data.value} value={data.value}>{data.label}</option>
              )
            })}
          </select>
        <span className='select-field-label'>{props.fieldTittle}</span>
      </label>
      </div>
      );
}