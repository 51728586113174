import './CompanyEditPopup.css';
import React, {useState, useEffect} from "react";
import { Popup } from '../../../../../components/popup/Popup.tsx';
import { Company } from '../../../../../models/manager/Company.ts';
import companyManager from '../../../../../managers/manager/company.manager.tsx';
import { TextField } from '../../../../../components/textfield/TextField.tsx';
import { IOptionSelectField } from '../../../../../components/selectfield/SelectField.tsx';
import { CheckBox } from '../../../../../components/checkbox/CheckBox.tsx';
import AttachmentUploader from '../../../../../components/attachment/AttachmentUploader.tsx';
import attachmentManager from '../../../../../managers/attachment.manager.tsx';
import { AttachmentType } from '../../../../../enums/AttachmentType.ts';
import { Loading } from '../../../../../components/loading/Loading.tsx';
import productTypeBasicManager from '../../../../../managers/manager/productTypeBasic.manager.tsx';
import { SelectFieldMultiple } from '../../../../../components/selectfield-multiple/SelectFieldMultiple.tsx';
import productTypeManager from '../../../../../managers/manager/productType.manager.tsx';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

interface ICompanyEditPopupProps{
  isActive: boolean;
  setActive: () => void;
  companyId: number;
}

interface ICompanyEditPopupState{
  company: Company;
  loading: boolean;
  image?: File;
  productTypes: IOptionSelectField[];
  productTypesSelected: IOptionSelectField[];
}

export const CompanyEditPopup: React.FC<ICompanyEditPopupProps> = (props) => {

  const [state, setState] = useState<ICompanyEditPopupState>({
      company: {
        companyId: 0,
        name: '',
        isActive: false,
        attachment: undefined
      },
      loading: true,
      image: undefined,
      productTypes: [],
      productTypesSelected: []
  });

  useEffect(() => {
    if (props.isActive) {
      clearErrors();
      if (props?.companyId > 0) {
        loadCompanyData();
        setState(prevState  => {
          return {
          ...prevState,
          image: undefined
          }
        });
      }
      else
      {
        setState(prevState  => {
          return {
          ...prevState,
          company: {
            companyId: 0,
            name: '',
            isActive: false,
            attachment: undefined
          },
          loading: false,
          image: undefined,
          productTypes: [],
          productTypesSelected: []
        }});
      }
      loadProductTypesBasicData();
      loadProductTypesBasicSelectedData();
    }
  }, [props.isActive]);

  const methods = useForm();
  const { register, setValue, clearErrors, formState: { errors }} = methods;

  const loadCompanyData = () => {
    if (props.companyId > 0) {
      companyManager.getCompany(props.companyId).then((data) =>
      {
        setState(prevState => {
          return {
            ...prevState,
            company: {...data},
            loading: false
          }});
        setValue('name', data.name);
      }); 
    }
  };

  const loadProductTypesBasicData = () => {
    productTypeBasicManager.getAllProductTypeBasics().then((data) => {
      let options: IOptionSelectField[] = [];
      if (data && data.length > 0) {
        data.forEach((x) => { options.push({ label: x.name, value: x.productTypeBasicId }) });

        setState(prevState => {
          return {
            ...prevState,
            productTypes: options,
            loading: false
          }
        });
      }
    });
  };

  const loadProductTypesBasicSelectedData = () => {
    productTypeBasicManager.getAllByCompanyIdSelected(props.companyId).then((data) => {
      let options: IOptionSelectField[] = [];
      if (data && data.length > 0) {
        data.forEach((x) => { options.push({ label: x.name, value: x.productTypeBasicId }) });

        setState(prevState => {
          return {
            ...prevState,
            productTypesSelected: options,
            loading: false
          }
        });
      }
    });
  };

  const setLoad = (value: boolean) => {
      setState(prevstate => {
        return {
          ...prevstate,
          loading: value
        }});
    }
  
  const updateImages = (files: File[]) => {
    setState(prevstate =>
      ({
        ...prevstate,
        image: files[0]
      }));
  };

  const updateExistingImages = (idRemove: number) => {
    let company = state.company;
    setState(prevstate =>
      ({
        ...prevstate,
        company: {
          ...company,
          attachment: prevstate.company.attachment?.attachmentId === idRemove ? undefined : prevstate.company.attachment
        }
      }));
  };

  const onPopupUpdate = async () => {
    setLoad(true);
    let company = state.company;
    if(company.attachment)
    {
      company.attachment = company.attachment.isDefault ? undefined : company.attachment;
    }

    await companyManager.updateCreateCompany(company).then(async (data) => {
      if (!data.isSuccessfull) alert(data.message ?? "Не вдалось оновити(додати) компанію!");

      let companyId = state.company.companyId; 
      if (!(companyId > 0) && data?.companyId && data?.companyId > 0)
        companyId = data.companyId as number;

      if (companyId > 0) {
        let newProductTypeBasics = state.productTypesSelected.map(function (x) { return Number(x['value']); });
        await productTypeManager.updateCompanyProductType(newProductTypeBasics, companyId).then((attdata) => {
          if (!attdata.isSuccessfull)
            alert(attdata.message ?? "Не вдалось завантажити зображення!");
        });

        let file = state.image;
        if (file) {
          await attachmentManager.uploadAttachments([file], AttachmentType.Company, companyId).then((attdata) => {
            if (!attdata.isSuccessfull) 
              alert(attdata.message ?? "Не вдалось завантажити зображення!");
          });
        }
      }
    });
    //Close Popup
    closePopup();
  };

  const closePopup = () => {
    props.setActive();
  }

  return (
    <Popup isActive={props.isActive} loading={state.loading} onClose={closePopup} onUpdate={methods.handleSubmit(onPopupUpdate)}
        tittle='Редагувати компанію' updateButtonTittle={props.companyId === 0 ? "Додати" : undefined}>
      <FormProvider {...methods}>
        <TextField
          fieldTittle='Назва'
          inputProps={{
            ...register('name',
              {
                onChange: (e) => { let value = e.target.value; setState(prevState => ({ ...prevState, company: { ...prevState.company, name: value } })) },
                required: 'Це поле обов`язкове!',
                value: state.company.name,
                maxLength: {
                  value: 100, message: 'Максимальна кількість знаків - 100!'
                }
              })
          }}
          errorMessage={errors.name?.message?.toString()} />
        <SelectFieldMultiple
          onChange={(e) => { setState((prevState) => { return { ...prevState, productTypesSelected: e } }); }}
          options={state.productTypes}
          value={state.productTypesSelected}
          isMultiple={true}
          fieldName='company.producttypes'
          fieldTittle='Категорії' />
        <CheckBox
          inputProps={{
            onChange: (e) => { let value = e.target.checked; setState(prevState => ({ ...prevState, company: { ...prevState.company, isActive: value } })) },
            name: 'isactive',
            checked: state.company.isActive
          }}
          fieldTittle='Видимість' />
        <AttachmentUploader
          accept=".jpg,.png,.jpeg"
          allowedTypes={["image/jpeg", "image/png"]}
          label=""
          maxAttachmentCount={1}
          updateFilesCb={(e) => updateImages(e)}
          existedFiles={state.company.attachment && !state.company.attachment.isDefault && [state.company.attachment]}
          updateExistedFilesCb={(e) => updateExistingImages(e)}
        />
      </FormProvider>
    </Popup>
  )
}