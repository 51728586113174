import { ColDef, GridApi, ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import { Grid } from '../../../components/grid/Grid.tsx';
import './UserManager.css';
import React, { useEffect, useMemo, useState } from "react";
import { Loading } from '../../../components/loading/Loading.tsx';
import { GridMenu } from '../../../components/grid/menu/GridMenu.tsx';
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import userManager from '../../../managers/user.manager.tsx';
import subscriptionManager from '../../../managers/manager/subscription.manager.tsx';
import { UsedBonusPopup } from './components/popup/UsedBonusPopup.tsx';
import { UserWithRole } from '../../../models/user/UserWithRole.ts';
import { UserRoleType } from '../../../enums/UserRoleType.ts';
import { Role } from '../../../models/user/Role.ts';
import { UserSubscription } from '../../../models/common/UserSubscription.ts';
import { SubscriptionType } from '../../../enums/SubscriptionType.ts';
import { Button } from './components/activity-button/Button.tsx';

interface IUserManagerProps{

}

interface IUserManagerState{
    columnDefs: ColDef[];
    rowData: UserWithRole[];
    popupActive: boolean;
    currentUserId: string;
    loading: boolean;
    selectedrow: string;
    pageSize: number;
}

export const UserManager: React.FC<IUserManagerProps> = (props) => {

    const roleRenderer = (params: ICellRendererParams<{},Role[]>) => {
        return (
            <span >
                {params?.data && params?.value && params?.value.length > 0 ? (
                    params.value.filter(x => x.userRoleType === UserRoleType.Admin).length > 0 ? 'адміністратор' :
                    params.value.filter(x => x.userRoleType === UserRoleType.User).length > 0 && 'користувач'
                ) : '-'}
            </span>
        );
    }
    const subscriptionRenderer = (params: ICellRendererParams<UserWithRole,UserSubscription[]>) => {
        let isAccess = false;
        if(params?.data && params?.value?.length && params?.value?.length > 0){
            let sub = params.value.filter(x => x?.subscription?.subscriptionType === SubscriptionType.Access);
            isAccess = sub[0]?.isActive ?? false;
        }
        return (<Button isActive={isAccess} onClick={onAccessChangeClick} userId={params?.data?.id ?? ''} setIsNodeChanged={setIsNodeChanged} />);
    }
    const [state, setState] = useState<IUserManagerState>({
        columnDefs: [
            { headerName: '', field: 'checkboxBtn', checkboxSelection: true, pinned: 'left', width: 50, sortable: false },
            { headerName: '', field: 'Id', hide: true },
            { headerName: 'Ім`я', field: 'firstName', width: 180, sortable: false },
            { headerName: 'По-батькові', field: 'middleName', width: 160, sortable: false },
            { headerName: 'Прізвище', field: 'lastName', width: 180, sortable: false },
            { headerName: 'Номер телефону', field: 'phoneNumber', width: 150, sortable: false },
            { headerName: 'Електронна пошта', field: 'email', width: 180, sortable: false },
            { headerName: 'Роль', field: 'roles', cellRenderer: roleRenderer, width: 150, sortable: false },
            { headerName: 'Підписка', field: 'subscriptions', cellRenderer: subscriptionRenderer, width: 150, sortable: false }
        ],
        rowData: [],
        popupActive: false,
        currentUserId: '',
        loading: false,
        selectedrow: '',
        pageSize: 10
    });

    // useEffect(() => {
    //     updateUserData();
    // }, [state.updating])

    const [gridApi, setGridApi] = useState(null);

    const [isNodeChanged, setIsNodeChanged] = useState(false);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const paginationPageSizeSelector = useMemo(() => {
        return [10, 50, 100];
    }, []);

    const hardReloadGrid = () => {
        //reload grid
        if (gridApi) {
            let api = gridApi as GridApi;
            api.refreshInfiniteCache()
        }
    }

    useEffect(() => {
        if (gridApi) {
            let api = gridApi as GridApi;
            const dataSource = {
                getRows: (params: IGetRowsParams) => {
                    if (params?.endRow) {
                        let pageSize = state.pageSize;
                        const page = params.endRow / pageSize;
                        updateUserData(page, pageSize)
                            .then(res => {
                                params.successCallback(res.users, res.count);
                            });
                    }
                }
            }
            api.setGridOption('datasource', dataSource);
        }
    }, [gridApi]);

    useEffect(() => {
        if (isNodeChanged) {
            hardReloadGrid();
            setIsNodeChanged(false);
        }
    }, [isNodeChanged]);


    const updateUserData = async (currentPage: number, pageSize: number) => {
        let data = await userManager.getUsers(currentPage, pageSize);
        return data;
    };

    const setActivePopup = (id?: string, open?: boolean) => {
        if (!(id === null || id === undefined) && open) {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: true,
                    currentUserId: id
                })
            );
        }
        else {
            setState(prevstate => 
                ({
                    ...prevstate,
                    popupActive: false,
                    currentUserId: ''
                })
            );
        }
    }

    const onRowSelected = (e) => {
        if(gridApi)
        {
            var selected = (gridApi as GridApi).getSelectedNodes();
         
            if (selected) {
                let id = selected[0]?.isSelected() ? selected[0]?.data?.id ?? '' : '';
                setState(prevstate =>
                ({
                    ...prevstate,
                    selectedrow: id
                })
                );
            }
        }
    };

    const onBonusClick = () =>{
         setActivePopup(state.selectedrow, true)
    }

    const onAccessChangeClick = async (isActive: boolean, userId: string) => {
        let result = false;
        if(userId){
            let response = await subscriptionManager.updateSubscription(isActive, userId);
            if (!response || !response.isSuccessfull)
                alert(response?.message ?? 'Не вдалось оновити підписку');
            else {
                return true;
            }
        }  
        else
            alert('Не вдалось оновити підписку');
        return result;
    }

        if (state.loading)
            return (
                <ManagerMenu action={ManagerMenuActions.User}>
                    <Loading />
                </ManagerMenu>);
        else
            return (
                <ManagerMenu action={ManagerMenuActions.User}>
                    <GridMenu 
                        isMenuActive={true} 
                        isAddButtonActive={false} 
                        isDeleteButtonActive={false}>
                        <div>
                            <button className={'grid-button'} onClick={onBonusClick} disabled={state.selectedrow.length === 0}>Бонуси</button>
                        </div>
                    </GridMenu>
                    <Grid 
                        pagination={true} 
                        pageSize={state.pageSize} 
                        height={500} 
                        rowData={state.rowData} 
                        rowSelection='single' 
                        onRowSelected={onRowSelected}
                        columnDefs={state.columnDefs} 
                        defaultColDef={this}
                        onGridReady={onGridReady}
                        rowModelType='infinite'
                        paginationPageSizeSelector={paginationPageSizeSelector} 
                        cacheBlockSize={state.pageSize}
                    />
                    <UsedBonusPopup userId={state.currentUserId} isActive={state.popupActive} setActive={setActivePopup} />                
                </ManagerMenu>);
}