import { Welcome } from '../../models/manager/Welcome.ts'
import { UpdateWelcomeRequest } from '../../services/manager/welcome/Request/UpdateWelcomeRequest.ts';
import { UpdateWelcomeResponse } from '../../services/manager/welcome/Response/UpdateWelcomeResponse.ts';
import welcomeService from '../../services/manager/welcome/welcome.service.tsx';

export class WelcomeManager{

    async getWelcome(): Promise<Welcome>{
        let response = await welcomeService.getWelcome();
        return response.welcome;
    }


    async updateWelcome(welcome: Welcome): Promise<UpdateWelcomeResponse>{
        let request: UpdateWelcomeRequest = {
            welcome: welcome
        }
        let response = await welcomeService.updateWelcome(request);
        return response;
    }
}

const welcomeManager = new WelcomeManager();
export default welcomeManager;