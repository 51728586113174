import './SelectField.css';
import React from "react"

export interface IOptionSelectField{
  label: string;
  value: string | number;
}

interface ISelectFieldProps{
  fieldName: string;
  inputProps?: React.TextareaHTMLAttributes<HTMLSelectElement>;
  options: IOptionSelectField[];
}

interface ISelectFieldState{

}

export const SelectField: React.FC<ISelectFieldProps> = (props) => {

  return (
    <select
      name={props.fieldName}
      {...props.inputProps}>
      {props.options.map(function (data) {
        return (
          <option key={data.value} value={data.value}>{data.label}</option>
        )
      })}
    </select>
  );
}