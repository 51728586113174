import { OrderStatus } from '../../../../../enums/OrderStatus.ts';
import './OrderBar.css';
import React, { useRef, useState } from "react";
import useDebounce from '../../../../../custom-hooks/useDebounce.jsx'

export class BarOptions{
    search: string;
    status: OrderStatus;
}

interface IOrderBarProps{
    onChange: (data: BarOptions) => void;
}

interface IOrderBarState{
    options: BarOptions;
}

export const OrderBar: React.FC<IOrderBarProps> = (props) => {

    const [state, setState] = useState<IOrderBarState>({
        options: {
            search: '',
            status: OrderStatus.None
        }
    });

    const { debounce } = useDebounce();

    const searchInput = useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     if (true) {

    //     }
    // }, [])

    const onSearchChange = (text: string) => {
        setState(prevState => {
            return {
                ...prevState,
                options: {
                    ...prevState.options,
                    search: text
                }
            };
        });
        onChangeBar({
            search: text,
            status: state.options.status
        })
    }

    const onChangeBar = (options: BarOptions) => {
        props.onChange(options)
    }
    
    const onStatusButtonClick = (status: OrderStatus) => {
        onChangeBar({
            search: state.options.search,
            status: status
        })   
        setStatus(status); 
    }

    const setStatus = (status: OrderStatus) => {
        setState(prevState => {
            return {
                ...prevState,
                options: {
                    ...prevState.options,
                    status: status
                }
            };
        });
    }

    const handleSearch = debounce(inputVal => onSearchChange(inputVal), 500)

    const onClearSearchClick = (e) => {
        if (e) {
            e.value = '';
            onSearchChange('');
        }
    }

    return(
        <div className='order-bar p-1 p-md-3'>
            <div className='order-bar-buttons gap-1'>
                <button
                    className={`order-bar-button ${state.options.status === OrderStatus.None ? 'active' : ''}`}
                    onClick={() => onStatusButtonClick(OrderStatus.None)}>
                    Всі
                </button>
                <button
                    className={`order-bar-button in-process ${state.options.status === OrderStatus.InProcess ? 'active' : ''}`}
                    onClick={() => onStatusButtonClick(OrderStatus.InProcess)}>
                    В процесі
                </button>
                <button 
                    className={`order-bar-button done ${state.options.status === OrderStatus.Done ? 'active' : ''}`}
                    onClick={() => onStatusButtonClick(OrderStatus.Done)}>
                        Виконано
                </button>
                <button 
                    className={`order-bar-button canceled ${state.options.status === OrderStatus.Canceled ? 'active' : ''}`}
                    onClick={() => onStatusButtonClick(OrderStatus.Canceled)}>
                        Скасовано
                </button>
            </div>
            <div className='order-search-bar'>
                <input type='text' 
                    ref={searchInput}
                    onChange={() => handleSearch(searchInput.current?.value)} 
                    placeholder='Пошук за номером договору...' />
                <button className='clear-search' onClick={() => onClearSearchClick(searchInput.current)}>
                    <svg stroke-linejoin="round" stroke-miterlimit="2" width='24px' height='24px' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" /></svg>
                </button>
            </div>
        </div>
    );
}