import authService from '../services/auth/auth.service.tsx';
import { LoginRequest } from '../services/auth/Models/Request/LoginRequest.ts';
import { LogoutRequest } from '../services/auth/Models/Request/LogoutRequest.ts';
import { LoginResponse } from '../services/auth/Models/Response/LoginResponse.ts';
import { LogoutResponse } from '../services/auth/Models/Response/LogoutResponse.ts';
import { RefreshTokenResponse } from '../services/auth/Models/Response/RefreshTokenResponse.ts';
import { RefreshTokenRequest } from '../services/auth/Models/Request/RefreshTokenRequest.ts';
import userManager from './user.manager.tsx';
import { ApplicationPaths } from '../ApplicationPaths.js';


export class AuthManager {
    constructor(){
    }

    isAuthenticated(): boolean {
        return !!(this.getAccessToken() && this.getRefreshToken());
    }
    
    getAccessToken(): string {
        return localStorage.getItem('token') || '';
    }

    getRefreshToken(): string {
        return localStorage.getItem('refreshToken') || '';
    }

    async Login(email: string, password: string): Promise<boolean>{
        let singIn = await this.SignIn({
            email: email, 
            password: password
        });
        if(singIn && singIn.refreshToken && singIn.token){
                localStorage.setItem('token', singIn.token);
                localStorage.setItem('refreshToken', singIn.refreshToken)
            }
        else
            return false;
        return true;
    }

    async Logout(){
        let token = this.getAccessToken();
        let refreshtoken = this.getRefreshToken();

        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        userManager.removeUser();

        if(token && refreshtoken)
            this.SignOut({
                token: this.getAccessToken(),
                refreshtoken: this.getRefreshToken()
            });
    }

    async SignIn(loginRequest: LoginRequest): Promise<LoginResponse>  {
        let result = authService.login(loginRequest);
        return result;
    }

    async SignOut(logoutRequest: LogoutRequest): Promise<LogoutResponse>  {
        let result = authService.logout(logoutRequest);
        return result;
    }

    async RefreshToken(): Promise<boolean> {
        let IsSuccessfull = false;
        let request: RefreshTokenRequest = {
            refreshToken: this.getRefreshToken()
        }
        let response = await authService.refreshToken(request)
                                        .catch((error) => {
                                            console.log(error?.response?.status);
                                            if(error?.response?.status === 401){
                                                this.Logout();
                                                window.location.replace(`${window.location.origin}/${ApplicationPaths.Login}`);
                                            }
                                        });
        let result = response?.data ? response?.data : undefined;
        if(result && result.newRefreshToken && result.newToken)
        {
            localStorage.setItem('token', result.newToken);
            localStorage.setItem('refreshToken', result.newRefreshToken)
            IsSuccessfull = true;
        }
        else{
            this.Logout();
            window.location.replace(`${window.location.origin}/${ApplicationPaths.Login}`);
        }
        return IsSuccessfull;
    }
}

const authManager = new AuthManager();
export default authManager;