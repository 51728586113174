import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetProductTypeResponse } from './Response/GetProductTypeResponse.ts';
import { GetAllProductTypesResponse } from './Response/GetAllProductTypesResponse.ts';
import { CreateUpdateProductTypeRequest } from './Request/CreateUpdateProductTypeRequest.ts';
import { GetAllProductTypesRequest } from './Request/GetAllProductTypesRequest.ts';
import { CreateUpdateProductTypeResponse } from './Response/CreateUpdateProductTypeResponse.ts';
import { DeleteProductTypeRequest } from './Request/DeleteProductTypeRequest.ts';
import { DeleteProductTypeResponse } from './Response/DeleteProductTypeResponse.ts';
import { GetAllActiveProductTypesSelectorRequest } from './Request/GetAllActiveProductTypesSelectorRequest.ts';
import { GetAllActiveProductTypesSelectorResponse } from './Response/GetAllActiveProductTypesSelectorResponse.ts';
import { UpdateCompanyProductTypeResponse } from './Response/UpdateCompanyProductTypeResponse.ts';
import { UpdateCompanyProductTypeRequest } from './Request/UpdateCompanyProductTypeRequest.ts';

export class ProductTypeService {

    async getProductType(id: number) : Promise<GetProductTypeResponse> {
        let response = await api
        .get<GetProductTypeResponse>(`${ApplicationEndpoints.GetProductType}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetProductTypeResponse();
    }

    async getAllProductTypes(request: GetAllProductTypesRequest) : Promise<GetAllProductTypesResponse> {
      let response = await api
      .post<GetAllProductTypesResponse>(ApplicationEndpoints.GetProductTypes,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllProductTypesResponse();
  }

  async getAllActiveProductTypesForSelector(request: GetAllActiveProductTypesSelectorRequest): Promise<GetAllActiveProductTypesSelectorResponse> {
    let response = await api
      .post<GetAllActiveProductTypesSelectorResponse>(ApplicationEndpoints.GetProductTypesForSelector,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new GetAllActiveProductTypesSelectorResponse();
  }

  async updateCreateProductType(request: CreateUpdateProductTypeRequest): Promise<CreateUpdateProductTypeResponse> {
    let response = await api
      .post<CreateUpdateProductTypeResponse>(ApplicationEndpoints.UpdateProductType,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateProductTypeResponse();
  }

  async updateCompanyProductType(request: UpdateCompanyProductTypeRequest) : Promise<UpdateCompanyProductTypeResponse> {
    let response = await api
    .post<UpdateCompanyProductTypeResponse>(ApplicationEndpoints.UpdateCompanyProductType,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new UpdateCompanyProductTypeResponse();
  }

  async deleteProductType(request: DeleteProductTypeRequest) : Promise<DeleteProductTypeResponse> {
    let response = await api
    .post<DeleteProductTypeResponse>(ApplicationEndpoints.DeleteProductType,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteProductTypeResponse();
  }
}

const productTypeService = new ProductTypeService();
export default productTypeService;