import './ManagerMenu.css';
import React, {PropsWithChildren} from "react"
import { ManagerMenuActions } from './ManagerMenuActions.ts';
import { ManagerButton } from '../button/ManagerButton.tsx';
import { ApplicationPaths } from '../../../ApplicationPaths.js';
import { Nav} from 'reactstrap';

interface IManagerMenuProps {
  action: string;
}

interface IManagerMenuState {

}

export const ManagerMenu: React.FC<PropsWithChildren<IManagerMenuProps>> = (props) => {

  return (
      <div className='manager-container container-xxl row'>
        <div className='manager-buttons col-12 col-lg-2 px-0'>
            <Nav className='manager-nav-bar flex-row flex-wrap flex-lg-column'>
                <ManagerButton name='Огляд' to={ApplicationPaths.Dashboard} isActive={props.action === ManagerMenuActions.Dashboard}></ManagerButton>
                <ManagerButton name='Компанія' to={ApplicationPaths.Company} isActive={props.action === ManagerMenuActions.Company}></ManagerButton>
                <ManagerButton name='Товар' to={ApplicationPaths.Product} isActive={props.action === ManagerMenuActions.Product}></ManagerButton>
                <ManagerButton name='Категорія' to={ApplicationPaths.ProductType} isActive={props.action === ManagerMenuActions.ProductType}></ManagerButton>
                <ManagerButton name='Сповіщення' to={ApplicationPaths.Notification} isActive={props.action === ManagerMenuActions.Notification}></ManagerButton>
                <ManagerButton name='Бонус' to={ApplicationPaths.Bonus} isActive={props.action === ManagerMenuActions.Bonus}></ManagerButton>
                <ManagerButton name='Підтримка' to={ApplicationPaths.Support} isActive={props.action === ManagerMenuActions.Support}></ManagerButton>
                <ManagerButton name='Про нас' to={ApplicationPaths.AboutUs} isActive={props.action === ManagerMenuActions.AboutUs}></ManagerButton>
                <ManagerButton name='Перше відкриття' to={ApplicationPaths.Welcome} isActive={props.action === ManagerMenuActions.Welcome}></ManagerButton>
                <ManagerButton name='Користувачі' to={ApplicationPaths.User} isActive={props.action === ManagerMenuActions.User}></ManagerButton>
                <ManagerButton name='Замовлення' to={ApplicationPaths.Order} isActive={props.action === ManagerMenuActions.Order}></ManagerButton>
                <ManagerButton name='1C' to={ApplicationPaths.OneC} isActive={props.action === ManagerMenuActions.OneC}></ManagerButton>
            </Nav>
        </div>
        <div className='manager-page col-12 col-lg-10'>
            {props.children}
        </div>
    </div>
  );
}