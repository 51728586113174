import './CurrencyRateBlock.css';
import React, {useState, useEffect} from "react";
import { Loading } from '../../../../../components/loading/Loading.tsx';
import { Grid } from '../../../../../components/grid/Grid.tsx';
import { ColDef } from 'ag-grid-community';
import { CurrencyRate } from '../../../../../models/manager/CurrencyRate.ts';
import onecManager from '../../../../../managers/manager/oneC.manager.tsx';
import { CurrencyType } from '../../../../../enums/CurrencyType.ts';


interface ICurrencyRateBlockProps{
}

interface ICurrencyRateBlockState{
  loading: boolean;
  error: string;
  columnDefs: ColDef[];
  rowData: CurrencyRate[];
  changedData: CurrencyRate[];
}

export const CurrencyRateBlock: React.FC<ICurrencyRateBlockProps> = (props) => {

  const nameValueSetter = (params) => {
    if(params?.newValue <= 0)
      alert('Значення повинно бути більше за 0!');

    if (typeof(params.newValue) === 'number' && params.newValue > 0 && params.oldValue > 0) {
      let normalizeValue = Math.round((params.newValue + Number.EPSILON) * 100) / 100;
      params.data.exchangeRate = normalizeValue
      
      return params.oldValue !== normalizeValue;
    }
    return false;
  };

  const [state, setState] = useState<ICurrencyRateBlockState>({
        error: '',
        loading: true,
        columnDefs: [
          { headerName: 'Назва', field: 'currency', width: 250 },
          { headerName: 'Обмінний курс', field: 'exchangeRate', width: 200, editable: (params) => params.data.currencyType != CurrencyType.UAH, valueSetter: nameValueSetter }
      ],
      rowData: [],
      changedData: []
  });

  useEffect(() =>{
    loadCurrencies();
}, [])

  const loadCurrencies = async () => {
    onecManager.GetCurrencyRates().then((data) => {
      setState(prevstate => {
        return {
          ...prevstate,
          rowData: data,
          loading: false
        }
      });
    });
  }

  const onCellValueChanged = (params) => {
    if(params?.data && params?.data.currency?.length > 0 && params?.data.currencyRateId > 0 && params?.data.currencyType > 0 && params?.data.exchangeRate > 0)
    {
      const index = state.changedData.findIndex(item => item.currencyRateId === params.data.currencyRateId);
      let updatedItems: CurrencyRate[] = [];

      if (index !== -1) {
        updatedItems = state.changedData.map(item => {
          if (item.currencyRateId === params.data.currencyRateId) {
            let updatedItem = item;
            item.exchangeRate = params.data.exchangeRate ?? 0;
            return updatedItem;
          } else {
            // No change
            return item;
          }
        });
      } else {
        updatedItems = [...state.changedData,
                        params.data
                       ];
      }

      setState(prevstate => {
        return {
          ...prevstate,
          changedData: updatedItems
        }
      });
    }
  }

  const onUpdateClick = async (e) => {
    if(state.changedData?.length > 0){
      setState(prevstate => {
        return {
          ...prevstate,
          loading: true
        }
      });

      let result = await onecManager.UpdateCurrencyRate(state.changedData);

      setState(prevstate => {
        return {
          ...prevstate,
          loading: false
        }
      });
      
      if(!result || !result?.isSuccessfull){
        alert(result?.message ?? 'Не вдалось оновити курс валют!');
      }
    }
  }

    if (state.loading)
        return (<Loading />);
    else
      return (
        <div className='currency-rate-container'>
          <h3 className='currency-rate-header'>Курс Валют</h3>
          <div className='currency-rate-grid'>
            <Grid
              pagination={false}
              height={200}
              rowData={state.rowData}
              columnDefs={state.columnDefs}
              defaultColDef={this}
              onCellValueChanged={onCellValueChanged}
            />
          </div>
          <input type='button' 
            className='currency-rate-update-button' 
            onClick={onUpdateClick} 
            value={'Оновити'}
            disabled={state.changedData.length === 0} />
        </div>);
}