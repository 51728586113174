import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './login-menu/LoginMenu.tsx';
import './NavMenu.css';
import authManager from '../managers/auth.manager.tsx';
import { ApplicationPaths } from '../ApplicationPaths.js';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    let isAuthenticated = authManager.isAuthenticated();
    if (isAuthenticated)
      return (
        <header className="nav-background">
            <Navbar className="container-xxl navbar-dark navbar-expand-lg navbar-toggleable-lg ng-white">
            <NavbarBrand tag={Link} className="text-light d-flex align-items-center py-0" to="/">
                <img src={'../logo.png'} height={"48px"} className='me-1' alt={'Логотип сайту'}></img>
                {/*<span className='mt-1'>Euro Land Management</span>*/}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-light" to={ApplicationPaths.Dashboard}>Менеджер</NavLink>
                </NavItem>
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Navbar>
        </header>
      );
      else
      return (
        <header className="nav-background">
          <Navbar className="container-xxl navbar-dark navbar-expand-lg navbar-toggleable-lg ng-white">
            <NavbarBrand tag={Link} className="text-light d-flex align-items-center py-0" to="/">
              <img src={'../logo.png'} height={"48px"} className='me-1' alt={'Логотип сайту'}></img>
              {/*<span className='mt-1'>Euro Land</span>*/}
              </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Navbar>
        </header>
      );
  }
}
