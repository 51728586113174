import {ApplicationPaths} from '../../ApplicationPaths';
import './ErrorHandler.css';
import * as React from "react"
export class ErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    handlerClick(){
      let url = `${window.location.origin}/${ApplicationPaths.Home}`;
      window.location.replace(url);  
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
            <div className="error-container">
            <h2>Щось трапилось!</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <div>Error</div>
              <div>{this.state.error}</div>
              <div>ErrorInfo</div>
              <div>{this.state.errorInfo}</div>
              <br />
            </details>
            <button onClick={this.handlerClick}>Ok</button>
            </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }