import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authManager from '../../managers/auth.manager.tsx';
import { ApplicationPaths } from '../../ApplicationPaths.js';
import userManager from '../../managers/user.manager.tsx';
import { User } from '../../models/user/User.ts';

interface ILoginMenuState{
  isAuthenticated: boolean;
  userName: string;
}

interface ILoginMenuProps{
}


export class LoginMenu extends Component<ILoginMenuProps, ILoginMenuState> {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: ''
    };
  }

   componentDidMount() {
     //this._subscription = authService.subscribe(() => this.populateState());
      this.populateState();
   }

  // componentWillUnmount() {
  //   authService.unsubscribe(this._subscription);
  // }

  async populateState() {
    let isAuth = await authManager.isAuthenticated();
    let currentuser = isAuth ? await userManager.getUser() : new User;
    //const [isAuthenticated, user]: [boolean, {}] = await Promise.all([isAuth, ])
    this.setState({
      isAuthenticated: isAuth,
      userName: currentuser.firstName
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.Logout}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, profilePath, logoutPath, logoutState) {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-light" to={profilePath}>Привіт, {userName}</NavLink>
      </NavItem>
      <NavItem>
        <NavLink replace tag={Link} className="text-light" to={logoutPath} state={logoutState}>Вихід</NavLink>
      </NavItem>
    </Fragment>);
  }

  anonymousView(registerPath, loginPath) {
    return (<Fragment>
      {/* <NavItem>
        <NavLink tag={Link} className="text-light" to={registerPath}>Register</NavLink>
      </NavItem> */}
      <NavItem>
        <NavLink tag={Link} className="text-light" to={loginPath}>Вхід</NavLink>
      </NavItem>
    </Fragment>);
  }
}
