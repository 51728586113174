import { ApplicationEndpoints } from '../../../ApplicationEndpoints.js';
import api from '../../api/api.authorized.js';
import { GetProductResponse } from './Response/GetProductResponse.ts';
import { GetAllProductsResponse } from './Response/GetAllProductsResponse.ts';
import { CreateUpdateProductRequest } from './Request/CreateUpdateProductRequest.ts';
import { GetAllProductsRequest } from './Request/GetAllProductsRequest.ts';
import { CreateUpdateProductResponse } from './Response/CreateUpdateProductResponse.ts';
import { DeleteProductRequest } from './Request/DeleteProductRequest.ts';
import { DeleteProductResponse } from './Response/DeleteProductResponse.ts';

export class ProductService {

    async getProduct(id: number) : Promise<GetProductResponse> {
        let response = await api
        .get<GetProductResponse>(`${ApplicationEndpoints.GetProduct}/${id}`)
          .catch(
            (error) => {
            });
      return response?.data || new GetProductResponse();
    }

    async getAllProducts(request: GetAllProductsRequest) : Promise<GetAllProductsResponse> {
      let response = await api
      .post<GetAllProductsResponse>(ApplicationEndpoints.GetProducts,
        request)
        .catch(
          (error) => {
          });
    return response?.data || new GetAllProductsResponse();
  }

  async updateCreateProduct(request: CreateUpdateProductRequest): Promise<CreateUpdateProductResponse> {
    let response = await api
      .post<CreateUpdateProductResponse>(ApplicationEndpoints.UpdateProduct,
        request)
      .catch(
        (error) => {
        });
    return response?.data || new CreateUpdateProductResponse();
  }

  async deleteProduct(request: DeleteProductRequest) : Promise<DeleteProductResponse> {
    let response = await api
    .post<DeleteProductResponse>(ApplicationEndpoints.DeleteProduct,
      request)
      .catch(
        (error) => {
        });
  return response?.data || new DeleteProductResponse();
  }
}

const productService = new ProductService();
export default productService;