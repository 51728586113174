import './OneCManager.css';
import React, {useState, useEffect} from "react";
import { ManagerMenu } from '../../../components/manager/menu/ManagerMenu.tsx';
import { ManagerMenuActions } from '../../../components/manager/menu/ManagerMenuActions.ts';
import { CurrencyRateBlock } from './components/currency/CurrencyRateBlock.tsx';


interface IOneCManagerProps{
}

interface IOneCManagerState {
}

export const OneCManager: React.FC<IOneCManagerProps> = (props) => {

  const [state, setState] = useState<IOneCManagerState>({
  });

  useEffect(() => {
  }, [])

  return (
    <ManagerMenu action={ManagerMenuActions.OneC}>
      <div className='one-c-item'>
        <CurrencyRateBlock />
        {/* <div className="one-c-item-line"></div> */}
      </div>
    </ManagerMenu>);
}